<script setup>
import Layout from '@layouts/Layout.vue'
import MainButton from '../../Components/Button/MainButton.vue'
import { useStore } from 'vuex'
const store = useStore()
import { computed } from 'vue'

const shareButtonDisabled = computed(() => store.getters['auth/getTrialPlanIsExpired']);
const website = computed(() =>store.getters['website/website']);

function share() {
  const specificRoute = `${ window.location.protocol }//${ website.value.code }.${ window.location.hostname }/quote`;
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(specificRoute).then(() => {
      alert('Quote link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  } else {
    alert('Quote link can\'t be copied to clipboard!');
  }
}
</script>

<template>
  <Layout title="How to Install your Instant Quote Tool on your Sites">
    <template #body>
      <div class="bg-gray-50 text-gray-800 font-sans">
        <div class="container mx-auto px-4 py-10">
          <!-- How to Access your Link Section -->
          <section class="mb-12">
            <h2 class="text-2xl font-semibold mb-4">How to Access your Link</h2>
            <p class="flex items-center gap-4">Click the
              <MainButton
                :type-button="shareButtonDisabled ? 'disabled' : ''"
                title="Share Quote Link"
                class-map="flex gap-1 align-center green-btn"
                @click="share"
              />
            </p>
          </section>

          <!-- How to Install on Your Website Section -->
          <section class="mb-12">
            <h2 class="text-2xl font-semibold mb-4">How to Install On Your Website</h2>

            <!-- Doing It Yourself -->
            <div class="mb-6">
              <h3 class="text-xl font-semibold mb-4">Doing It Yourself</h3>
              <ol class="list-decimal list-inside space-y-4">
                <li>Log in to your website provider (i.e. Squarespace or Wix) and <strong>Edit</strong> the website.</li>
                <li>Find the <strong>“Call To Action” button</strong> on the front center of your website.</li>

                <!-- Placeholder for image -->
                <div class="h-24 w-full max-w-lg flex justify-center rounded-lg">
                  <img class="tool-image" :src="require('../../../assets/images/onboarding/tool-website.png').default" alt="website">
                </div>

                <li>First change the Text of the button to <strong>Get Instant Quote</strong>.</li>
                <li>Then remove the current link and add your <strong>EZ Estimate link</strong> (see above).</li>

                <!-- Placeholder for link icon -->
                <div class="w-full max-w-lg flex justify-center rounded-lg">
                  <svg class="mr-20" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_75_9)">
                      <path d="M17.7298 3.91389C19.682 1.95413 22.6889 1.79526 24.4459 3.55903C26.2029 5.32282 26.0445 8.34132 24.0924 10.3011L21.2646 13.1399M11.7208 16.3335C9.96384 14.5697 10.1221 11.5512 12.0743 9.59142L14.5833 7.07264" stroke="#007BFF" stroke-width="3.75" stroke-linecap="round"/>
                      <path d="M16.2794 11.6665C18.0364 13.4303 17.878 16.4488 15.9259 18.4086L13.0981 21.2473L10.2703 24.0861C8.31814 26.0459 5.31126 26.2047 3.5543 24.4409C1.79733 22.6772 1.9556 19.6586 3.90779 17.6989L6.7356 14.86" stroke="#007BFF" stroke-width="3.75" stroke-linecap="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_75_9">
                        <rect width="28" height="28" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <li>Hit <strong>Save</strong> and <strong>Publish</strong>.</li>
              </ol>
            </div>

            <!-- Having your website company do it -->
            <div>
              <h3 class="text-xl font-semibold mb-4">Having your website company do it</h3>
              <p>You can <strong>copy & paste</strong> this message and <strong>email it to your website developer</strong>. If you want to embed your instant quote on your website, please reach out, and we can provide you with the code!</p>
              <blockquote class="p-4 mt-4 bg-gray-100 border-l-4 border-blue-500 italic">
                “Can you change the main CTA to say <strong>‘Get Instant Quote’</strong> and add my <strong>EZ Estimate link</strong> behind it? Here is my link &lt;copy and paste your link&gt;”
              </blockquote>
            </div>
          </section>

          <!-- How to Install on Instagram Section -->
          <section class="mb-12">
            <h2 class="text-2xl font-semibold mb-4">How to Install On Instagram</h2>
            <ol class="list-decimal list-inside space-y-4">
              <li>Go to your profile and select the <strong>Edit Profile</strong> button.</li>
              <li>In the <strong>Website</strong> field, paste your Quote Link.</li>
              <li>Hit Done or select Checkmark!</li>
            </ol>
          </section>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style scoped lang="scss">

</style>
