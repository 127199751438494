import { createApp } from 'vue';
import App from '../views/Pages/App.vue';
import utils from './utils';
import UI from '../views/Components/UI';
import plugins from './plugins';
import Maska from 'maska';
import globalComponents from '@components/Global';
import './libs';
import router from './router';
import store from './store';
import axios from 'axios';
import VueIntercom from '@homebaseai/vue3-intercom';
import Vue3ColorPicker from 'vue3-colorpicker'
import InputPhone from '@components/UI/InputPhone.vue';

import TooltipColor from './utils/tooltipcolor.js';
import '../assets/sass/app.scss';
import 'vue3-colorpicker/style.css';
import VueTelInput from 'vue-tel-input';
import '../assets/css/vue-tel-input.scss';
import '../assets/css/app.css';
import '../assets/sass/custom.scss';
import '../assets/sass/style.scss';

store.axios = axios;
const app = createApp(App)
    .use(store)
    .use(router)
    .use(Maska)
    .use(Vue3ColorPicker)
    .use(VueTelInput)
    .use(VueIntercom)

app.directive('click-outside', {
    mounted(el, binding, vnode) {
        el.addEventListener('click', e => e.stopPropagation());
        document.body.addEventListener('click', binding.value);
    },
    unmounted(el, binding) {
        document.body.removeEventListener('click', binding.value);
    }
});

app.component('input-phone', InputPhone);
app.mixin(TooltipColor);

globalComponents(app);
utils(app);
plugins(app);
UI(app);

app.mount('#app');
