<script setup>
import Layout from '@layouts/Layout.vue'
import OnboardingCategoryPrice from './OnboardingCategoryPrice.vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const showSpinner = ref(false);

const attributes = computed({
  get() {
    return store.getters['onboarding/getAttributes'];
  },
  set(value) {
    store.commit('onboarding/setAttributes', value);
  }
})

const onLoading = (loading) => {
  showSpinner.value = loading;
}

onMounted(async () => {
  await store.dispatch('onboarding/getAttributesAction')
});
</script>

<template>
  <Layout title="Quick Price Change">
    <template #body>
      <div v-if="showSpinner" id="overlay">
        <div id="overlay-text">Updading price</div>
        <img src="/images/spinner.gif" alt="spinner">
      </div>
      <div class="subtitle">
        <p>Want to add another service? Click <a href="/admin/products">Products</a></p>
        <p>Delete any Service categories or Services you don’t offer</p>
      </div>
      <div class="categories-price">
        <OnboardingCategoryPrice
          v-for="attribute in attributes.filter(attribute => attribute.type == 1)"
          :key="`category-price-${attribute.id}`"
          :category="attribute"
          :allAttributes="attributes"
          @onLoading="onLoading"
        />
      </div>
    </template>
  </Layout>
</template>

<style scoped lang="scss">
.subtitle {
  p {
    font-family: Poppins;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    
    a {
      font-weight: 700;
      color: rgba(62, 175, 105, 1);
    }
  }
}

.categories-price {
  margin: 40px 20px 40px 0;
  display: flex;
  flex-direction: column;
  gap: 59px;
  
  @media (max-width: 768px) {
    margin: 40px 0;
  }
}
</style>
