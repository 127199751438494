<script setup>
import MainButton from "../Button/MainButton.vue";


const actionClick = () => {
    window.open('https://adaptive-swift-950.notion.site/EZ-Estimate-Onboarding-f3a62da8cc9d47b0957e00774b8c1aa9', '_blank')
}
</script>

<template>
    <MainButton class="nowrap" title="How to use" @click="actionClick" size-button="small" />
</template>

<style scoped>

</style>
