<template>
    <editor
        :initial-value="modelValue"
        model-events="change keydown paste"
        :api-key="$tinyMceApiKey"
        :init="init"
        @change="onChange"
        @keydown="onChange"
        @paste="onChange"
    />
</template>

<script>
import { mapGetters } from 'vuex'
import Editor from '@tinymce/tinymce-vue'

export default {
    name: 'UITextEditor',
    components: { 'editor': Editor },
    props: {
        modelValue: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            init: {
                image_uploadtab: true,
                images_file_types: 'jpg,png',
                images_upload_handler: this.imageUploadHandler,
                images_upload_url: '/api/v1/media',
                height: 500,
                menubar: 'insert',
                plugins: [
                    'advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media',
                    'paste code help wordcount table'
                ],
                toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | image'
            }
        }
    },
    computed: {
        ...mapGetters({ loading: 'main/loading' })
    },
    methods: {
        onChange (event, editor) {
            this.$emit('update:modelValue', editor.getContent())
        },
        async imageUploadHandler (blobInfo, success) {
            const formData = new FormData()
            formData.append('file', blobInfo.blob())
            const { data: { media: { url } } } = await this.$store.dispatch('main/media', formData)
            success(url)
        }
    }
}
</script>

