import axios from "axios";
export default function haveAccess({next, store}) {
    if (store.getters['auth/loggedIn'] && store.getters['auth/hasRole'] && store.getters['auth/isAdmin']) {
        return next();
    }

    if (store.getters['auth/loggedIn']) {
        if (store.getters['auth/hasRole'] && (store.getters['auth/isSales'] || store.getters['auth/isManager']) && !store.getters['auth/haveAccess'] ) {
            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            axios.post('/api/v1/logout', { headers: { 'X-CSRF-TOKEN': token } }).then(r => {
                store.commit('auth/setUser', {});
            });
        }
    }
}
