<template>
    <div>
        <Tabulator
            :ajax-url="options.ajaxUrl"
            :ajax-params="options.ajaxParams"
            :columns="options.columns"
            :filtering-fields="options.filteringFields"
            :prevent-submit="true"
            title="Notifications"
        />
    </div>
</template>

<script setup>
import { columns } from '@scripts/component/NotificationTabulatorColumns.js';
import Tabulator from '~/Pages/Tabulator/NotificationTabulatorPage.vue';


const options = {
    ajaxUrl: '/api/notification',
    columns: columns,
    filteringFields: [
        {
            name: 'Date',
            value: 'created_at'
        },
        {
            name: 'Body',
            value: 'body'
        },
        {
            name: 'Seen',
            value: 'is_seen'
        },
    ]
}
</script>

<style scoped>

</style>