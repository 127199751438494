<template>
    <div v-if="!isAdmin" class="mt-1 mr-4">
        <template v-if="amountNotifications > 0 || amountNotifications.length > 0">
            <div class="notification-icon">
                <div
                    :class="['notification-icon__icon', {'notification-icon__icon__hover': showNotifications}]"
                    @click="showNotifications=!showNotifications"
                >
                    <svg class="svg-notification" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.7832 16.0312C18.1684 15.5023 17.6301 14.8959 17.1829 14.2284C16.6946 13.3071 16.402 12.3009 16.3221 11.2689V8.22941C16.3264 6.60851 15.7171 5.04192 14.6088 3.82397C13.5005 2.60601 11.9695 1.82054 10.3034 1.61512V0.821403C10.3034 0.603553 10.2137 0.394626 10.0541 0.240583C9.89443 0.0865405 9.67793 0 9.45218 0C9.22644 0 9.00994 0.0865405 8.85031 0.240583C8.69068 0.394626 8.60101 0.603553 8.60101 0.821403V1.62742C6.94983 1.84765 5.4373 2.63787 4.34356 3.85174C3.24982 5.0656 2.64899 6.62084 2.65235 8.22941V11.2689C2.57249 12.3009 2.27986 13.3071 1.79161 14.2284C1.35224 14.8944 0.822607 15.5007 0.216779 16.0312C0.148768 16.0889 0.0942613 16.1598 0.0568836 16.2394C0.0195059 16.319 0.000114635 16.4053 0 16.4927V17.3294C0 17.4926 0.0671738 17.6491 0.186744 17.7645C0.306314 17.8799 0.468486 17.9447 0.637584 17.9447H18.3624C18.5315 17.9447 18.6937 17.8799 18.8133 17.7645C18.9328 17.6491 19 17.4926 19 17.3294V16.4927C18.9999 16.4053 18.9805 16.319 18.9431 16.2394C18.9057 16.1598 18.8512 16.0889 18.7832 16.0312ZM1.32617 16.7142C1.91939 16.1611 2.44169 15.5414 2.88188 14.8683C3.4969 13.7556 3.85575 12.5278 3.93389 11.2689V8.22941C3.90861 7.50832 4.03395 6.78969 4.30244 6.11632C4.57094 5.44295 4.97712 4.82861 5.49677 4.30987C6.01642 3.79114 6.63893 3.37862 7.32723 3.09689C8.01553 2.81515 8.75554 2.66996 9.50319 2.66996C10.2508 2.66996 10.9908 2.81515 11.6791 3.09689C12.3674 3.37862 12.99 3.79114 13.5096 4.30987C14.0293 4.82861 14.4354 5.44295 14.7039 6.11632C14.9724 6.78969 15.0978 7.50832 15.0725 8.22941V11.2689C15.1506 12.5278 15.5095 13.7556 16.1245 14.8683C16.5647 15.5414 17.087 16.1611 17.6802 16.7142H1.32617Z" fill="#B7B9BF"/>
                        <path d="M9.53197 20C9.9336 19.9911 10.319 19.8454 10.6201 19.5886C10.9211 19.3319 11.1184 18.9807 11.1769 18.5972H7.82324C7.88348 18.9911 8.08996 19.3506 8.40427 19.6086C8.71859 19.8667 9.11934 20.0058 9.53197 20Z" fill="#B7B9BF"/>
                    </svg>
                </div>
                <div class="notification-icon__badge"><span>{{ amountNotifications }}</span></div>

                <div v-if="showNotifications" class="notification-dropdown" ref="dropdownNotifications">
                    <div class="dropdown-header">
                        <div class="p-5">
                            <div class="flex items-center">
                                <div class="left-header">
                                    <span>Notifications</span>
                                </div>
                                <MainButton title="Mark All" @click="markSeenAll" />
                            </div>
                        </div>
                    </div>
                    <div class="notification-dropdown__body">
                        <div
                            v-for="(notification, index) in notifications"
                            :key="index"
                            class="notification-dropdown__item"
                        >
                            <div class="notification-dropdown__item__body" @click="toNotification(notification)">
                                <div class="flex justify-between w-full">
                                    <div class="flex gap-4">
                                        <div><img :src="setIcon(notification.type)" alt=""></div>
                                        <div>{{ notification.body }}</div>
                                    </div>
                                    <div>
                                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" @click.stop="markSeen(notification)">
                                            <path d="M8.96409 7L12.6599 3.30423C13.1134 2.85071 13.1134 2.1154 12.6599 1.66151L11.8385 0.840142C11.385 0.386619 10.6497 0.386619 10.1958 0.840142L6.5 4.53591L2.80423 0.840142C2.35071 0.386619 1.6154 0.386619 1.16151 0.840142L0.340142 1.66151C-0.113381 2.11503 -0.113381 2.85034 0.340142 3.30423L4.03591 7L0.340142 10.6958C-0.113381 11.1493 -0.113381 11.8846 0.340142 12.3385L1.16151 13.1599C1.61503 13.6134 2.35071 13.6134 2.80423 13.1599L6.5 9.46409L10.1958 13.1599C10.6493 13.6134 11.385 13.6134 11.8385 13.1599L12.6599 12.3385C13.1134 11.885 13.1134 11.1497 12.6599 10.6958L8.96409 7Z" fill="#939393"/>
                                        </svg>
                                    </div>
                                </div>
                                <p class="date-text w-full mt-4">
                                    <span class="mr-2">
                                        <img :src="clockSvg" alt="">
                                    </span>
                                    <span>{{ notification.createdAt }}</span>
                                </p>
                            </div>
                            <Line style="padding-bottom: 0.5rem !important; padding-top: 0.5rem !important;" />
                        </div>
                    </div>
                    <div class="py-4 text-center bg-white">
                        <router-link :to="{ name: 'notifications' }" tag="a" class="button w-[60%]" @click="showNotifications=false">
                            <span class="btn-content">View All</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <router-link :to="{ name: 'notifications' }" tag="a" class="notification-icon__icon">
                <svg class="svg-notification" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.7832 16.0312C18.1684 15.5023 17.6301 14.8959 17.1829 14.2284C16.6946 13.3071 16.402 12.3009 16.3221 11.2689V8.22941C16.3264 6.60851 15.7171 5.04192 14.6088 3.82397C13.5005 2.60601 11.9695 1.82054 10.3034 1.61512V0.821403C10.3034 0.603553 10.2137 0.394626 10.0541 0.240583C9.89443 0.0865405 9.67793 0 9.45218 0C9.22644 0 9.00994 0.0865405 8.85031 0.240583C8.69068 0.394626 8.60101 0.603553 8.60101 0.821403V1.62742C6.94983 1.84765 5.4373 2.63787 4.34356 3.85174C3.24982 5.0656 2.64899 6.62084 2.65235 8.22941V11.2689C2.57249 12.3009 2.27986 13.3071 1.79161 14.2284C1.35224 14.8944 0.822607 15.5007 0.216779 16.0312C0.148768 16.0889 0.0942613 16.1598 0.0568836 16.2394C0.0195059 16.319 0.000114635 16.4053 0 16.4927V17.3294C0 17.4926 0.0671738 17.6491 0.186744 17.7645C0.306314 17.8799 0.468486 17.9447 0.637584 17.9447H18.3624C18.5315 17.9447 18.6937 17.8799 18.8133 17.7645C18.9328 17.6491 19 17.4926 19 17.3294V16.4927C18.9999 16.4053 18.9805 16.319 18.9431 16.2394C18.9057 16.1598 18.8512 16.0889 18.7832 16.0312ZM1.32617 16.7142C1.91939 16.1611 2.44169 15.5414 2.88188 14.8683C3.4969 13.7556 3.85575 12.5278 3.93389 11.2689V8.22941C3.90861 7.50832 4.03395 6.78969 4.30244 6.11632C4.57094 5.44295 4.97712 4.82861 5.49677 4.30987C6.01642 3.79114 6.63893 3.37862 7.32723 3.09689C8.01553 2.81515 8.75554 2.66996 9.50319 2.66996C10.2508 2.66996 10.9908 2.81515 11.6791 3.09689C12.3674 3.37862 12.99 3.79114 13.5096 4.30987C14.0293 4.82861 14.4354 5.44295 14.7039 6.11632C14.9724 6.78969 15.0978 7.50832 15.0725 8.22941V11.2689C15.1506 12.5278 15.5095 13.7556 16.1245 14.8683C16.5647 15.5414 17.087 16.1611 17.6802 16.7142H1.32617Z" fill="#B7B9BF"/>
                    <path d="M9.53197 20C9.9336 19.9911 10.319 19.8454 10.6201 19.5886C10.9211 19.3319 11.1184 18.9807 11.1769 18.5972H7.82324C7.88348 18.9911 8.08996 19.3506 8.40427 19.6086C8.71859 19.8667 9.11934 20.0058 9.53197 20Z" fill="#B7B9BF"/>
                </svg>
            </router-link>
        </template>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import clockSvg from "@images/clock.svg";
import axios from 'axios';
import Line from "../Line.vue";
import MainButton from "../Button/MainButton.vue";
import NotificationImageSubscribed from "@images/svg/notification/subscribed.svg";
import NotificationImageManager from "@images/svg/notification/manager.svg";
import NotificationImageQuote from "@images/svg/notification/quote.svg";
import NotificationImageUpdateSub from "@images/svg/notification/update-sub.svg";
import NotificationImageTimeSub from "@images/svg/notification/time.svg";


const showNotifications = ref(false);
const dropdownNotifications = ref(null);
const store = useStore();
const route = useRoute();
const isAdmin = computed(() => store.getters['auth/isAdmin']);
const amountNotifications = computed(() => store.getters['notification/getNotificationAmount']);
const notifications = computed({
    get() {
        return store.getters['notification/getNotifications'];
    },
    set(data) {
        store.commit('notification/setNotifications', data);
    }
});


const closeColorPicker = (event) => {
    if (
        showNotifications.value
        && dropdownNotifications.value
        && !event.target.classList.contains('notification-icon')
        && !event.target.classList.contains('notification-icon__icon')
        && !event.target.classList.contains('svg-notification')
        && !dropdownNotifications.value.contains(event.target)
    ) {
        showNotifications.value = false;
    }
};

const setIcon = (type) => {
    switch (type) {
        case 0:
            return NotificationImageSubscribed;
        case 1:
            return NotificationImageUpdateSub;
        case 2:
            return NotificationImageTimeSub;
        case 3:
            return NotificationImageQuote;
        case 4:
            return NotificationImageManager;
    }
}

const handleClickOutside = (event) => {
    if (showNotifications.value) {
        closeColorPicker(event);
    }
};

const getNotifications = () => {
    if (store.getters['auth/loggedIn']) {
        store.dispatch('notification/getNotificationsData');
    }
}

const markSeen = (notification) => {
    axios.put(`/api/notification-mark-seen/${notification.notificationId}`).then(({ data }) => {
        amountNotifications.value = data.length ?? 0;
        notifications.value = data;
    });

    showNotifications.value = false;
}

const markSeenAll = () => {
    axios.put('/api/notification-mark-seen-all').then(({ data }) => {
        amountNotifications.value = data.length ?? 0;
        notifications.value = data;
    });

    showNotifications.value = false;
}

const toNotification = (notification) => {
    if (notification.link) {
        markSeen(notification);

        setTimeout(() => {
            document.location.replace(notification.link);
        }, 250);
    }
}


onMounted(() => {
    window.addEventListener('click', handleClickOutside);

    if (window.auth) {
        getNotifications();

        setInterval(() => {
            getNotifications();
        }, 120000);
    }
});
</script>

<style scoped>
.mark-seen-notification {
    display: block;
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 40px;
    background-color: #939393;
}
</style>
