<template>
    <Layout title="Users">
        <template #body>
            <tabulator
                :ajax-url="options.ajaxUrl"
                :ajax-params="options.ajaxParams"
                :columns="options.columns"
                :default-filter-field="options.defaultFilterField"
                :filtering-fields="options.filteringFields"
                :row-click-callback="rowClick"
                :ditailing-filter="false"
                :set-page="page"
                :is-refresh="isRefresh"
                title="Users"
            />
        </template>
    </Layout>

    <div
        id="modalUpdateUser"
        class="modal"
        style="z-index: 9998 !important;"
        tabindex="-1"
        aria-hidden="true"
    >
        <div :class="['modal-dialog', owner && managers.length > 0 && website ? 'modal-xl' : 'modal-lg']">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal" />
                    <MainBlock v-if="(owner || managers) && website !== null" class="my-4">
                        <template #body>
                            <div :class="[{'flex justify-around': amountBlocks > 1}]">
                                <div v-if="owner">
                                    <div class="block-title">Owner</div>
                                    <div class="block-content">
                                        <div>Name: {{ owner.full_name }}</div>
                                        <div>Email: {{ owner.email }}</div>
                                        <div>Contact number: {{ owner.contact_number }}</div>
                                    </div>
                                    <div class="vertical-line" />
                                </div>
                                <div v-if="managers.length > 0">
                                    <div class="block-title">Managers</div>
                                    <div class="block-content max-h-[5rem] overflow-y-auto">
                                        <div v-for="(manager, index) in managers" :key="index">
                                            <div
                                                class="text-theme-32 text-decoration-underline pr-2 hover:cursor-pointer"
                                                @click="choiceUser(manager)"
                                            >
                                                {{ manager.first_name }}  {{ manager.last_name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="website">
                                    <div class="vertical-line" />
                                    <div>
                                        <div class="block-title">Website</div>
                                        <div class="block-content">
                                            <div>Domain: {{ website.code }}</div>
                                            <div>Company Name: {{ website.company_name }}</div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </MainBlock>

                    <form @submit.prevent="updateUser">
                        <div class="mb-4">
                            <FormInput
                                type="text"
                                label="First Name:"
                                v-model="validate.first_name.$model"
                                :errors="validate.first_name.$errors"
                            />
                        </div>
                        <div class="mb-4">
                            <FormInput
                                type="text"
                                label="Last Name:"
                                v-model="validate.last_name.$model"
                                :errors="validate.last_name.$errors"
                            />
                        </div>
                        <div class="mb-4">
                            <FormInput
                                type="email"
                                label="Email:"
                                v-model="validate.email.$model"
                                :errors="validate.email.$errors"
                            />
                        </div>
                        <div class="mb-4">
                            <InputPhone
                                v-model="validate.contact_number.$model"
                                :default-val="validate.contact_number.$model"
                                :errors="validate.contact_number.$errors"
                                label="Phone:"
                            />
                        </div>
                        <div v-if="validate.userRolesList.$model != 2" class="form-group">
                            <label class="mr-3">Roles:</label>
                            <TomSelect
                                v-model="validate.userRolesList.$model"
                                :options="{placeholder: 'Select roles'}"
                                :remove-button="false"
                                :errors="validate.userRolesList.$errors"
                                class="w-full"
                            >
                                <option
                                    v-for="(role, index) in roles"
                                    :key="index"
                                    :value="role.id"
                                    v-text="role.name"
                                />
                            </TomSelect>
                        </div>

                        <div class="flex justify-center gap-4 my-4">
                            <MainButton title="Save" type="submit" :type-button="processing ? 'disabled' : ''" />
                            <MainButton title="Login like this user" @click="loginLikeUser" />
                            <MainButton title="Delete" type-button="danger" @click="deleteManager" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch, ref, computed, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { usersManagementColumns } from '@scripts/component/UsersManagementColumns';
import { usersManagementFilteringFields } from '@scripts/component/UsersManagementFilteringFields';
import { email, maxLength, minLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "vuex";
import cash from "cash-dom/dist/cash";
import axios from "axios";
import Tabulator from '@components/Tabulator/Tabulator.vue';
import Layout from "@layouts/Layout.vue";
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import MainBlock from "@components/MainBlock.vue";


const owner = ref({});
const website = ref(null);
const isRefresh = ref(false);
const managers = ref([]);
const userId = ref(null);
const userData = ref({
    last_name: '',
    first_name: '',
    email: '',
    contact_number: '',
    userRolesList: ''
});
const amountBlocks = computed(() => {
    if (owner.value && website.value && managers.value && managers.value.length > 0) {
        return 3;
    }

    if (
        owner.value && website.value
        || (website.value && managers.value && managers.value.length > 0)
        || (owner.value && managers.value && managers.value.length > 0))
    {
        return 2;
    }

    if (owner.value || website.value || (managers.value && managers.value.length > 0)) {
        return 1;
    }

    return 0;
});
const roles = ref([]);
const store = useStore();
const rules = {
    last_name: { required, maxLength: maxLength(40) },
    first_name: { required, maxLength: maxLength(40) },
    email: { required, email },
    contact_number: { required },
    userRolesList: { required, minLength: minLength(1) },
}
const validate = useVuelidate(rules, userData);
const router = useRouter();
const toast = inject('toast');
const route = useRoute();
const processing = ref(false);
const page = ref(1);
const options = {
    ajaxUrl: '/api/super-admin/users',
    columns: usersManagementColumns,
    filteringFields: usersManagementFilteringFields,
    defaultFilterField: 'first_name'
}


const updateIsRefresh = () => {
    isRefresh.value = true;

    setTimeout(() => {
        isRefresh.value = false;
    }, 200);
}

const getUserData = (userId_) => {
    userId.value = userId_;

    axios.get(`/api/super-admin/users/${ userId_ }`).then((response) => {
        const { data } = response;
        const { user } = data;
        userData.value = user ?? null;
        owner.value = user.owner ?? null;
        website.value = user.website ?? null;
        managers.value = user.managers ?? null;
        roles.value = data.roles.filter((role) => role.code !== 'owner');

        userData.value.userRolesList = user?.roles.length ? user.roles.map(item => item.id) : [];

        cash('#modalUpdateUser').modal('show');
    }).catch((e) => {
        toast({
            type: 'danger',
            text: e.response.data
        });
    });
}

const deleteManager = () => {
    axios.post(`/api/super-admin/users/${ userId.value }`, {
        id: userId.value,
        _method: 'delete'
    }).then(() => {
        toast({
            type: 'success',
            text: 'Manager successful deleted'
        });

        cash('#modalUpdateUser').modal('hide');
    });

    updateIsRefresh();
}

const choiceUser = (user) => {
    getUserData(user.id);
}

const rowClick = (ev, row) => {
    getUserData(row._row.data.id);
}

const updateUser = () => {
    validate.value.$touch();

    if (!validate.value.$invalid) {
        processing.value = true;
        Object.assign(userData.value, { _method: 'put' }, { userRoles: userData.value.userRolesList })

        axios.post(`/api/super-admin/users/${ userId.value }`, userData.value).then(() => {
            toast({
                type: 'success',
                text: 'User successful updated'
            });

            cash('#modalUpdateUser').modal('hide');
            processing.value = false;
        }).catch((e) => {
            processing.value = false;
        }).finally(() => {
            updateIsRefresh();
        });
    }
}

const loginLikeUser = () => {
    store.dispatch('main/setImpersonated', true);
    document.location.replace(`/login-like-user/${ userId.value }`);
}


watch(
    route,
    (route) => {
        page.value = route.params.page ? Number(route.params.page) : 1;
    },
    { immediate: true, deep: true }
);
</script>

<style scoped>

.block-title {
    @apply pb-2 text-left block;
}

.block-content {
    @apply flex flex-col;
}
</style>
