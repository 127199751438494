<template>
    <div class="flex h-screen justify-center items-center w-full">
        <div class="mx-auto my-auto mt-16 px-[3rem] py-[2rem]">
            <div :class="wrapClassList">
                <div v-for="(plan, index) in plans" :class="['rounded-3xl relative card-item justify-self-center', {'card-scale': isOtherStylePlan(plan)}, {'orange-block': isOtherStylePlan(plan)}, {'max-w-[600px]': plans.length < 2}]">
                    <div class="flex flex-col justify-between h-full">
                        <div class="px-4">
                            <div :class="['text-center name-price', isOtherStylePlan(plan) ? 'pt-[2.5rem]' : 'pt-[2rem]']">
                                <div class="text-decoration-underline uppercase font-bold text-theme-32 planName">
                                    {{ plan.planName }}
                                </div>
                                <div class="pt-4">
                                    <span v-if="plan.price === 0" class="font-bold uppercase text-theme-32" style="font-size: 40px">Free</span>
                                    <div v-else class="text-theme-32 font-bold">
                                        <span style="font-size: 40px">${{ plan.price }}</span>
                                        <span style="font-size: 25px">/{{ plan.period }}</span>
                                    </div>
                                </div>
                            </div>

                            <div :class="['text-gray-600 text-sm mt-4', {'px-[5rem]': plans.length < 2 }]" style="word-break: break-word;">
                                {{ plan.description }}
                            </div>

                            <Line between="1" />

                            <div v-if="isOtherStylePlan(plan)" style="position: absolute; left: 2%; top: -5.5%">
                                <img :src="planCheck" alt="" width="100" height="100">
                            </div>
                        </div>
                        <div class="flex-grow px-4">
                            <template v-if="plan.rule">
                                <ul>
                                    <li v-for="rule in plan.rule.items" style="list-style: inside" class="pb-2 text-md xl:text-lg text-slate-600">
                                        {{ rule }}
                                    </li>
                                </ul>
                            </template>

                            <div v-if="plan.isTrial" class="ribbon-two ribbon-two-danger" :class="{'ribbon-orange': isOtherStylePlan(plan)}">
                                <span>Free Trial</span>
                                <div>{{ plan.trialDays > 30 ? plan.trialDays - 1 : plan.trialDays }} days</div>
                            </div>
                        </div>
                        <div class="p-4">
                            <div class="flex justify-center">
                                <div>
                                    <MainButton
                                        :type-button="disabledButtons ? 'disabled' : ''"
                                        :title="`${plan.isTrial ? 'Start Free Trial' : 'Start Free Plan'}`"
                                        @click="buyClick(false, plan)"
                                    />
                                </div>
                            </div>
                            <div v-if="plan.childrenPlan" class="pt-4 px-2" id="mp">
                                Need more than {{ plan.childrenPlan.searchMap }} Map Searches/month? Try our <span class="text-theme-32 hover:cursor-pointer" @click="buyClick(false, plan.childrenPlan)">{{ plan.childrenPlan.name }}</span> plan (${{ plan.childrenPlan.price }}/month)
                            </div>
                            <div v-else :style="{height: `${heightOtherPlanBlock + (index === 1 && isOtherStylePlan(plan) ? 13 : 0)}` + 'px'}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, ref, onMounted, computed, nextTick } from 'vue';
import axios from 'axios';
import MainButton from "@components/Button/MainButton.vue";
import Line from "@components/Line.vue";
import planCheck from "@images/svg/plan-check.svg";


const props = defineProps({
    disabledButtons: {
        type: Boolean,
        default: false
    }
});
const emit = defineEmits([ 'buyClick' ]);
const plans = ref([]);
const heightOtherPlanBlock = ref(0);
const wrapClassList = computed(() => plans.value.length > 2 ? 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[3rem] h-100' : `grid grid-cols-1 ${plans.value.length > 1 ? 'md:grid-cols-2' : ''} gap-[3rem] justify-center h-100`);

const getSubData = async () => {
    await axios
        .get('/api/get-subscriptions')
        .then((res) => {
            plans.value = res.data[0] ?? [];
        });
}

const buyClick = (val, selectedSub) => emit('buyClick', val, selectedSub);

const isOtherStylePlan = (plan) => {
    return plan.planType === 2 && plan.type === 0;
}


onMounted(async () => {
    await getSubData();
});
</script>

<style lang="scss" scoped>

</style>
