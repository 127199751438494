const state = {
	isActive: false
}

const getters = {
	getMapActive: state => state.isActive
}

const mutations = {
	setMapActive(state, data) {
		state.isActive = data
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}
