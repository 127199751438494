export default {
    getOnboardingStepsAction (context) {
        return this.axios.get('/api/onboarding-steps').then(({ data }) => {
            this.commit('onboarding/setOnboardingSteps', data);
        });
    },
    
    completeOnboardingStepAction (context, payload) {
        return this.axios.post('/api/onboarding-steps', payload)
          .then((response) => {
            // Update the step in the store
            if (payload.is_completed) {
              this.commit('onboarding/setStepCompleted', payload.onboarding_step_id)
            }
            if (payload.is_skipped) {
              this.commit('onboarding/setStepSkipped', payload.onboarding_step_id)
            }
            // Find the next step and set it as the current step
            const currentStepId = payload.onboarding_step_id
            let nextStepId = null
            const onboardingSteps = this.state.onboarding.onboardingSteps
            for (let i = 0; i < onboardingSteps.length; i++) {
              if (onboardingSteps[i].id == currentStepId) {
                nextStepId = onboardingSteps[i + 1]?.id
                break
              }
            }
            this.commit('onboarding/setCurrentStepId', nextStepId ? nextStepId : null)
          }).catch((error) => {
            console.log('error', error)
            return Promise.reject(error);
          })
    },

    getAttributesAction(context, payload) {
      return this.axios.get('/api/onboarding-steps/get-attribute-options').then(({ data }) => {
        this.commit('onboarding/setAttributes', data);
      });
    }
}
