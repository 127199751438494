export default function auth ({ next, store }){
    if (!store.getters['auth/loggedIn']){
        return next({
            name: 'login'
        });
    }

    store.getters['auth/user'].roles.forEach((e)=>{
        if (e.code === 'admin') {
            return next({
                name: 'users-management'
            });
        }
    });

    return next();
}
