<template>
    <div class="fixed top-0 right-0 flex items-center justify-center z-50 mt-5 mr-5">
        <MainButton title="Logout" @click="logout" />
    </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import MainButton from "./Button/MainButton.vue";


const store = useStore();
const router = useRouter();


const logout = async () => {
    store.commit('main/setLoading', false);
    await store.dispatch('auth/logout');
    store.commit('auth/setLoggedIn', false);

    if (window.Intercom) {
        Intercom('shutdown');
    }

    store.commit('auth/setUser', {});
    await router.push({ name: 'login' });
}
</script>
