export default () => ({
    guideStep: 0,
    website: {
        id: '',
        code: '',
        company_name: '',
        user_id: '',
        status: null,
        settings: {}
    },
    pages: {
        mainPage: {
            title: '',
            seo_description: ''
        }
    },
    websiteSettings: {
        footer: {
            facebook: '',
            twitter: '',
            youtube: '',
            instagram: '',
            contact_phone: '',
            email: '',
            address: ''
        },
        about_us: {
            image: '',
            title: '',
            description: ''
        },
        contact_us: {
            email: ''
        },
        callback: {
            button_link: '',
            button_text: ''
        },
        onlyQuote: false,
        logo: null
    },
    emailSettings: {
        mailInfo: {
            text: '',
            title: '',
            sender: {
                name: '',
                email: '',
                phone: '',
                photo: '',
            }
        },
        companyInfo: {
            name: '',
            email: '',
            phone: '',
        },
    },
    statuses: [
    {
        text: 'Active',
        value: 1
    },
    {
        text: 'Inactive',
        value: 0
    }
]
})