<template>
    <Layout title="Update Profile">
        <template #body>
            <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                <div class="flex lg:block flex-col-reverse">
                    <MainBlock>
                        <template #body>
                            <div>
                                <div class="relative flex items-center">
                                    <div class="w-24 h-24 image-fit">
                                        <img
                                            v-if="user.media_id"
                                            class="rounded-md"
                                            :alt="user.media.name"
                                            :src="user.media.url"
                                        >

                                        <DefaultImg
                                            v-else
                                            alt="Dummy image"
                                            class-map="rounded-full"
                                        />
                                    </div>
                                    <div class="ml-4">
                                        <div class="font-medium text-left">
                                            {{ user.first_name }}
                                        </div>
                                        <div class="font-medium text-left">
                                            {{ user.last_name }}
                                        </div>
                                        <div class="text-gray-600">
                                            {{ user.company_name }}
                                        </div>
                                    </div>
                                </div>
                                <Line />
                                <div>
                                    <router-link
                                        :to="{ name: 'profile' }"
                                        class="flex items-center"
                                        :class="{ 'text-theme-32 dark:text-theme-10 font-medium': $route.name === 'profile' }"
                                    >
                                        <img class="w-6 h-6 mr-2" :src="require(`../../../assets/images/svg/profile/info.svg`).default" alt="">
                                        Personal Information
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'profile-change-password' }"
                                        class="flex items-center mt-5"
                                        :class="{ 'text-theme-32 dark:text-theme-10 font-medium': $route.name === 'profile-change-password' }"
                                    >
                                        <img class="w-6 h-6 mr-2" :src="require(`../../../assets/images/svg/profile/change-password.svg`).default" alt="">
                                        Change Password
                                    </router-link>
                                </div>
                            </div>
                        </template>
                    </MainBlock>
                </div>
                <router-view v-slot="{ Component, route }">
                    <transition :name="route.meta.transition || 'fade'">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import Layout from "@layouts/Layout.vue";
import MainBlock from "@components/MainBlock.vue";
import Line from "@components/Line.vue";
import DefaultImg from "../../Components/UI/DefaultImg.vue";


const store = useStore();
const user = computed(() => store.getters['auth/user']);
</script>
