<template>
    <div
        ref="notification"
        class="toastify-content hidden flex"
    >
        <XCircleIcon
            v-if="type === 'danger'"
            class="text-theme-6"
        />
        <CheckCircleIcon
            v-if="type === 'success'"
            class="text-theme-9"
        />
        <InfoIcon
            v-if="type === 'warning'"
            class="text-theme-12"
        />
        <div class="ml-4 mr-4">
            <div
                class="font-medium"
                v-text="title"
            />
            <div
                class="text-gray-600 mt-1"
                v-text="message"
            />
        </div>
    </div>
</template>

<script>
import Toastify from 'toastify-js'

export default {
    name: 'Notify',
    props: {
        title: {
            required: true,
            type: String
        },
        message: {
            required: true,
            type: String
        },
        type: {
            default: 'success',
            type: String,
            validator: (value) => ['success', 'warning', 'danger'].indexOf(value) !== -1
        }
    },

    mounted() {
      this.$on('show-notifications', this.show)
    },

    methods: {
        show () {
            Toastify({
                node: this.$refs.notification.cloneNode().classList.remove('hidden'),
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true
            }).showToast()
        }
    }
}
</script>
