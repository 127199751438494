export default function website ({ next, store }){
    if (!store.getters['website/domain']) {
        return next({
            name: 'dashboard'
        });
    }

    if (store.getters['auth/loggedIn'] && store.getters['auth/hasRole'] && (store.getters['auth/isManager'] || store.getters['auth/isSales'])) {
        return next({
            name: 'dashboard'
        });
    }

    return next();
}
