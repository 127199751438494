<template>
    <Layout :title="title">
        <template #body>
            <form
                @submit="handleSubmit"
                id="tabulator-html-filter-form"
            >
                <div class="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-6 gap-y-3 md:gap-x-3">
                    <div class="sm:flex items-center xl:mt-0 gap-2 col-span-1">
                        <div>
                            <label>
                                Field
                            </label>
                        </div>
                        <div class="flex-grow">
                            <CustomSelect
                                v-model="filter.field"
                                :options="filteringFields"
                                placeholder="Select Field"
                            />
                        </div>
                    </div>
                    <div class="sm:flex items-center gap-2 col-span-2">
                        <div>
                            <label>
                                Value
                            </label>
                        </div>
                        <input
                            v-if="filter.field!=='created_at'"
                            id="tabulator-html-filter-value"
                            v-model="filter.value"
                            type="text"
                            class="form-control w-full"
                            placeholder="Search..."
                            @keyup.enter="onFilter"
                        >
                        <Litepicker
                            v-else
                            v-model="dateRange"
                            :options="{
                            autoApply: false,
                            singleMode: false,
                            numberOfColumns: 2,
                            numberOfMonths: 2,
                            showWeekNumbers: true,
                            format: 'YYYY-MM-DD',
                            dropdowns: {
                                minYear: 1990,
                                maxYear: null,
                                months: true,
                                years: true
                            }
                        }"
                            class="form-control block mx-auto"
                            @apply="dateRangeApply"
                            @cancel="dateRangeCancel"
                        />
                    </div>
                    <div class="flex gap-4 col-span-1">
                        <MainButton
                            id="tabulator-html-filter-go"
                            title="Go"
                            class-map="w-full"
                            @click="onFilter"
                        />
                        <MainButton
                            id="tabulator-html-filter-reset"
                            title="Reset"
                            class-map="w-full"
                            @click="onResetFilter"
                        />
                    </div>
                </div>
            </form>

            <div class="overflow-x-auto scrollbar-hidden">
                <div
                    id="tabulator"
                    ref="tableRef"
                    class="mt-5 table-report table-report--tabulator"
                />
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { computed, ref, reactive, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import router from '@router';
import feather from 'feather-icons';
import Tabulator from 'tabulator-tables';
import Layout from "@layouts/Layout.vue";
import MainButton from "@components/Button/MainButton.vue";
import CustomSelect from "../../Components/UI/CustomSelect.vue";


const props = defineProps({
    title: {
        type: String,
        default: 'Default title'
    },
    preventSubmit: {
        type: Boolean,
        default: false
    },
    ajaxUrl: {
        type: String,
        required: true
    },
    columns: {
        type: Array,
        default: () => []
    },
    filteringFields: {
        type: Array,
        default: () => []
    }
});

const store = useStore();
const tableRef = ref();
const tabulator = ref();
const dateRange = ref('');
const customersPage = computed(() => store.getters['main/customersPage']);
const currentWebsite = computed(() => store.getters['website/website']);
const filter = reactive({
    field: 'name',
    type: 'like',
    value: ''
});


const dateRangeApply = (date) => {
    filter.field = 'created_at'
    filter.value = date
}

const dateRangeCancel = () => {
    dateRange.value = ''
}

const handleSubmit = (e) => {
    if (props.preventSubmit) {
        e.preventDefault();
        onFilter();
    }
}

const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: props.ajaxUrl,
        ajaxParams: { website_id: currentWebsite.value?.id ?? null },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        paginationInitialPage: customersPage.value ?? 1,
        pagination: 'remote',
        paginationSize: store.getters['main/customersSize'] ?? 10,
        paginationSizeSelector: [ 10, 20, 30, 40 ],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: props.columns,
        initialSort: [
            { column: "created_at", dir: "desc" },
        ],
        pageLoaded(pageNumber) {
            store.commit('main/setCustomersPage', pageNumber)
            store.commit('main/setCustomersSize', tabulator.value.getPageSize())
        },
        rowClick(ev, row) {
            router.push({
                name: 'customer-details',
                params: { id: row._row.data.id }
            })
        },
        renderComplete() {
            feather.replace({ 'stroke-width': 1.5 })
        },
        addRow(ev) {
        }
    })
}

const reInitOnResizeWindow = () => {
    window.addEventListener('resize', () => {
        tabulator.value.redraw();
        feather.replace({ 'stroke-width': 1.5 });
    })
}

const onFilter = () => {
    tabulator.value.setFilter(filter.field, filter.type, filter.value);
}

const onResetFilter = () => {
    filter.field = 'name';
    filter.type = 'like';
    filter.value = '';
    onFilter();
}


watch(() => currentWebsite.value, () => {
    initTabulator();
});

watch(
    () => dateRange.value,
    (date) => {
        filter.field = 'created_at'
        filter.value = date
    }
);

watch(() => filter.field, (currentFilter) => {
    if (currentFilter !== 'created_at') {
        filter.value = '';
    }
});

onMounted(() => {
    initTabulator();
    reInitOnResizeWindow();
});
</script>
