const CONTACT_STEP = 'contact'
const OPTION_STEP = 'option'
const SUMMARY_STEP = 'summary'

const PRESIZE_STEP = 'presize'


export  {
    SUMMARY_STEP,
    CONTACT_STEP,
    OPTION_STEP,
    PRESIZE_STEP
}
