<template>
    <header style="padding-bottom: 4rem">
        <div class="layout-header py-4 border-b border-theme-13 px-4 w-full absolute z-50" style="background-color: white;">
            <div class="container flex justify-between items-center">
                <a @click="hrefClick" class="hover:cursor-pointer">
                    <img class="h-12" :src="logoPath" alt="Logo">
                </a>
                <div class="customer-banner flex w-full items-center justify-between hidden sm:flex gap-8" v-if="isClientPreview">
                  <div class="empty-space"></div>
                  <h3 class="max-w-[25rem] text-center" style="color: #3EAF69;">
                    This is a test version of your instant quote tool. See how it works from a customer’s view, then start editing your services and prices!
                  </h3>
                  <MainButton 
                    title="Customize your Tool!"
                    class-map="orange"
                    @click="goToOnboarding"
                  />
                </div>
                <ul class="items-center gap-4 hidden sm:flex" v-if="!isClientPreview">
                    <li v-if="subTypeMeasureBasicPlan || subTypeMeasureProPlan">
                        <MainButton
                            title="How to use"
                            @click="showModalHowUse"
                        />
                    </li>
                    <li>
                        <MainButton
                            title="Contact Us"
                            @click="showModal"
                        />
                    </li>
                    <li v-if="routeName !== 'quote'  && quoteButtonAllowed">
                        <a href="/quote">
                            <MainButton title="Generate Quote" />
                        </a>
                    </li>
                </ul>
                <div class="sm:hidden block">
                    <i @click="showMobileMenu" class="gg-menu hover:cursor-pointer" />
                </div>
            </div>
            <div class="customer-banner flex flex-col gap-4 mt-8 sm:hidden" v-if="isClientPreview">
              <h3 class="max-w-[25rem] text-center" style="color: #3EAF69;">
                This is a test version of your instant quote tool. See how it works from a customer’s view, then start editing your services and prices!
              </h3>
              <MainButton
                title="Customize your Tool!"
                class-map="orange"
                @click="goToOnboarding"
              />
            </div>
            <div :class="['transition-all sm:hidden', {'hidden': !showMobileMenu_}]">
                <ul class="items-center pt-2">
                    <li class="pt-2">
                      <MainButton
                          v-if="!isClientPreview"
                          title="Contact Us"
                          @click="showModal"
                      />
                    </li>
                    <li class="pt-2" v-if="routeName !== 'quote' && quoteButtonAllowed">
                        <a href="/quote">
                            <MainButton title="Generate Quote" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </header>

    <main :class="[mainClassMap, isClientPreview ? 'mobile-preview-padding-top' : '' ]">
        <slot name="body" />
    </main>

    <footer v-if="footer" :class="['footer-small', subTypeMeasureBasic || subTypeMeasurePro ? 'small-height' : '']">
        <div class="container footer-inside">
            <div v-if="(!subTypeMeasureBasic && !subTypeMeasurePro) && settings?.websiteSettings?.footer" class="grid grid-cols-2 sm:grid-cols-4 gap-4">
                <ul class="text-center">
                    <li class="mb-2 heading">Address</li>
                    <li class="text-theme-32 break-all">{{ settings?.websiteSettings?.footer?.address }}</li>
                </ul>
                <ul class="text-center">
                    <li class="mb-2 heading">Phone</li>
                    <li>
                        <a :href="`tel:${settings?.websiteSettings?.footer?.contact_phone}`" class="text-theme-32 text-decoration-underline" tabindex=-1>
                            {{ settings?.websiteSettings?.footer?.contact_phone }}
                        </a>
                    </li>
                </ul>
                <ul class="text-center">
                    <li class="mb-2 heading">Email</li>
                    <li>
                        <a :href="`mailto:${settings?.websiteSettings?.footer?.email}`" class="text-theme-32 text-decoration-underline" tabindex=-1>
                            {{ settings?.websiteSettings?.footer?.email }}
                        </a>
                    </li>
                </ul>
                <ul class="text-center">
                    <li class="mb-2 heading">Social</li>
                    <li>
                        <ul class="flex items-center justify-center" style="max-height: 16px;">
                            <li>
                                <a v-if="settings?.websiteSettings?.footer?.facebook" class="mx-2" tabindex=-1 :href="settings?.websiteSettings?.footer?.facebook" target="_blank">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="#3EAF69" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
                                    </svg>
                                </a>
                            </li>
                            <li v-if="settings?.websiteSettings?.footer?.twitter">
                                <a class="mx-2" tabindex=-1 :href="settings?.websiteSettings?.footer?.twitter" target="_blank">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="#3EAF69" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                                    </svg>
                                </a>
                            </li>
                            <li v-if="settings?.websiteSettings?.footer?.instagram">
                                <a class="mx-2" tabindex=-1 :href="settings?.websiteSettings?.footer?.instagram" target="_blank">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="#3EAF69" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                                    </svg>
                                </a>
                            </li>
                            <li v-if="settings?.websiteSettings?.footer?.youtube">
                                <a class="mx-2" tabindex=-1 :href="settings?.websiteSettings?.footer?.youtube" target="_blank">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="#3EAF69" d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <ul class="flex justify-center gap-4 mt-5 pt-3">
                <li>
                    <a class="text text-decoration-underline" tabindex=-1 href="/terms-and-conditions" target="_blank">
                        Term of use
                    </a>
                </li>
                <li>
                    <a class="text text-decoration-underline" tabindex=-1 href="/privacy-policy" target="_blank">
                        Private policy
                    </a>
                </li>
            </ul>
        </div>
    </footer>

    <div
        id="howToUse"
        class="modal"
        style="z-index: 9998 !important;"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-body p-1">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal" @click="closeModal"/>
                    <video :src="videoHowToUse" controls controlsList="nodownload" type="video/mp4" id="videoHowToUse" />
                </div>
            </div>
        </div>
    </div>

    <div
        id="howToUseOnMapPage"
        class="modal"
        style="z-index: 9998 !important;"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-body p-1">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal" @click="closeModal"/>
                    <div v-if="videoHowToUseOnMapPage" v-html="videoHowToUseOnMapPage"></div>
                    <video v-else :src="videoHowToUse" controls controlsList="nodownload" type="video/mp4" id="howToUseOnMapPage" />
                </div>
            </div>
        </div>
    </div>

    <div
        id="contactUsModal"
        class="modal"
        style="z-index: 9998 !important;"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
                    <div class="text-center">
                        <span class="intro-x font-medium text-2xl xl:text-3xl text-center">
                            Contact Us
                        </span>
                    </div>
                    <div class="switch">
                        <div :class="['switch-item left', {'active-left': switchItem === 'email'}]" data-type="left" @click="switchItem = 'email'">
                            <div class="switch-item-text">
                                Email
                            </div>
                        </div>

                        <div :class="['switch-item right', {'active-right': switchItem === 'call'}]" data-type="right" @click="switchItem ='call'">
                            <div class="switch-item-text">
                                Call
                            </div>
                        </div>
                    </div>
                    <form>
                        <div v-if="switchItem !== 'call'" class="my-4 block-email-message">
                            <lable class="float-left form-label">Topic</lable>
                            <select v-model="requestDataContactUs.topic" name="subject" class="w-full bg-white" style="height: 2.5rem; text-indent: 5px">
                                <option value="Cooperation">Cooperation</option>
                                <option value="Bug/Errors">Bug/Errors</option>
                                <option value="Suggestions">Suggestions</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div class="form-group mt-5">
                            <FormInput
                                v-model="requestDataContactUs.name"
                                type="text"
                                label="Name"
                            />
                        </div>
                        <div v-if="switchItem !== 'call'" class="form-group mt-5">
                            <FormInput
                                v-model="requestDataContactUs.email"
                                type="email"
                                label="Email"
                            />
                        </div>
                        <div v-if="switchItem === 'call'" class="form-group mt-5">
                            <InputPhone
                                v-model="requestDataContactUs.phone"
                                :class-map="['mt-1', 'w-full', 'form-control']"
                                label="Phone"
                                id="telephone"
                                placeholder=""
                            />
                        </div>
                        <div v-if="switchItem !== 'call'" class="form-group mt-5">
                            <FormTextarea
                                v-model="requestDataContactUs.message"
                                label="Message"
                                placeholder="Enter message"
                            />
                        </div>
                        <div class="form-group text-center">
                            <MainButton
                                :type-button="processing ? 'disabled' : ''"
                                title="Send"
                                type="submit"
                                class-map="inline-block my-2"
                                @click.prevent="buttonClick"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount, inject, watch, getCurrentInstance, onMounted } from 'vue';
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import axios from 'axios';
import cash from "cash-dom/dist/cash";
import FormInput from "@components/UI/Input.vue";
import InputPhone from "@components/UI/InputPhone.vue";
import FormTextarea from "@components/UI/FormTextarea.vue";
import MainButton from "@components/Button/MainButton.vue";
import '../../../assets/sass/quote.scss';
import '@assets/sass/quote.scss';


const props = defineProps({
    footer: {
        type: Boolean,
        default: true
    },
    mainClassMap: {
        type: String,
        default: 'py-10 mx-4 lg:mx-0'
    },
   quoteButtonAllowed: {
      type: Boolean,
      default: true
    }
});
const route = useRoute();
const store = useStore();
const logoPath = ref('images/logo.svg');
const settings = ref(null);
const toast = inject('toast');
const params = ref(null);
const routeName = ref('');
const switchItem = ref('email');
const showMobileMenu_ = ref(false);
const formData = new FormData();
const subTypeMeasureBasic = ref(false);
const subTypeMeasurePro = ref(false);
const processing = ref(false);
const { proxy } = getCurrentInstance();
const requestDataContactUs = reactive({
    name: '',
    email: '',
    phone: '',
    topic: 'Cooperation',
    message: '',
});
const videoHowToUse = '/videos/how_to_use.mp4';
const videoHowToUseOnMapPage = ref(null);
const subTypeMeasureBasicPlan = store.getters['auth/subTypeMeasureBasic'];
const subTypeMeasureProPlan = store.getters['auth/subTypeMeasurePro'];
const isClientPreview = ref(false);

const hrefClick = () => {
    window.location = '/';
}

const showMobileMenu = () => {
    showMobileMenu_.value = !showMobileMenu_.value;
}

const goToOnboarding = () => {
    const website = store.getters['quote/website']
    window.open('http://' + window.location.host.replace(website.code + '.', '') + '/admin/onboarding', '_blank');
}

const showModal = () => {
    requestDataContactUs.email = '';
    requestDataContactUs.name = '';
    requestDataContactUs.message = '';
    requestDataContactUs.phone = '';
    requestDataContactUs.topic = 'Cooperation';

    switchItem.value = 'email';
    cash('#contactUsModal').modal('show');
}

const showModalHowUse = () => {
    cash('#howToUse').modal('show');
}

const send = () => {
    formData.append('name', requestDataContactUs.name);
    formData.append('type', switchItem.value);

    if (switchItem.value === 'email') {
        formData.append('email', requestDataContactUs.email);
        formData.append('message', requestDataContactUs.message);
    } else {
        formData.append('phone', requestDataContactUs.phone);
    }

    processing.value = true;

    axios
        .post('/api/send-contact-us-email', formData)
        .then((response) => {
            formData.delete('name');
            requestDataContactUs.name = '';

            if (switchItem.value === 'email') {
                formData.delete('email');
                formData.delete('message');
                requestDataContactUs.email = '';
                requestDataContactUs.message = '';
            } else {
                formData.delete('phone');
                requestDataContactUs.phone = '';
            }

            cash('#contactUsModal').modal('hide');

            toast({
                text: 'Thank You for contacting!',
                type: 'success'
            });
        })
        .catch((e) => {
            proxy.$errorHandler(e);
        })
        .finally(() => {
            processing.value = false;
        });
}


const buttonClick = () => {
    send();
}


const onModalHidden = () => {
    const video = document.getElementById('videoHowToUse');
    video.pause();
    video.currentTime = 0;
}

const closeModal = () => {
    onModalHidden();
}

watch(
    () => window.location,
    (data) => {
        routeName.value = window.location.pathname.replace('/', '');
    },
    {
        deep: true
    }
);


onBeforeMount(async () => {
    params.value = window.location.host.split('.');
    routeName.value = window.location.pathname.replace('/', '');
    
    if (route.query?.preview) {
        isClientPreview.value = true;
    }

    await axios.get(`api/get-settings-data/${ params.value[0] }`).then(({ data }) => {
        logoPath.value = data?.settings?.websiteSettings?.logo ?? 'images/logo.svg';
        settings.value = data?.settings;
        subTypeMeasureBasic.value = data.subType === 0;
        subTypeMeasurePro.value = data.subType === 1;
        videoHowToUseOnMapPage.value = data?.settings?.websiteSettings?.how_to_use_video_embed_code

        document.title = data?.page?.title ?? 'Landing Page';

        store.commit('quote/setSettings', data?.settings);
        store.commit('website/setWebsiteSetting', data?.settings?.websiteSettings?.contact_us);
    });
    await store.dispatch('quote/getWebsite', params.value[0]);
});

onMounted(() => {
    const modalElement = document.querySelector('#howToUse');

    const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (mutation.attributeName === 'class') {
                const currentClassState = mutation.target.className;

                if (!currentClassState.includes('show')) {
                    onModalHidden();
                }
            }
        });
    });

    observer.observe(modalElement, { attributes: true });
});
</script>

<style>
body {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media (max-width: 640px) {
    .mobile-preview-padding-top {
        padding-top: 12rem !important;
    }
}

.footer-small {
    height: 150px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 21px 4px rgba(0, 0, 0, 0.08);
    text-align: center;

    @media (max-width: 640px) {
        height: 220px;
    }

    .small-height {
        height: 100px !important;
    }
}

.footer-inside {
    padding-top: 1.5rem;
}
</style>
