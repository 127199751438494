import { CONTACT_STEP, PRESIZE_STEP, OPTION_STEP, SUMMARY_STEP } from './quoteConstants';

export default () => ({
    order: {
        userData: {
            place: {}
        },
        selectedLayers: [],
        selectedOptions: [],
        selectedUpgrades: [],
        selectedSingleUnitItems: [],
        imageData: {},
    },
    customerTier: null,
    layers: [],
    options: [],
    upgrades: [],
    singleUnitItems: [],
    step: CONTACT_STEP,
    mapLoader: null,
    settings: {

    },
    buttonSettings: {
        
    },
    locationData: {
        unitSystem: '',
        currency: {
            converter: 1,
            code: '',
            symbol: ''
        }
    },
    ranges: {
        low: 0,
        high: 0
    },
    website: {
        id: '',
        code: '',
        company_name: '',
        user_id: '',
        status: null,
        limit: false,
        currency: {},
        google_tag: '',
        fb_pixel: '',
        multiple: false,
        quote_notes: ''
    },
    transformData: {
        stepTransform: 0,
        indent: 0,
    },
    summaryData: {
        items: [],
        simpleUnitItems: [],
        total: 0,
        metric: '',
        currency: '',
    },
    windowSize: {
        height: 0,
        width: 0
    },
    disabledSize: false
});
