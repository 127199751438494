<template>
    <div>
        <h2 class="text-lg font-medium mt-10">
            Super admin subscribe
        </h2>
        <div class="p-5 mt-5">
            <TabulatorFilter
                :filtering-fields="filteringFields"
                @filter="filter"
            />
            <div class="overflow-x-auto scrollbar-hidden">
                <div
                    id="tabulator"
                    ref="tableRef"
                    class="mt-5 table-report table-report--tabulator"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { filteringFields } from '@scripts/component/SubscribeManagementFilteringFields';
import { subscribeManagementColumns } from '@scripts/component/SubscribeManagementColumns';
import axios from 'axios';
import Tabulator from 'tabulator-tables';
import TabulatorFilter from '@components/Tabulator/TabulatorFilter.vue';

const store = useStore();
const tabulator = ref();
const tableData = ref([]);
const tableRef = ref();


const filter = ({ field, type, value }) => {
    tabulator.value.setFilter(field, type, value)
}

const getTableData = async () => {
    await axios.get('/api/super-admin/admin-stripe/subscribers', {
        params: {
            size: 10
        }
    }).then((response) => {
        tableData.value = response.data.data
        initTabulator()
    })
}

const initTabulator = () => {
    tabulator.value = new Tabulator(tableRef.value, {
        data: tableData.value,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        ajaxURL: '/api/super-admin/admin-stripe/subscribers',
        ajaxFiltering: true,
        paginationSize: store.getters['main/subscriberSize'] ?? 10,
        paginationSizeSelector: [ 10, 20, 30, 40 ],
        paginationInitialPage: store.getters['main/subscriberPage'] ?? 1,
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: subscribeManagementColumns,
        pageLoaded(pageNumber) {
            store.commit('main/setSubscriberPage', pageNumber)
            store.commit('main/setSubscriberSize', tabulator.value.getPageSize())
        }
    })
}


onMounted(() => {
    getTableData();
});
</script>
