<script setup>
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import { useIntercom } from "@homebaseai/vue3-intercom";

const store = useStore();
const user = computed(() => store.getters['auth/user']);
const intercom = useIntercom();


onMounted(() => {
    if (typeof user === 'object' && Object.keys(user.value).length > 0 && !user.value?.isAdmin) {
        intercom.boot({
            app_id: 'dggujel5',
            name: `${user.value.full_name}`,
            user_id: `${user.value.uuid}`,
            user_hash: `${user.value.user_hash}`
        });
        intercom.update({
            app_id: 'dggujel5',
            name: `${user.value.full_name}`,
            user_id: `${user.value.uuid}`,
            user_hash: `${user.value.user_hash}`
        });
    }

    if(Object.keys(user.value).length < 1) {
        intercom.boot({ app_id: 'dggujel5' });
        intercom.update({ app_id: 'dggujel5' });
    }

    if (typeof user === 'object' && Object.keys(user.value).length > 0 && user.value?.isAdmin && window.Intercom) {
        intercom.shutdown();
    }
});
</script>

<template>
    <slot></slot>
</template>

<style scoped>

</style>