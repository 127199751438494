<template>
    <div class="mb-8 shadow-sm dark:border-darkmode-400 rounded-md">
        <BlockTitle :title="title" />

        <AttributeChange
            :current-item="currentItem"
            :type="attributeType"
            :label-price="labelPrice"
            @saveAttribute="saveAttribute"
            @itemChosen="itemChosen"
            @itemDeselect="itemDeselect"
        />

        <div class="ml-4">
            <AttributesItems
                :current-item="currentItem"
                :type="attributeType"
                :items="attributesItems"
                :limit="setLimit(attributeType)"
                :deleteWindowId="deleteWindowId"
                :attribute-type="attributeType"
                :label-price="labelPrice"
                @itemChosen="itemChosen"
                @saveAttribute="saveAttribute"
                @itemDeselect="itemDeselect"
                @clearItemChosen="clearItemChosen"
            />
        </div>

        <DeleteModal :id="deleteWindowId" @delete="deleteItem" />
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, inject, defineProps, defineEmits, getCurrentInstance } from 'vue';
import { useStore } from "vuex";
import AttributesItems from './AttributesItems';
import AttributeChange from './AttributeChange';
import axios from 'axios';
import cash from 'cash-dom/dist/cash';
import BlockTitle from "@components/BlockTitle.vue";
import DeleteModal from "@components/Modal/DeleteModal.vue";


const props = defineProps({
    productId: {
        type: Number,
        default: null
    },
    title: {
        type: String,
        default: ''
    },
    attributeType: {
        type: Number,
        default: null
    },
    parentId: {
        type: Number,
        default: null
    },
    child: {
        type: Object,
        default: null
    },
    labelPrice: {
        type: String,
        default: 'SQFT'
    }
});
const emit = defineEmits([ 'itemChosen', 'deleteItem', 'itemDeselect', 'clearItemChosen' ]);
const store = useStore();
const unwantedSubTypes = computed(() => store.getters['auth/subTypeBasic'] || store.getters['auth/subTypeMeasureBasic'] || store.getters['auth/subTypeMeasurePro']);
const ATTRIBUTE_SINGLE_UNIT_ITEM = 5;
const toast = inject('toast');
const currentItem = ref({});
const itemId = ref('');
const attributesItems = ref([]);
const deleteWindowId = computed(() => 'delete' + props.attributeType);

const getAttributes = async () => {
    if (props.productId > 0) {
        const repsonse = await axios
            .get('/api/attribute', {
                params: {
                    product_id: props.productId,
                    type: props.attributeType,
                    parent_id: props.parentId
                }
            })
            attributesItems.value = Object.values(repsonse.data)
    }
}

const saveAttribute = async (formData) => {
    formData.append('product_id', props.productId)
    formData.append('parent_id', props.parentId)

    let url = '/api/attribute/'

    //for clearing chosen items after service update
    // emit('clearItemChosen');

    if (itemId.value) {
        formData.append('id', itemId.value)
        formData.append('_method', 'put')

        url = `/api/attribute/${ itemId.value }`
    }

    try {
      await axios.post(url, formData)
      await getAttributes()

      clearItemChosen()

      formData.delete('id')
      formData.delete('_method')
      formData.delete('product_id')
      formData.delete('name')
      formData.delete('file')
      formData.delete('type')

      itemId.value = ''
    } catch (e) {
      let responseData = e.response.data
      let errors = ''

      for (const item in responseData) {
        for (const itemDetails in responseData[item]) {
          errors += responseData[item][itemDetails]
        }
      }

      if (responseData.file) {
        errors = responseData.file
      }

      toast({
        type: 'danger',
        text: errors
      })
    }
}

const itemDeselect = (type) => {
    emit('itemDeselect', type)

    itemId.value = ''
}

const itemChosen = (type) => {
    itemId.value = type.id
    currentItem.value = type

    emit('itemChosen', type)
}

const clearItemChosen = (type) => {
    itemId.value = ''
    emit('clearItemChosen')
}

const deleteItem = async () => {
    await axios.post(`/api/attribute/${ itemId.value }`, {
        id: itemId.value,
        _method: 'delete'
    })
    cash('#' + deleteWindowId.value).modal('hide')
    itemId.value = null;

    emit('itemDeselect', props.attributeType)
  
    await getAttributes()
}

const setLimit = (typeItem) => {
    let count = attributesItems.value.reduce((acc, item) => acc + (item.type == typeItem ? 1 : 0), 0);

    if ((typeItem === 1 || typeItem === '1') && unwantedSubTypes.value) {
        return count >= store.getters['auth/getAppSettings'].layers
    }

    return false
}


watch(
    () => props.parentId,
    async () => {
        await getAttributes()
    }
);

watch(
    () => props.attributeType,
    async (value) => {
        if (value === ATTRIBUTE_SINGLE_UNIT_ITEM) {
            await getAttributes()
        }
    }
);

onMounted(async () => {
    await getAttributes()

    //get common type attributes and save to store
    await store.dispatch('general/actionGetCommonTypes')
});
</script>

<style scoped>
</style>
