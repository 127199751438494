<template>
    <div class="custom-grid">
        <div
            v-for="(item, index) in attributesItems"
            :key="index"
            @click="selectAttribute(item)"
        >
            <AttributesItem :item="item" />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";
import { useStore } from 'vuex';
import axios from "axios";
import AttributesItem from "@components/AttributesItem.vue";


const props = defineProps({
    attributeType: {
        type: Number,
        default: null
    },
});

const store = useStore();
const emit = defineEmits([ 'attributeChosen' ]);
const attributesItems = ref([]);
const formData = new FormData();
const attribute = ref({
    value: '',
    description: ''
});


const getAttributes = () => {
    axios.get('/api/attribute-by-type', {
        params: {
            type: props.attributeType,
        }
    }).then(response => {
        const { data } = response
        data.map((dataItem) => dataItem.active = false)
        attributesItems.value = data
    })
}

const selectAttribute = (attribute) => {
    attributesItems.value.map((dataItem) => dataItem.active = false);
    attribute.active = true;
    emit('attributeChosen', attribute);
}

const fileUploaded = (file) => {
    formData.append('file', file);
}


onMounted(() => {
    getAttributes();
});
</script>
