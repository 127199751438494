export default function checkPaymentWebsite({ next, store }) {
    if (store.getters['auth/loggedIn']) {
        const user = store.getters['auth/user'];

        if (store.getters['auth/hasRole'] && store.getters['auth/isOwner'] && !user.isSubscribed) {
            return next({
                name: 'add-plan'
            })
        }

        if (!user.owner_id && !user.hasAttributes) {
            return next({
                name: 'guide'
            })
        }
    }

    return next();
}
