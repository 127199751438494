<template>
  <div v-if="metrics && metrics.length" :class="wrapClassMap">
    <label v-if="showLabel" class="form-label text-left w-full">Type of metric:</label>

    <div class="flex gap-x-3">
      <div v-if="SQFT" class="flex items-center align-center gap-x-2">
        <input
            v-model="metric"
            :value="SQFT['id']"
            type="radio"
            class="form-check-input"
            @input="(event) => emit('update:modelValue', event.target.value)"
        >
        <label>{{ SQFT['currency'] }}</label>
      </div>
      <div v-if="LF" class="flex items-center align-center gap-x-2">
        <input
            v-model="metric"
            :value="LF['id']"
            type="radio"
            class="form-check-input"
            @input="(event) => emit('update:modelValue', event.target.value)"
        >
        <label>{{ LF['currency'] }}</label>
      </div>
      <div v-if="perUnit" class="flex items-center align-center gap-x-2">
        <input
            v-model="metric"
            :value="perUnit['id']"
            type="radio"
            class="form-check-input"
            @input="(event) => emit('update:modelValue', event.target.value)"
        >
        <label>{{ perUnit['currency'] }}</label>
      </div>
    </div>

    <template v-if="errors && errors.length > 0">
      <div
          v-for="(error) in errors"
          :key="error.$message"
          class="text-theme-6 mt-2"
      >
        {{ error.$message }}
      </div>
    </template>

    <div v-if="textMetric.length > 0 && showText" class="pt-3">
      {{ textMetric }}
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, onMounted, ref, watch } from 'vue'
import { useStore } from "vuex";

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: '',
    },
    errors: {
        type: Array,
        required: false,
        default: () => {
          return []
        }
    },
    modelValue: {
        type: [ String, Number ],
        default: null
    },
    showLabel: {
        type: Boolean,
        default: true
    },
    showText: {
        type: Boolean,
        default: true
    },
    wrapClassMap: {
        type: String,
        default: 'form-group mt-5'
    },
    isActionCreate: {
      type: Boolean,
      default: false
    }
})
const store = useStore();
const emit = defineEmits(['update:modelValue']);
const metric = ref(props.modelValue);
const metrics = computed(() => store.getters['general/getMetrics']);
const textMetric = computed(() => metric.value === 1 ? 'Selecting this metric requires users to draw interconnected lines, forming a complete figure to determine the SQFT length. ' :  metric.value === 2 ? 'When this metric is chosen, users can determine the LF length by drawing not connected single lines.' : '');

const SQFT = metrics.value.find(el => el.currency === 'SQFT')
const LF = metrics.value.find(el => el.currency === 'LF')
const perUnit = metrics.value.find(el => el.currency.toLowerCase() === 'per unit')

onMounted(async () => {
  //get metrics
  if (!metrics && metrics.length < 1) {
    await store.dispatch('general/actionGetMetrics')
  }
})

watch(
    () => props.modelValue,
    (value) => {
        metric.value = value
    }
);
</script>

<style scoped>

</style>
