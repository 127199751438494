<template>
    <div>
        <DarkModeSwitcher />
        <MobileMenu />
        <div class="flex">
            <div class="menu" style="height: 100vh;">
              <nav class="side-nav">
                <router-link
                  :to="{ name: 'dashboard' }"
                  tag="a"
                  class="px-2 intro-x flex items-center pt-4"
                >
                  <img
                    :src="Logo"
                    alt=""
                    class="hidden xl:block"
                  >
                  <img
                    :src="MinLogo"
                    alt=""
                    width="55"
                    class="block xl:hidden"
                  >
                </router-link>
                <div class="my-6" />
                <ul>
                  <template v-for="(menu, menuKey) in formattedMenu">
                    <li
                      v-if="menu === 'divider'"
                      :key="menu + menuKey"
                      class="side-nav__devider my-6"
                    />
                    <li
                      v-else
                      :key="menu + menuKey"
                    >
                      <SideMenuTooltip
                        tag="a"
                        :content="menu.title"
                        :href="
                                menu.subMenu
                                    ? 'javascript:void(0);'
                                    : router.resolve({ name: menu.pageName }).path
                            "
                        class="side-menu"
                        :class="{
                                'side-menu--active': menu.active,
                                'side-menu--open': menu.activeDropdown
                            }"
                        @click="linkTo(menu, router, $event)"
                      >
                        <div :class="['side-menu__icon']">
                          <img v-if="menu.image" :src="require(`../../../assets/images/svg/side-menu/${menu.image}.svg`).default" alt="">
                          <component v-else :is="menu.icon" />
                        </div>
                        <div class="side-menu__title">
                          {{ menu.title }}
                          <div
                            v-if="menu.subMenu"
                            class="side-menu__sub-icon"
                          >
                            <DropdownArrow :width="7" :height="14" :active="menu.activeDropdown" />
                          </div>
                        </div>
                      </SideMenuTooltip>
                      <transition @enter="enter" @leave="leave">
                        <ul v-if="menu.subMenu && menu.activeDropdown">
                          <li
                            v-for="(subMenu, subMenuKey) in menu.subMenu"
                            :key="subMenuKey"
                          >
                            <SideMenuTooltip
                              tag="a"
                              :content="subMenu.title"
                              :href="
                                            subMenu.subMenu
                                                ? 'javascript:void(0);'
                                                : router.resolve({ name: subMenu.pageName }).path
                                        "
                              class="side-menu"
                              :class="{ 'side-menu--active': subMenu.active }"
                              @click="linkTo(subMenu, router, $event)"
                            >
                              <div class="side-menu__icon">
                                <template v-if="subMenu.image">
                                  <img :src="require(`../../../assets/images/svg/side-menu/${subMenu.image}.svg`).default" alt="">
                                </template>
                                <template v-else>
                                  <component
                                    :is="subMenu.icon"
                                    v-if="subMenu.icon"
                                  />
                                  <ActivityIcon v-else />
                                </template>
                              </div>
                              <div class="side-menu__title">
                                {{ subMenu.title }}
                                <div
                                  v-if="subMenu.subMenu"
                                  class="side-menu__sub-icon"
                                >
                                  <DropdownArrow :width="7" :height="14" :active="subMenu.activeDropdown" />
                                </div>
                              </div>
                            </SideMenuTooltip>
                            <transition
                              @enter="enter"
                              @leave="leave"
                            >
                              <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                                <li
                                  v-for="(lastSubMenu,
                                                        lastSubMenuKey) in subMenu.subMenu"
                                  :key="lastSubMenuKey"
                                >
                                  <SideMenuTooltip
                                    tag="a"
                                    :content="lastSubMenu.title"
                                    :href="
                                                        lastSubMenu.subMenu
                                                            ? 'javascript:void(0);'
                                                            : router.resolve({ name: lastSubMenu.pageName })
                                                                .path
                                                    "
                                    class="side-menu"
                                    :class="{ 'side-menu--active': lastSubMenu.active }"
                                    @click="linkTo(lastSubMenu, router, $event)"
                                  >
                                    <div class="side-menu__icon">
                                      <ZapIcon />
                                    </div>
                                    <div class="side-menu__title">
                                      {{ lastSubMenu.title }}
                                    </div>
                                  </SideMenuTooltip>
                                </li>
                              </ul>
                            </transition>
                          </li>
                        </ul>
                      </transition>
                    </li>
                  </template>
                </ul>
              </nav>
              <div class="share-button p-2">
                <MainButton
                  :type-button="shareButtonDisabled ? 'disabled' : ''"
                  title="Share Quote Link"
                  class-map="mt-10 mb-10 flex gap-1 align-center green-btn" 
                  @click="share"
                >
                  <template #icon>
                    <svg width="20px" height="20px" viewBox="0 0 32 32" id="Outlined" xmlns="http://www.w3.org/2000/svg">
                      <title/>
                      <g id="Fill">
                        <path d="M25,22a4,4,0,0,0-3.26,1.69l-11-6.4A4,4,0,0,0,11,16a4.14,4.14,0,0,0-.1-.87L22,8.65A4,4,0,1,0,21,6a4.14,4.14,0,0,0,.1.87L10,13.35A4,4,0,1,0,7,20a4,4,0,0,0,2.66-1L21,25.6c0,.13,0,.26,0,.4a4,4,0,1,0,4-4ZM25,4a2,2,0,1,1-2,2A2,2,0,0,1,25,4ZM7,18a2,2,0,1,1,2-2A2,2,0,0,1,7,18ZM25,28a2,2,0,1,1,2-2A2,2,0,0,1,25,28Z"/>
                      </g>
                    </svg>
                  </template>
                </MainButton>
              </div>
            </div>
            <div class="content">
                <TopBar />
                <router-view />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@store';
import { linkTo, nestedMenu, enter, leave } from './index';
import { helper as $h } from '@scripts/utils/helper';
import TopBar from '@components/TopBar.vue';
import MobileMenu from '@components/MobileMenu/MobileMenu.vue';
import DarkModeSwitcher from '@components/DarkModeSwitcher.vue';
import SideMenuTooltip from '@components/Tooltips/SideMenuTooltip.vue';
import MinLogo from "@assets/images/svg/min-logo.svg";
import DropdownArrow from "@components/UI/DropdownArrow.vue";
import Logo from "@images/svg/logo.svg";
import MainButton from '../../Components/Button/MainButton.vue'

const route = useRoute();
const router = useRouter();
const store = useStore();
const formattedMenu = ref([]);
const sideMenu = computed(() => nestedMenu(route));

const website = computed(() =>store.getters['website/website']);
const shareButtonDisabled = computed(() => store.getters['auth/getTrialPlanIsExpired']);

const getFormattedMenu = () => {
    formattedMenu.value = $h.toRaw(sideMenu.value)
}

function share() {
  const specificRoute = `${ window.location.protocol }//${ website.value.code }.${ window.location.hostname }/quote`;
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(specificRoute).then(() => {
      alert('Quote link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  } else {
    alert('Quote link can\'t be copied to clipboard!');
  }
}

watch(
    computed(() => route.path),
    () => {
        getFormattedMenu()
    },
    {
        immediate: true
    }
);


onBeforeMount(() => {
    if (store.getters['main/impersonated']) {
        store.commit('sideMenu/resetState')
        store.dispatch('main/setImpersonated', false)
    }
});

onMounted(() => {
    cash('body').removeClass('error-page').removeClass('login').addClass('main');

    getFormattedMenu();
});
</script>

<style scoped lang="scss">
.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.share-button {
  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 1280px) {
    &::v-deep(span) {
      display: none !important;
    }
  }
}

</style>
