<template>
    <Tab v-show="show">
        <Title title="About us" type-title="medium" />

        <div class="grid grid-cols-1 xl:grid-cols-3">
            <div class="pt-2">
                <div class="flex justify-center gap-4 items-center">
                    <div>Video</div>
                    <Switch :side="side" :checked="isChecked" @click="switchMode" style="margin: 0" />
                    <div>Image</div>
                </div>
                <div class="pt-[1.5rem]">
                    <div v-show="formData.about_us.media_type === 'image'">
                        <Title title="Image" type-title="small" class="!mt-0 mb-4 !text-center" />
                        <ImageUploader
                            :url="formData.about_us.image"
                            @fileUploaded="fileUploaded"
                        />
                    </div>
                    <div v-show="formData.about_us.media_type === 'video'">
                        <FormInput
                            v-model="validate.about_us.video.$model"
                            :errors="validate.about_us.video.$errors"
                            id="website-about-us-video"
                            label="YouTube link"
                            placeholder="Enter youtube link"
                            class="mb-3 mr-4"
                            @click="validate.$touch()"
                        />
                        <iframe
                            v-if="!validate.about_us.video.$error"
                            width="96.5%"
                            height="250"
                            :src="getYouTubeIframeUrl(formData.about_us.video)"
                            frameborder="0"
                            allowfullscreen
                        />
                    </div>
                </div>
            </div>
            <div class="col-span-2">
                <FormInput
                    id="website-about-us-title"
                    v-model="validate.about_us.title.$model"
                    :errors="validate.about_us.title.$errors"
                    placeholder="Enter title"
                    label="Title"
                />

                <div class="form-group mt-2">
                    <FormTextarea
                        v-model="validate.about_us.description.$model"
                        :errors="validate.about_us.description.$errors"
                        label="Description"
                        placeholder="Enter description"
                        class-map="form-control mt-1"
                        cols="60"
                        rows="12"
                    />
                </div>

                <div class="flex justify-center w-full mt-5">
                    <MainButton
                        :type-button="processing ? 'disabled' : ''"
                        title="Save"
                        @click="save"
                    />
                </div>
            </div>
        </div>

        <div>
            <Title title="Footer Info" type-title="medium" />

            <div class="grid gap-4">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormInput
                        id="website-header-email-input"
                        v-model="validateFooter.email.$model"
                        :errors="validateFooter.email.$errors"
                        type="email"
                        label="Email"
                        placeholder="Enter email"
                    >
                        <template #prepend>
                            <div
                                class="input-group-text"
                                v-text="'@'"
                            />
                        </template>
                    </FormInput>

                    <div class="input-group">
                        <InputPhone
                            v-model="validateFooter.contact_phone.$model"
                            :default-val="validateFooter.contact_phone.$model"
                            :errors="validateFooter.contact_phone.$errors"
                            label="Phone"
                            id="telephone"
                            placeholder="Enter phone"
                        />
                    </div>
                </div>
                <div>
                    <FormInput
                        v-model="validateFooter.address.$model"
                        :errors="validateFooter.address.$errors"
                        id="website-header-address-input"
                        label="Address"
                        placeholder="Enter address"
                    />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormInput
                        id="website-header-title-input"
                        v-model="validateFooter.facebook.$model"
                        :errors="validateFooter.facebook.$errors"
                        label="Facebook Link"
                        placeholder="Enter facebook link"
                    />
                    <FormInput
                        id="website-header-title-input"
                        v-model="validateFooter.twitter.$model"
                        :errors="validateFooter.twitter.$errors"
                        label="Twitter Link"
                        placeholder="Enter twitter link"
                    />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormInput
                        v-model="validateFooter.youtube.$model"
                        :errors="validateFooter.youtube.$errors"
                        label="YouTube Link"
                        placeholder="Enter youtube link"
                        id="website-header-title-input"
                    />
                    <FormInput
                        v-model="validateFooter.instagram.$model"
                        :errors="validateFooter.instagram.$errors"
                        id="website-header-title-input"
                        label="Instagram Link"
                        placeholder="Enter instagram link"
                    />
                </div>
            </div>

            <div class="flex justify-center w-full mt-5">
                <MainButton
                    :type-button="processing ? 'disabled' : ''"
                    title="Save"
                    @click="saveFooterInfo"
                />
            </div>
        </div>
    </Tab>
</template>

<script setup>
import {computed, getCurrentInstance, inject, onMounted, reactive, ref, toRefs} from "vue";
import { useStore } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { telInputValid } from "../../../../mixins/composition/phoneMask";
import { email, helpers, maxLength, required, requiredIf, url } from '@vuelidate/validators';
import ImageUploader from '@components/UI/ImageUploader';
import Tab from './Tab.vue';
import Title from "@components/Title.vue";
import Switch from "@components/UI/Switch.vue";
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import FormTextarea from "@components/UI/FormTextarea.vue";
import InputPhone from "@components/UI/InputPhone.vue";


const mediaPreview = ref(null);
const store = useStore();
const processing = ref(false);
const toast = inject('toast');
const side = ref('right');
const isChecked = ref(true);
const show = ref(store.getters['website/privacyPolicyTitle'] || store.getters['website/privacyPolicyTitle'] === '');
const websiteId = computed(() => store.getters['website/websiteId']);
const websiteSettings = computed(() => store.getters['website/websiteSettings']);
const loading = computed(() => store.getters['main/loading']);
const { proxy } = getCurrentInstance();
const content = computed({
    get() {
        return store.getters['website/mainPageContent'];
    },
    set(value) {
        store.commit('website/setMainPageContent', value);
    }
});
const aboutUsStore = computed({
    get() {
        return store.getters['website/mainPageAboutUs'];
    },
    set(value) {
        store.commit('website/setMainPageAboutUs', value);
    }
});
const footer = computed({
    get() {
       return store.getters['website/footer']
    },
    set(value) {
        store.commit('website/setWebsiteFooter', value)
    }
});
const formData = reactive({
    about_us: {
        image: '',
        video: '',
        media_type: 'image',
        title: '',
        description: ''
    },
});
const formData_v = {
    about_us: {
        video: { url },
        title: { required },
        description: { required }
    },
}
const mediaType = ref(null);
const validate = useVuelidate(formData_v, toRefs(formData));
const formDataFooter = reactive({
    email: footer?.value?.email,
    contact_phone: footer?.value?.contact_phone,
    address: footer?.value?.address,
    facebook: '',
    youtube: '',
    twitter: '',
    instagram: ''
});
const footerRules = {
    email: { email, maxLength: maxLength(40) },
    contact_phone: { phone: helpers.withMessage('Contact Number is invalid', telInputValid) },
    address: { maxLength: maxLength(100) },
    facebook: { url, requiredFacebook: requiredIf(formDataFooter.facebook.length > 0), maxLength: maxLength(120) },
    youtube: { url, requiredYoutube: requiredIf(formDataFooter.youtube.length > 0), maxLength: maxLength(200) },
    twitter: { url, requiredTwitter: requiredIf(formDataFooter.twitter.length > 0), maxLength: maxLength(120) },
    instagram: { url, requiredInstagram: requiredIf(formDataFooter.instagram.length > 0), maxLength: maxLength(120) },
}
const validateFooter = useVuelidate(footerRules, toRefs(formDataFooter));

const getYouTubeIframeUrl = (url) => {
    let videoId;

    if (url.split('watch?v=')[1] !== undefined) {
        videoId = url.split('watch?v=')[1].split('&')[0];
        formData.about_us.video = `https://www.youtube.com/embed/${ videoId }`;
    }

    return formData.about_us.video;
}

const fileUploaded = (file) => {
    mediaPreview.value = file;
}

const switchMode = () => {
    formData.about_us.media_type = formData.about_us.media_type === 'image' ? 'video' : 'image';
    mediaType.value = mediaType.value === 'image' ? 'video' : 'image';
    side.value = side.value === 'right' ? 'left' : 'right';
    isChecked.value = !isChecked.value;
}

const save = async () => {
    try {
        validate.value.$touch();

        if (!validate.value.$invalid) {
            processing.value = true;
            const settings = {
                websiteSettings: JSON.parse(JSON.stringify(websiteSettings.value))
            }

            settings.websiteSettings.about_us = formData.about_us;

            if (mediaPreview.value) {
                const formData = new FormData();
                formData.append('file', mediaPreview.value);
                const { data: { media: { url } } } = await store.dispatch('main/media', formData);

                settings.websiteSettings.about_us.image = url;
            }

            const { data: { message } } = await store.dispatch('website/settings', {
                settings,
                website_id: websiteId.value,
                type: 'MainPage'
            });

            toast({
                type: 'success',
                text: message
            });

            aboutUsStore.value = formData.about_us;

            setTimeout(() => {
                processing.value = false;
            }, 500);
        }
    } catch (e) {
        proxy.$errorHandler(e);
        processing.value = false;
    }
}


const saveFooterInfo = async () => {
    try {
        validateFooter.value.$touch();

        if (!validateFooter.value.$invalid) {
            const settings = {
                websiteSettings: JSON.parse(JSON.stringify(websiteSettings.value))
            }

            settings.websiteSettings.footer = formDataFooter;

            const { data: { message } } = await store.dispatch('website/settings', {
                settings,
                website_id: websiteId.value,
                type: 'MainPage'
            });

            websiteSettings.value = settings.websiteSettings;

            toast({
                type: 'success',
                text: 'Footer info successfully updated.'
            });

            footer.value = formDataFooter;
        }
    } catch (e) {
        proxy.$errorHandler(e);
        processing.value = false;
    }
}


const getAboutUsData = () => {
    const { image, title, description, media_type, video } = aboutUsStore.value ?? {};
    const { address, contact_phone, facebook, youtube, twitter, instagram } = footer.value ?? {};

    formData.about_us.image = image ?? '';
    formData.about_us.title = title ?? '';
    formData.about_us.description = description ?? '';
    formData.about_us.video = video ?? '';
    formData.about_us.media_type = media_type ?? 'image';
    mediaType.value = media_type;

    formDataFooter.email = footer.value?.email ?? '';
    formDataFooter.contact_phone = contact_phone ?? '';
    formDataFooter.address = address ?? '';
    formDataFooter.facebook = facebook ?? '';
    formDataFooter.youtube = youtube ?? '';
    formDataFooter.twitter = twitter ?? '';
    formDataFooter.instagram = instagram ?? '';

    side.value = media_type === 'image' ? 'right': 'left';
    isChecked.value = media_type !== 'video';
}


onMounted(() => {
    getAboutUsData();
});
</script>
<style lang="scss" scoped>
:deep(.height-img-wrapper){
    height: 10rem;
    width: 10rem;
}
.about-us-switcher{
    background-color: #3160d8!important;
    &:before{
        background-color:#FFFFFF!important;
    }
}
iframe{
    .dark-mode-switcher{
        display: none;
    }
}
</style>
