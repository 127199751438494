export default function selectedWebsite({ next, store }) {
    if (window.history.length === 1) {
        return next();
    }

    if (!store.getters['website/domain']) {
        return next({
            name: 'dashboard'
        });
    }

    return next();
}