export const phoneList = [
  {
    "name": "United States",
    "code": "+1",
    "flag": "us",
    "preferred": true,
    "phoneMask": ["+1 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Australia",
    "code": "+61",
    "flag": "au",
    "preferred": true,
    "phoneMask": ["+61 # #### ####", ""],
    "lastPreferred": true,
    'regulars': /\+61 \d \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Afghanistan (\u202Bافغانستان\u202C\u200E)",
    "code": "+93",
    "flag": "af",
    "preferred": false,
    "phoneMask": ["+93 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+93 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Albania (Shqipëri)",
    "code": "+355",
    "flag": "al",
    "preferred": false,
    "phoneMask": ["+355 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+355 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Algeria (‫الجزائر‬‎)",
    "code": "+213",
    "flag": "dz",
    "preferred": false,
    "phoneMask": ["+213 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+213 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "American Samoa",
    "code": "+1684",
    "flag": "as",
    "preferred": false,
    "phoneMask": ["+1 684 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 684 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Andorra",
    "code": "+376",
    "flag": "ad",
    "preferred": false,
    "phoneMask": ["+376 ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+376 \d\d\d \d\d\d/g
  },
  {
    "name": "Angola",
    "code": "+244",
    "flag": "ao",
    "preferred": false,
    "phoneMask": ["+244 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+244 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Anguilla",
    "code": "+1264",
    "flag": "ai",
    "preferred": false,
    "phoneMask": ["+1 264 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 264 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Antigua and Barbuda",
    "code": "+1268",
    "flag": "ag",
    "preferred": false,
    "phoneMask": ["+1 268 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 268 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Argentina",
    "code": "+54",
    "flag": "ar",
    "preferred": false,
    "phoneMask": ["+54 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+54 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Armenia (Հայաստան)",
    "code": "+374",
    "flag": "am",
    "preferred": false,
    "phoneMask": ["+374 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+374 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Aruba",
    "code": "+297",
    "flag": "aw",
    "preferred": false,
    "phoneMask": ["+297 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+297 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Austria (Österreich)",
    "code": "+43",
    "flag": "at",
    "preferred": false,
    "phoneMask": ["+43 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+43 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Azerbaijan (Azərbaycan)",
    "code": "+994",
    "flag": "az",
    "preferred": false,
    "phoneMask": ["+994 ## ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+994 \d\d \d\d\d \d\d \d\d/g
  },
  {
    "name": "Bahamas",
    "code": "+1242",
    "flag": "bs",
    "preferred": false,
    "phoneMask": ["+1 242 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 242 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Bahrain (‫البحرين‬‎)",
    "code": "+973",
    "flag": "bh",
    "preferred": false,
    "phoneMask": ["+973 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+973 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Bangladesh (বাংলাদেশ)",
    "code": "+880",
    "flag": "bd",
    "preferred": false,
    "phoneMask": ["+880 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+880 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Barbados",
    "code": "+1246",
    "flag": "bb",
    "preferred": false,
    "phoneMask": ["+1 246 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 246 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Belarus (Беларусь)",
    "code": "+375",
    "flag": "by",
    "preferred": false,
    "phoneMask": ["+375 ## ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+375 \d\d \d\d\d \d\d \d\d/g
  },
  {
    "name": "Belgium (België)",
    "code": "+32",
    "flag": "be",
    "preferred": false,
    "phoneMask": ["+32 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+32 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Belize",
    "code": "+501",
    "flag": "bz",
    "preferred": false,
    "phoneMask": ["+501 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+501 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Benin (Bénin)",
    "code": "+229",
    "flag": "bj",
    "preferred": false,
    "phoneMask": ["+229 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+229 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Bermuda",
    "code": "+1441",
    "flag": "bm",
    "preferred": false,
    "phoneMask": ["+1 441 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 441 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Bhutan (འབྲུག)",
    "code": "+975",
    "flag": "bt",
    "preferred": false,
    "phoneMask": ["+975 17 ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+975 17 \d\d\d \d\d\d/g
  },
  {
    "name": "Bolivia",
    "code": "+591",
    "flag": "bo",
    "preferred": false,
    "phoneMask": ["+591 # ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+591 \d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Bosnia and Herzegovina (Босна иХерцеговина)",
    "code": "+387",
    "flag": "ba",
    "preferred": false,
    "phoneMask": ["+387 ## #####", ""],
    "lastPreferred": false,
    'regulars': /\+387 \d\d \d\d\d\d\d/g
  },
  {
    "name": "Botswana",
    "code": "+267",
    "flag": "bw",
    "preferred": false,
    "phoneMask": ["+267 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+267 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Brazil (Brasil)",
    "code": "+55",
    "flag": "br",
    "preferred": false,
    "phoneMask": ["+55 ## #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+55 \d\d \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "British Indian Ocean Territory",
    "code": "+246",
    "flag": "io",
    "preferred": false,
    "phoneMask": ["+246 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+246 \d\d\d \d\d\d\d/g
  },
  {
    "name": "British Virgin Islands",
    "code": "+1284",
    "flag": "vg",
    "preferred": false,
    "phoneMask": ["+1 284 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 284 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Brunei",
    "code": "+673",
    "flag": "bn",
    "preferred": false,
    "phoneMask": ["+673 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+673 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Bulgaria (България)",
    "code": "+359",
    "flag": "bg",
    "preferred": false,
    "phoneMask": ["+359 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+359 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Burkina Faso",
    "code": "+226",
    "flag": "bf",
    "preferred": false,
    "phoneMask": ["+226 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+226 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Burundi (Uburundi)",
    "code": "+257",
    "flag": "bi",
    "preferred": false,
    "phoneMask": ["+257 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+257 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Cambodia (កម្ពុជា)",
    "code": "+855",
    "flag": "kh",
    "preferred": false,
    "phoneMask": ["+855 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+855 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Cameroon (Cameroun)",
    "code": "+237",
    "flag": "cm",
    "preferred": false,
    "phoneMask": ["+237 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+237 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Canada",
    "code": "+1",
    "flag": "ca",
    "preferred": false,
    "phoneMask": ["+1 ### ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+1 \d\d\d \d\d\d \d\d \d\d/g
  },
  {
    "name": "Cape Verde (Kabu Verdi)",
    "code": "+238",
    "flag": "cv",
    "preferred": false,
    "phoneMask": ["+238 ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+238 \d\d\d \d\d \d\d/g
  },
  {
    "name": "Caribbean Netherlands",
    "code": "+599",
    "flag": "bq",
    "preferred": false,
    "phoneMask": ["+599 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+599 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Cayman Islands",
    "code": "+1345",
    "flag": "ky",
    "preferred": false,
    "phoneMask": ["+1 345 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 345 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Central African Republic (Républiquecentrafricaine)",
    "code": "+236",
    "flag": "cf",
    "preferred": false,
    "phoneMask": ["+236 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+236 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Chad (Tchad)",
    "code": "+235",
    "flag": "td",
    "preferred": false,
    "phoneMask": ["+235 ## ## ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+235 \d\d \d\d \d\d \d\d/g
  },
  {
    "name": "Chile",
    "code": "+56",
    "flag": "cl",
    "preferred": false,
    "phoneMask": ["+56 # #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+56 \d \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "China (中国)",
    "code": "+86",
    "flag": "cn",
    "preferred": false,
    "phoneMask": ["+86 ### ### ##", ""],
    "lastPreferred": false,
    'regulars': /\+86 \d\d\d \d\d\d \d\d/g
  },
  {
    "name": "Colombia",
    "code": "+57",
    "flag": "co",
    "preferred": false,
    "phoneMask": ["+57 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+57 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Comoros (‫جزر القمر‬‎)",
    "code": "+269",
    "flag": "km",
    "preferred": false,
    "phoneMask": ["+269 ## #####", ""],
    "lastPreferred": false,
    'regulars': /\+269 \d\d \d\d\d\d\d/g
  },
  {
    "name": "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    "code": "+243",
    "flag": "cd",
    "preferred": false,
    "phoneMask": ["+243 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+243 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Congo (Republic) (Congo-Brazzaville)",
    "code": "+242",
    "flag": "cg",
    "preferred": false,
    "phoneMask": ["+242 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+242 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Cook Islands",
    "code": "+682",
    "flag": "ck",
    "preferred": false,
    "phoneMask": ["+682 ## ###", ""],
    "lastPreferred": false,
    'regulars': /\+682 \d\d \d\d\d/g
  },
  {
    "name": "Costa Rica",
    "code": "+506",
    "flag": "cr",
    "preferred": false,
    "phoneMask": ["+506 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+506 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Côte d’Ivoire",
    "code": "+225",
    "flag": "ci",
    "preferred": false,
    "phoneMask": ["+225 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+225 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Croatia (Hrvatska)",
    "code": "+385",
    "flag": "hr",
    "preferred": false,
    "phoneMask": ["+385 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+385 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Cuba",
    "code": "+53",
    "flag": "cu",
    "preferred": false,
    "phoneMask": ["+53 # ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+53 \d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Curaçao",
    "code": "+599",
    "flag": "cw",
    "preferred": false,
    "phoneMask": ["+599 9### ####", ""],
    "lastPreferred": false,
    'regulars': /\++599 9\d\d\d \d\d\d\d/g
  },
  {
    "name": "Cyprus (Κύπρος)",
    "code": "+357",
    "flag": "cy",
    "preferred": false,
    "phoneMask": ["+357 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+357 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Czech Republic (Česká republika)",
    "code": "+420",
    "flag": "cz",
    "preferred": false,
    "phoneMask": ["+420 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+420 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Denmark (Danmark)",
    "code": "+45",
    "flag": "dk",
    "preferred": false,
    "phoneMask": ["+45 ## ## ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+45 \d\d \d\d \d\d \d\d/g
  },
  {
    "name": "Djibouti",
    "code": "+253",
    "flag": "dj",
    "preferred": false,
    "phoneMask": ["+253 ## ## ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+253 \d\d \d\d \d\d \d\d/g
  },
  {
    "name": "Dominica",
    "code": "+1767",
    "flag": "dm",
    "preferred": false,
    "phoneMask": ["+1 767 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 767 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Dominican Republic (RepúblicaDominicana)",
    "code": "+1",
    "flag": "do",
    "preferred": false,
    "phoneMask": ["+1 829 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 829 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Ecuador",
    "code": "+593",
    "flag": "ec",
    "preferred": false,
    "phoneMask": ["+593 # ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+593 \d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Egypt (‫مصر‬‎)",
    "code": "+20",
    "flag": "eg",
    "preferred": false,
    "phoneMask": ["+20 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+20 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "El Salvador",
    "code": "+503",
    "flag": "sv",
    "preferred": false,
    "phoneMask": ["+503 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+503 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Equatorial Guinea (Guinea Ecuatorial)",
    "code": "+240",
    "flag": "gq",
    "preferred": false,
    "phoneMask": ["+240 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+240 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Eritrea",
    "code": "+291",
    "flag": "er",
    "preferred": false,
    "phoneMask": ["+291 # ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+291 \d \d\d\d \d\d\d/g
  },
  {
    "name": "Estonia (Eesti)",
    "code": "+372",
    "flag": "ee",
    "preferred": false,
    "phoneMask": ["+372 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+372 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Ethiopia",
    "code": "+251",
    "flag": "et",
    "preferred": false,
    "phoneMask": ["+251 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+251 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Falkland Islands (Islas Malvinas)",
    "code": "+500",
    "flag": "fk",
    "preferred": false,
    "phoneMask": ["+500 #####", ""],
    "lastPreferred": false,
    'regulars': /\+500 \d\d\d\d\d/g
  },
  {
    "name": "Faroe Islands (Føroyar)",
    "code": "+298",
    "flag": "fo",
    "preferred": false,
    "phoneMask": ["+298 ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+298 \d\d\d \d\d\d/g
  },
  {
    "name": "Fiji",
    "code": "+679",
    "flag": "fj",
    "preferred": false,
    "phoneMask": ["+679 ## #####", ""],
    "lastPreferred": false,
    'regulars': /\+679 \d\d \d\d\d\d\d/g
  },
  {
    "name": "Finland (Suomi)",
    "code": "+358",
    "flag": "fi",
    "preferred": false,
    "phoneMask": ["+358 ### ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+358 \d\d\d \d\d\d \d\d \d\d/g
  },
  {
    "name": "France",
    "code": "+33",
    "flag": "fr",
    "preferred": false,
    "phoneMask": ["+33 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+33 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "French Guiana (Guyane française)",
    "code": "+594",
    "flag": "gf",
    "preferred": false,
    "phoneMask": ["+594 ##### ####", ""],
    "lastPreferred": false,
    'regulars': /\+594 \d\d\d\d\d \d\d\d\d/g
  },
  {
    "name": "French Polynesia (Polynésiefrançaise)",
    "code": "+689",
    "flag": "pf",
    "preferred": false,
    "phoneMask": ["+689 ## ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+689 \d\d \d\d \d\d/g
  },
  {
    "name": "Gabon",
    "code": "+241",
    "flag": "ga",
    "preferred": false,
    "phoneMask": ["+241 # ## ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+241 \d \d\d \d\d \d\d/g
  },
  {
    "name": "Gambia",
    "code": "+220",
    "flag": "gm",
    "preferred": false,
    "phoneMask": ["+220 ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+220 \d\d\d \d\d \d\d/g
  },
  {
    "name": "Georgia (საქართველო)",
    "code": "+995",
    "flag": "ge",
    "preferred": false,
    "phoneMask": ["+995 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+995 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Germany (Deutschland)",
    "code": "+49",
    "flag": "de",
    "preferred": false,
    "phoneMask": ["+49 ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+49 \d\d\d \d\d\d/g
  },
  {
    "name": "Ghana (Gaana)",
    "code": "+233",
    "flag": "gh",
    "preferred": false,
    "phoneMask": ["+233 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+233 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Gibraltar",
    "code": "+350",
    "flag": "gi",
    "preferred": false,
    "phoneMask": ["+350 ### #####", ""],
    "lastPreferred": false,
    'regulars': /\+350 \d\d\d \d\d\d\d\d/g
  },
  {
    "name": "Greece (Ελλάδα)",
    "code": "+30",
    "flag": "gr",
    "preferred": false,
    "phoneMask": ["+30 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+30 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Greenland (Kalaallit Nunaat)",
    "code": "+299",
    "flag": "gl",
    "preferred": false,
    "phoneMask": ["+299 ## ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+299 \d\d \d\d \d\d/g
  },
  {
    "name": "Grenada",
    "code": "+1473",
    "flag": "gd",
    "preferred": false,
    "phoneMask": ["+1 473 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 473 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Guadeloupe",
    "code": "+590",
    "flag": "gp",
    "preferred": false,
    "phoneMask": ["+590 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+590 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Guam",
    "code": "+1671",
    "flag": "gu",
    "preferred": false,
    "phoneMask": ["+1 671 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 671 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Guatemala",
    "code": "+502",
    "flag": "gt",
    "preferred": false,
    "phoneMask": ["+502 # ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+502 \d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Guinea (Guinée)",
    "code": "+224",
    "flag": "gn",
    "preferred": false,
    "phoneMask": ["+224 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+224 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Guinea-Bissau (Guiné Bissau)",
    "code": "+245",
    "flag": "gw",
    "preferred": false,
    "phoneMask": ["+245 # ######", ""],
    "lastPreferred": false,
    'regulars': /\+245 \d \d\d\d\d\d\d/g
  },
  {
    "name": "Guyana",
    "code": "+592",
    "flag": "gy",
    "preferred": false,
    "phoneMask": ["+592 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+592 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Haiti",
    "code": "+509",
    "flag": "ht",
    "preferred": false,
    "phoneMask": ["+509 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+509 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Honduras",
    "code": "+504",
    "flag": "hn",
    "preferred": false,
    "phoneMask": ["+504 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+504 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Hong Kong (香港)",
    "code": "+852",
    "flag": "hk",
    "preferred": false,
    "phoneMask": ["+852 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+852 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Hungary (Magyarország)",
    "code": "+36",
    "flag": "hu",
    "preferred": false,
    "phoneMask": ["+36 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+36 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Iceland (Ísland)",
    "code": "+354",
    "flag": "is",
    "preferred": false,
    "phoneMask": ["+354 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+354 \d\d\d \d\d\d\d/g
  },
  {
    "name": "India (भारत)",
    "code": "+91",
    "flag": "in",
    "preferred": false,
    "phoneMask": ["+91 #### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+91 \d\d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Indonesia",
    "code": "+62",
    "flag": "id",
    "preferred": false,
    "phoneMask": ["+62 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+62 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Iran (‫ایران‬‎)",
    "code": "+98",
    "flag": "ir",
    "preferred": false,
    "phoneMask": ["+98 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+98 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Iraq (‫العراق‬‎)",
    "code": "+964",
    "flag": "iq",
    "preferred": false,
    "phoneMask": ["+964 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+964 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Ireland",
    "code": "+353",
    "flag": "ie",
    "preferred": false,
    "phoneMask": ["+353 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+353 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Israel (‫ישראל‬‎)",
    "code": "+972",
    "flag": "il",
    "preferred": false,
    "phoneMask": ["+972 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+972 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Italy (Italia)",
    "code": "+39",
    "flag": "it",
    "preferred": false,
    "phoneMask": ["+39 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+39 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Jamaica",
    "code": "+1876",
    "flag": "jm",
    "preferred": false,
    "phoneMask": ["+1 876 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 876 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Japan (日本)",
    "code": "+81",
    "flag": "jp",
    "preferred": false,
    "phoneMask": ["+81 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+81 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Jordan (‫الأردن‬‎)",
    "code": "+962",
    "flag": "jo",
    "preferred": false,
    "phoneMask": ["+962 # #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+962 \d \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Kazakhstan (Казахстан)",
    "code": "+7",
    "flag": "kz",
    "preferred": false,
    "phoneMask": ["+7 7## ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+7 7\d\d \d\d\d \d\d \d\d/g
  },
  {
    "name": "Kenya",
    "code": "+254",
    "flag": "ke",
    "preferred": false,
    "phoneMask": ["+254 ### ######", ""],
    "lastPreferred": false,
    'regulars': /\+254 \d\d\d \d\d\d\d\d\d/g
  },
  {
    "name": "Kiribati",
    "code": "+686",
    "flag": "ki",
    "preferred": false,
    "phoneMask": ["+686 ## ###", ""],
    "lastPreferred": false,
    'regulars': /\+686 \d\d \d\d\d/g
  },
  {
    "name": "Kosovo",
    "code": "+383",
    "flag": "xk",
    "preferred": false,
    "phoneMask": ["+383 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+383 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Kuwait (‫الكويت‬‎)",
    "code": "+965",
    "flag": "kw",
    "preferred": false,
    "phoneMask": ["+965 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+965 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Kyrgyzstan (Кыргызстан)",
    "code": "+996",
    "flag": "kg",
    "preferred": false,
    "phoneMask": ["+996 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+996 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Laos (ລາວ)",
    "code": "+856",
    "flag": "la",
    "preferred": false,
    "phoneMask": ["+856 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+856 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Latvia (Latvija)",
    "code": "+371",
    "flag": "lv",
    "preferred": false,
    "phoneMask": ["+371 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+371 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Lebanon (‫لبنان‬‎)",
    "code": "+961",
    "flag": "lb",
    "preferred": false,
    "phoneMask": ["+961 # ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+961 \d \d\d\d \d\d\d/g
  },
  {
    "name": "Lesotho",
    "code": "+266",
    "flag": "ls",
    "preferred": false,
    "phoneMask": ["+266 # ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+266 \d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Liberia",
    "code": "+231",
    "flag": "lr",
    "preferred": false,
    "phoneMask": ["+231 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+231 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Libya (‫ليبيا‬‎)",
    "code": "+218",
    "flag": "ly",
    "preferred": false,
    "phoneMask": ["+218 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+218 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Liechtenstein",
    "code": "+423",
    "flag": "li",
    "preferred": false,
    "phoneMask": ["+423 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+423 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Lithuania (Lietuva)",
    "code": "+370",
    "flag": "lt",
    "preferred": false,
    "phoneMask": ["+370 ### ## ###", ""],
    "lastPreferred": false,
    'regulars': /\+370 \d\d\d \d\d \d\d\d/g
  },
  {
    "name": "Luxembourg",
    "code": "+352",
    "flag": "lu",
    "preferred": false,
    "phoneMask": ["+352 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+352 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Macau (澳門)",
    "code": "+853",
    "flag": "mo",
    "preferred": false,
    "phoneMask": ["+853 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+853 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Macedonia (FYROM) (Македонија)",
    "code": "+389",
    "flag": "mk",
    "preferred": false,
    "phoneMask": ["+389 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+389 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Madagascar (Madagasikara)",
    "code": "+261",
    "flag": "mg",
    "preferred": false,
    "phoneMask": ["+261 ## ## #####", ""],
    "lastPreferred": false,
    'regulars': /\+261 \d\d \d\d \d\d\d\d\d/g
  },
  {
    "name": "Malawi",
    "code": "+265",
    "flag": "mw",
    "preferred": false,
    "phoneMask": ["+265 1 ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+265 1 \d\d\d \d\d\d/g
  },
  {
    "name": "Malaysia",
    "code": "+60",
    "flag": "my",
    "preferred": false,
    "phoneMask": ["+60 # ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+60 \d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Maldives",
    "code": "+960",
    "flag": "mv",
    "preferred": false,
    "phoneMask": ["+960 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+960 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Mali",
    "code": "+223",
    "flag": "ml",
    "preferred": false,
    "phoneMask": ["+223 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+223 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Malta",
    "code": "+356",
    "flag": "mt",
    "preferred": false,
    "phoneMask": ["+356 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+356 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Marshall Islands",
    "code": "+692",
    "flag": "mh",
    "preferred": false,
    "phoneMask": ["+692 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+692 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Martinique",
    "code": "+596",
    "flag": "mq",
    "preferred": false,
    "phoneMask": ["+596 ### ## ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+596 \d\d\d \d\d \d\d \d\d/g
  },
  {
    "name": "Mauritania (‫موريتانيا‬‎)",
    "code": "+222",
    "flag": "mr",
    "preferred": false,
    "phoneMask": ["+222 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+222 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Mauritius (Moris)",
    "code": "+230",
    "flag": "mu",
    "preferred": false,
    "phoneMask": ["+230 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+230 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Mayotte",
    "code": "+262",
    "flag": "yt",
    "preferred": false,
    "phoneMask": ["+262 ##### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1262 \d\d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Mexico (México)",
    "code": "+52",
    "flag": "mx",
    "preferred": false,
    "phoneMask": ["+52 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+52 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Micronesia",
    "code": "+691",
    "flag": "fm",
    "preferred": false,
    "phoneMask": ["+691 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+691 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Moldova (Republica Moldova)",
    "code": "+373",
    "flag": "md",
    "preferred": false,
    "phoneMask": ["+373 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+373 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Monaco",
    "code": "+377",
    "flag": "mc",
    "preferred": false,
    "phoneMask": ["+377 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+377 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Mongolia (Монгол)",
    "code": "+976",
    "flag": "mn",
    "preferred": false,
    "phoneMask": ["+976 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+976 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Montenegro (Crna Gora)",
    "code": "+382",
    "flag": "me",
    "preferred": false,
    "phoneMask": ["+382 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+382 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Montserrat",
    "code": "+1664",
    "flag": "ms",
    "preferred": false,
    "phoneMask": ["+1 664 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 664 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Morocco (‫المغرب‬‎)",
    "code": "+212",
    "flag": "ma",
    "preferred": false,
    "phoneMask": ["+212 ## #### ###", ""],
    "lastPreferred": false,
    'regulars': /\+212 \d\d \d\d\d\d \d\d\d/g
  },
  {
    "name": "Mozambique (Moçambique)",
    "code": "+258",
    "flag": "mz",
    "preferred": false,
    "phoneMask": ["+258 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+258 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Myanmar (Burma) (မြန်မာ)",
    "code": "+95",
    "flag": "mm",
    "preferred": false,
    "phoneMask": ["+95 # ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+95 \d \d\d\d \d\d\d/g
  },
  {
    "name": "Namibia (Namibië)",
    "code": "+264",
    "flag": "na",
    "preferred": false,
    "phoneMask": ["+264 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+264 \d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Nauru",
    "code": "+674",
    "flag": "nr",
    "preferred": false,
    "phoneMask": ["+674 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+674 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Nepal (नेपाल)",
    "code": "+977",
    "flag": "np",
    "preferred": false,
    "phoneMask": ["+977 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+977 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Netherlands (Nederland)",
    "code": "+31",
    "flag": "nl",
    "preferred": false,
    "phoneMask": ["+31 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+31 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "New Caledonia (Nouvelle-Calédonie)",
    "code": "+687",
    "flag": "nc",
    "preferred": false,
    "phoneMask": ["+687 ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+687 \d\d \d\d\d\d/g
  },
  {
    "name": "New Zealand",
    "code": "+64",
    "flag": "nz",
    "preferred": false,
    "phoneMask": ["+64 # ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+64 \d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Nicaragua",
    "code": "+505",
    "flag": "ni",
    "preferred": false,
    "phoneMask": ["+505 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+505 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Niger (Nijar)",
    "code": "+227",
    "flag": "ne",
    "preferred": false,
    "phoneMask": ["+227 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+227 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Nigeria",
    "code": "+234",
    "flag": "ng",
    "preferred": false,
    "phoneMask": ["+234 ## ### ##", ""],
    "lastPreferred": false,
    'regulars': /\+234 \d\d \d\d\d \d\d/g
  },
  {
    "name": "Niue",
    "code": "+683",
    "flag": "nu",
    "preferred": false,
    "phoneMask": ["+683 ####", ""],
    "lastPreferred": false,
    'regulars': /\+683 \d\d\d\d/g
  },
  {
    "name": "Norfolk Island",
    "code": "+672",
    "flag": "nf",
    "preferred": false,
    "phoneMask": ["+672 3## ###", ""],
    "lastPreferred": false,
    'regulars': /\+672 3\d\d \d\d\d/g
  },
  {
    "name": "North Korea (조선 민주주의 인민 공화국)",
    "code": "+850",
    "flag": "kp",
    "preferred": false,
    "phoneMask": ["+850 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+850 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Northern Mariana Islands",
    "code": "+1670",
    "flag": "mp",
    "preferred": false,
    "phoneMask": ["+1 670 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 670 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Norway (Norge)",
    "code": "+47",
    "flag": "no",
    "preferred": false,
    "phoneMask": ["+47 ### ## ###", ""],
    "lastPreferred": false,
    'regulars': /\+47 \d\d \d\d \d\d\d/g
  },
  {
    "name": "Oman (‫عُمان‬‎)",
    "code": "+968",
    "flag": "om",
    "preferred": false,
    "phoneMask": ["+968 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+968 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Pakistan (‫پاکستان‬‎)",
    "code": "+92",
    "flag": "pk",
    "preferred": false,
    "phoneMask": ["+92 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+92 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Palau",
    "code": "+680",
    "flag": "pw",
    "preferred": false,
    "phoneMask": ["+680 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+680 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Palestine (‫فلسطين‬‎)",
    "code": "+970",
    "flag": "ps",
    "preferred": false,
    "phoneMask": ["+970 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+970 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Panama (Panamá)",
    "code": "+507",
    "flag": "pa",
    "preferred": false,
    "phoneMask": ["+507 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+507 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Papua New Guinea",
    "code": "+675",
    "flag": "pg",
    "preferred": false,
    "phoneMask": ["+675 ### ## ###", ""],
    "lastPreferred": false,
    'regulars': /\+675 \d\d\d \d\d \d\d\d/g
  },
  {
    "name": "Paraguay",
    "code": "+595",
    "flag": "py",
    "preferred": false,
    "phoneMask": ["+595 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+595 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Peru (Perú)",
    "code": "+51",
    "flag": "pe",
    "preferred": false,
    "phoneMask": ["+51 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+51 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Philippines",
    "code": "+63",
    "flag": "ph",
    "preferred": false,
    "phoneMask": ["+63 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+63 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Poland (Polska)",
    "code": "+48",
    "flag": "pl",
    "preferred": false,
    "phoneMask": ["+48 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+48 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Portugal",
    "code": "+351",
    "flag": "pt",
    "preferred": false,
    "phoneMask": ["+351 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+351 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Puerto Rico",
    "code": "+1",
    "flag": "pr",
    "preferred": false,
    "phoneMask": ["+1 787 ### ####", "+1 939 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 787 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Qatar (‫قطر‬‎)",
    "code": "+974",
    "flag": "qa",
    "preferred": false,
    "phoneMask": ["+974 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+974 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Réunion (La Réunion)",
    "code": "+262",
    "flag": "re",
    "preferred": false,
    "phoneMask": ["+262 ##### ####", ""],
    "lastPreferred": false,
    'regulars': /\+262 \d\d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Romania (România)",
    "code": "+40",
    "flag": "ro",
    "preferred": false,
    "phoneMask": ["+40 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+40 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Russia (Россия)",
    "code": "+7",
    "flag": "ru",
    "preferred": false,
    "phoneMask": ["+7 ### ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+7 \d\d\d \d\d\d \d\d \d\d/g
  },
  {
    "name": "Rwanda",
    "code": "+250",
    "flag": "rw",
    "preferred": false,
    "phoneMask": ["+250 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+250 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Saint Barthélemy",
    "code": "+590",
    "flag": "bl",
    "preferred": false,
    "phoneMask": ["+590 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+590 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Saint Helena",
    "code": "+290",
    "flag": "sh",
    "preferred": false,
    "phoneMask": ["+290 ####", ""],
    "lastPreferred": false,
    'regulars': /\+290 \d\d\d\d/g
  },
  {
    "name": "Saint Kitts and Nevis",
    "code": "+1869",
    "flag": "kn",
    "preferred": false,
    "phoneMask": ["+1 869 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 869 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Saint Lucia",
    "code": "+1758",
    "flag": "lc",
    "preferred": false,
    "phoneMask": ["+1 758 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 758 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Saint Martin (Saint-Martin (partie française))",
    "code": "+590",
    "flag": "mf",
    "preferred": false,
    "phoneMask": ["+590 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+590 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Saint Pierre and Miquelon(Saint-Pierre-et-Miquelon)",
    "code": "+508",
    "flag": "pm",
    "preferred": false,
    "phoneMask": ["+508 ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+508 \d\d \d\d\d\d/g
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "code": "+1784",
    "flag": "vc",
    "preferred": false,
    "phoneMask": ["+1 784 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 784 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Samoa",
    "code": "+685",
    "flag": "ws",
    "preferred": false,
    "phoneMask": ["+685 ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+685 \d\d \d\d\d\d/g
  },
  {
    "name": "San Marino",
    "code": "+378",
    "flag": "sm",
    "preferred": false,
    "phoneMask": ["+378 #### ######", ""],
    "lastPreferred": false,
    'regulars': /\+378 \d\d\d \d\d\d\d\d\d/g
  },
  {
    "name": "São Tomé and Príncipe (São Tomé ePríncipe)",
    "code": "+239",
    "flag": "st",
    "preferred": false,
    "phoneMask": ["+239 ## #####", ""],
    "lastPreferred": false,
    'regulars': /\+239 \d\d \d\d\d\d\d/g
  },
  {
    "name": "Saudi Arabia (\u202Bالمملكة العربية السعودية\u202C\u200E)",
    "code": "+966",
    "flag": "sa",
    "preferred": false,
    "phoneMask": ["+966 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+966 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Senegal (Sénégal)",
    "code": "+221",
    "flag": "sn",
    "preferred": false,
    "phoneMask": ["+221 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+221 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Serbia (Србија)",
    "code": "+381",
    "flag": "rs",
    "preferred": false,
    "phoneMask": ["+381 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+381 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Seychelles",
    "code": "+248",
    "flag": "sc",
    "preferred": false,
    "phoneMask": ["+248 # ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+248 \d \d\d\d \d\d\d/g
  },
  {
    "name": "Sierra Leone",
    "code": "+232",
    "flag": "sl",
    "preferred": false,
    "phoneMask": ["+232 ## ######", ""],
    "lastPreferred": false,
    'regulars': /\+232 \d\d \d\d\d\d\d\d/g
  },
  {
    "name": "Singapore",
    "code": "+65",
    "flag": "sg",
    "preferred": false,
    "phoneMask": ["+65 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+65 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Sint Maarten",
    "code": "+1721",
    "flag": "sx",
    "preferred": false,
    "phoneMask": ["+1 721 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 721 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Slovakia (Slovensko)",
    "code": "+421",
    "flag": "sk",
    "preferred": false,
    "phoneMask": ["+421 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+421 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Slovenia (Slovenija)",
    "code": "+386",
    "flag": "si",
    "preferred": false,
    "phoneMask": ["+386 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+386 \d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Solomon Islands",
    "code": "+677",
    "flag": "sb",
    "preferred": false,
    "phoneMask": ["+677 #####", ""],
    "lastPreferred": false,
    'regulars': /\+677 \d\d\d\d\d/g
  },
  {
    "name": "Somalia (Soomaaliya)",
    "code": "+252",
    "flag": "so",
    "preferred": false,
    "phoneMask": ["+252 # ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+252 \d \d\d\d \d\d\d/g
  },
  {
    "name": "South Africa",
    "code": "+27",
    "flag": "za",
    "preferred": false,
    "phoneMask": ["+27 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+27 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "South Korea (대한민국)",
    "code": "+82",
    "flag": "kr",
    "preferred": false,
    "phoneMask": ["+82 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+82 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "South Sudan (‫جنوب السودان‬‎)",
    "code": "+211",
    "flag": "ss",
    "preferred": false,
    "phoneMask": ["+211 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+211 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Spain (España)",
    "code": "+34",
    "flag": "es",
    "preferred": false,
    "phoneMask": ["+34 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+34 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Sri Lanka (ශ්‍රී ලංකාව)",
    "code": "+94",
    "flag": "lk",
    "preferred": false,
    "phoneMask": ["+94 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+94 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Sudan (‫السودان‬‎)",
    "code": "+249",
    "flag": "sd",
    "preferred": false,
    "phoneMask": ["+249 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+249 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Suriname",
    "code": "+597",
    "flag": "sr",
    "preferred": false,
    "phoneMask": ["+597 ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+597 \d\d\d \d\d\d/g
  },
  {
    "name": "Svalbard and Jan Mayen",
    "code": "+47",
    "flag": "sj",
    "preferred": false,
    "phoneMask": ["+47 ### ## ###", ""],
    "lastPreferred": false,
    'regulars': /\+47 \d\d\d \d\d \d\d\d/g
  },
  {
    "name": "Swaziland",
    "code": "+268",
    "flag": "sz",
    "preferred": false,
    "phoneMask": ["+268 ## ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+268 \d\d \d\d \d\d\d\d/g
  },
  {
    "name": "Sweden (Sverige)",
    "code": "+46",
    "flag": "se",
    "preferred": false,
    "phoneMask": ["+46 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+46 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Switzerland (Schweiz)",
    "code": "+41",
    "flag": "ch",
    "preferred": false,
    "phoneMask": ["+41 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+41 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Syria (‫سوريا‬‎)",
    "code": "+963",
    "flag": "sy",
    "preferred": false,
    "phoneMask": ["+963 ## #### ###", ""],
    "lastPreferred": false,
    'regulars': /\+963 \d\d \d\d\d\d \d\d\d/g
  },
  {
    "name": "Taiwan (台灣)",
    "code": "+886",
    "flag": "tw",
    "preferred": false,
    "phoneMask": ["+886 #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+886 \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Tajikistan",
    "code": "+992",
    "flag": "tj",
    "preferred": false,
    "phoneMask": ["+992 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+992 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Tanzania",
    "code": "+255",
    "flag": "tz",
    "preferred": false,
    "phoneMask": ["+255 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+255 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Thailand (ไทย)",
    "code": "+66",
    "flag": "th",
    "preferred": false,
    "phoneMask": ["+66 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+66 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Timor-Leste",
    "code": "+670",
    "flag": "tl",
    "preferred": false,
    "phoneMask": ["+670 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+670 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Togo",
    "code": "+228",
    "flag": "tg",
    "preferred": false,
    "phoneMask": ["+228 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+228 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Tokelau",
    "code": "+690",
    "flag": "tk",
    "preferred": false,
    "phoneMask": ["+690 ####", ""],
    "lastPreferred": false,
    'regulars': /\+690 \d\d\d\d/g
  },
  {
    "name": "Tonga",
    "code": "+676",
    "flag": "to",
    "preferred": false,
    "phoneMask": ["+676 #####", ""],
    "lastPreferred": false,
    'regulars': /\+676 \d\d\d\d\d/g
  },
  {
    "name": "Trinidad and Tobago",
    "code": "+1868",
    "flag": "tt",
    "preferred": false,
    "phoneMask": ["+1 868 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 868 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Tunisia (‫تونس‬‎)",
    "code": "+216",
    "flag": "tn",
    "preferred": false,
    "phoneMask": ["+216 ## ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+216 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Turkey (Türkiye)",
    "code": "+90",
    "flag": "tr",
    "preferred": false,
    "phoneMask": ["+90 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+90 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Turkmenistan",
    "code": "+993",
    "flag": "tm",
    "preferred": false,
    "phoneMask": ["+993 # ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+993 \d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Turks and Caicos Islands",
    "code": "+1649",
    "flag": "tc",
    "preferred": false,
    "phoneMask": ["+1 649 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 649 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Tuvalu",
    "code": "+688",
    "flag": "tv",
    "preferred": false,
    "phoneMask": ["+688 2####", ""],
    "lastPreferred": false,
    'regulars': /\+688 2\d\d\d\d/g
  },
  {
    "name": "U.S. Virgin Islands",
    "code": "+1340",
    "flag": "vi",
    "preferred": false,
    "phoneMask": ["+1 340 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+1 340 \d\d\d \d\d\d\d/g
  },
  {
    "name": "Uganda",
    "code": "+256",
    "flag": "ug",
    "preferred": false,
    "phoneMask": ["+256 ### ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+256 \d\d\d \d\d\d \d\d\d/g
  },
  {
    "name": "Ukraine (Україна)",
    "code": "+380",
    "flag": "ua",
    "preferred": false,
    "phoneMask": ["+380 ## ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+380 \d\d \d\d\d \d\d \d\d/g
  },
  {
    "name": "United Arab Emirates (\u202Bالإمارات العربية المتحدة\u202C\u200E)",
    "code": "+9711",
    "flag": "ae",
    "preferred": false,
    "phoneMask": ["+971 1 ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+971 1 \d\d\d \d\d\d\d/g
  },
  {
    "name": "United Kingdom",
    "code": "+44",
    "flag": "gb",
    "preferred": false,
    "phoneMask": ["+44 ## #### ####", ""],
    "lastPreferred": false,
    'regulars': /\+44 \d\d \d\d\d\d \d\d\d\d/g
  },
  {
    "name": "Uruguay",
    "code": "+598",
    "flag": "uy",
    "preferred": false,
    "phoneMask": ["+598 # ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+598 \d \d\d\d \d\d \d\d/g
  },
  {
    "name": "Uzbekistan (Oʻzbekiston)",
    "code": "+998",
    "flag": "uz",
    "preferred": false,
    "phoneMask": ["+998 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+998 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Vanuatu",
    "code": "+678",
    "flag": "vu",
    "preferred": false,
    "phoneMask": ["+678 #####", ""],
    "lastPreferred": false,
    'regulars': /\+678 \d\d\d\d\d/g
  },
  {
    "name": "Vatican City (Città del Vaticano)",
    "code": "+39",
    "flag": "va",
    "preferred": false,
    "phoneMask": ["+39 ######", ""],
    "lastPreferred": false,
    'regulars': /\+39 \d\d\d\d\d\d/g
  },
  {
    "name": "Venezuela",
    "code": "+58",
    "flag": "ve",
    "preferred": false,
    "phoneMask": ["+58 ### ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+58 \d\d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Vietnam (Việt Nam)",
    "code": "+84",
    "flag": "vn",
    "preferred": false,
    "phoneMask": ["+84 #######", ""],
    "lastPreferred": false,
    'regulars': /\+84 \d\d\d\d\d\d\d/g
  },
  {
    "name": "Wallis and Futuna (Wallis-et-Futuna)",
    "code": "+681",
    "flag": "wf",
    "preferred": false,
    "phoneMask": ["+681 ## ####", ""],
    "lastPreferred": false,
    'regulars': /\+681 \d\d \d\d\d\d/g
  },
  {
    "name": "Western Sahara (‫الصحراء الغربية‬‎)",
    "code": "+212",
    "flag": "eh",
    "preferred": false,
    "phoneMask": ["+212 ## #### ###", ""],
    "lastPreferred": false,
    'regulars': /\+212 \d\d \d\d\d\d \d\d\d/g
  },
  {
    "name": "Yemen (‫اليمن‬‎)",
    "code": "+967",
    "flag": "ye",
    "preferred": false,
    "phoneMask": ["+967 # ### ###", ""],
    "lastPreferred": false,
    'regulars': /\+967 \d \d\d\d \d\d\d/g
  },
  {
    "name": "Zambia",
    "code": "+260",
    "flag": "zm",
    "preferred": false,
    "phoneMask": ["+260 ## ### ####", ""],
    "lastPreferred": false,
    'regulars': /\+260 \d\d \d\d\d \d\d\d\d/g
  },
  {
    "name": "Zimbabwe",
    "code": "+263",
    "flag": "zw",
    "preferred": false,
    "phoneMask": ["+263 # ######", ""],
    "lastPreferred": false,
    'regulars': /\+263 \d \d\d\d\d\d\d/g
  },
  {
    "name": "Åland Islands",
    "code": "+358",
    "flag": "ax",
    "preferred": false,
    "phoneMask": ["+358 ### ### ## ##", ""],
    "lastPreferred": false,
    'regulars': /\+358 \d\d\d \d\d\d \d\d \d\d/g
  }
]
