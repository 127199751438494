export const websitesManagementColumns = [
    {
        formatter: 'responsiveCollapse',
        width: 40,
        minWidth: 30,
        hozAlign: 'center',
        vertAlign: 'middle',
        resizable: false,
        headerSort: false
    },
    {
        title: 'Domain name',
        minWidth: 200,
        responsive: 0,
        field: 'code'
    },
    {
        title: 'Company name',
        minWidth: 200,
        responsive: 0,
        field: 'company_name',
        hozAlign: 'center'
    },
    {
        title: 'Owner name',
        minWidth: 200,
        responsive: 0,
        field: 'owner.full_name',
        hozAlign: 'center'
    },
    {
        title: 'Owner email',
        minWidth: 200,
        responsive: 0,
        field: 'owner.email',
        hozAlign: 'center'
    }
]
