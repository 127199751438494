import { computed, ref } from 'vue';
import { useStore } from '../../../scripts/store';
import { useRoute } from "vue-router";
import Velocity from 'velocity-animate';


const store = useStore();
const route = useRoute();
const currentUserRoles = computed(() => store.getters['auth/user'].roles.map(item => item.code));
const menu = ref([
    {
      icon: 'HomeIcon',
      image: 'onboarding',
      pageName: 'onboarding',
      title: 'Getting Started',
      roles: ['owner', 'office_manager', 'sales_team'],
    },
    {
        icon: 'HomeIcon',
        image: 'dashboard',
        pageName: 'dashboard',
        title: 'Dashboard',
        roles: ['owner', 'office_manager', 'sales_team'],
    },
    {
        icon: 'ShoppingBagIcon',
        image: 'products',
        pageName: 'products',
        title: 'Products',
        roles: ['owner', 'office_manager']
    },
    {
        icon: 'LayoutIcon',
        image: 'website',
        pageName: 'website',
        title: 'Website',
        roles: ['owner']
    },
    {
        icon: 'UserIcon',
        image: 'website-managers',
        pageName: 'managers-management',
        title: 'Website managers',
        roles: ['owner'],
        unwantedSubType: ['subTypeMeasureBasic', 'subTypeMeasurePro'],
    },
    {
        icon: 'UsersIcon',
        image: 'customers',
        pageName: 'customers',
        title: 'Customers',
        roles: ['owner', 'office_manager', 'sales_team'],
        unwantedSubType: ['subTypeMeasureBasic', 'subTypeMeasurePro'],
    },
    {
        icon: 'DollarSignIcon',
        image: 'pricing',
        title: 'Pricing',
        roles: ['owner', 'office_manager'],
        unwantedSubType: ['subTypeMeasureBasic', 'subTypeMeasurePro'],
        subMenu: [{
            icon: 'TagIcon',
            image: 'rules',
            pageName: 'SideMenu-pr-rules',
            roles: ['owner', 'office_manager'],
            title: 'Pricing Rules'
        },
            {
                icon: 'ListIcon',
                image: 'catalog',
                pageName: 'SideMenu-pr-catalog',
                roles: ['owner', 'office_manager'],
                title: 'Price Catalog'
            }
        ]
    },
    {
        icon: 'MailIcon',
        image: 'email-settings',
        pageName: 'EmailSettings',
        title: 'Email Settings',
        roles: ['owner', 'office_manager', 'sales_team'],
        unwantedSubType: ['subTypeMeasureBasic', 'subTypeMeasurePro'],
    },
    {
        icon: 'UsersIcon',
        image: 'users-websites',
        pageName: 'users-management',
        title: 'Users',
        roles: ['admin']
    },
    {
        icon: 'UsersIcon',
        image: 'influencers',
        pageName: 'influencers-management',
        title: 'Influencers',
        roles: ['admin']
    },
    {
        icon: 'LayoutIcon',
        image: 'website',
        pageName: 'websites-management',
        title: 'Websites',
        roles: ['admin']
    },
    {
        icon: 'KeyIcon',
        image: 'stripe-account',
        pageName: 'subscribe-management',
        link: 'https://dashboard.stripe.com/login',
        title: 'Stripe Account',
        roles: ['admin'],
    },
    {
        icon: 'ListIcon',
        image: 'website-settings',
        pageName: 'subscription-settings',
        title: 'Plan settings',
        roles: ['admin']
    },
    {
        icon: 'ListIcon',
        image: 'subscription-plans',
        pageName: 'subscription-plans',
        title: 'Subscription plans',
        roles: ['admin']
    },
]);


const checkAccessPointForCurrentRole = (item) => {
    if (!item.roles) {
        return true;
    }

    let isAllow = false;
    let length = item.roles.length;

    if (length) {
        for (let i = 0; i <= length; i++) {
            if (currentUserRoles.value.includes(item.roles[i])) {
                isAllow = true;
                break
            }
        }
    }

    return isAllow
}


const checkAccessPointForSubType = (item) => {
    if (item?.unwantedSubType) {
        for (const subType of item.unwantedSubType) {
            if (store.getters[`auth/${subType}`]) {
                return false;
            }
        }
    }

    return true;
}

const findActiveMenu = (subMenu, route) => {
    let match = false;

    subMenu.forEach(item => {
        if (item.pageName === route.name && !item.ignore) {
            match = true;
        } else if (!match && item.subMenu) {
            match = findActiveMenu(item.subMenu, route);
        }
    });

    return match;
}

const nestedMenu = (route) => {
    let newMenu = [];

    if (menu.value.length < 1) {
        return [];
    }

    menu.value.forEach((item, key) => {
        if (menu.value[key] && typeof item !== 'string') {
            item.active = (item.pageName === route.name || (item.subMenu && findActiveMenu(item.subMenu, route))) && !item.ignore;

            if (item?.subMenu) {
                item.activeDropdown = findActiveMenu(item.subMenu, route);
            }
        }

        if (checkAccessPointForSubType(item) && checkAccessPointForCurrentRole(item)) {
            newMenu.push(item);
        }
    });

    if (newMenu.length > 3 && (store.getters['auth/subTypeMeasureBasic'] || store.getters['auth/subTypeMeasurePro'])) {
        newMenu.splice(newMenu.length - 1, 1);
    }

    return newMenu;
}

const linkTo = (menu, router, event) => {
    if (menu.subMenu) {
        menu.activeDropdown = !menu.activeDropdown
    } else {
        event.preventDefault();
        if (menu.link) {
            window.open(menu.link, '_blank')
        } else {
            router.push({
                name: menu.pageName
            });
        }
    }
}

const enter = (el, done) => {
    Velocity(el, 'slideDown', { duration: 300 }, { complete: done })
}

const leave = (el, done) => {
    Velocity(el, 'slideUp', { duration: 300 }, { complete: done })
}

export { nestedMenu, linkTo, enter, leave }
