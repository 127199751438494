export const usersManagementFilteringFields = [
    {
        name: 'First name',
        value: 'first_name'
    },
    {
        name: 'Company name',
        value: 'company_name'
    },
    {
        name: 'Email',
        value: 'email'
    }
]
