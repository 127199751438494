import axios from 'axios'
import toast from './toast'
import errorHandler from './errorHandler'

export default (app) => {
    app.config.globalProperties.$tinyMceApiKey = process.env.MIX_TINYMCE_API_KEY
    app.config.globalProperties.$axios = axios
    app.use(toast)
    app.use(errorHandler)
}
