<template>
    <Layout title="Website Managers">
        <template #body>
            <div>
                <div
                    v-if="showAddButton"
                    class="col-span-12 flex flex-wrap sm:flex-nowrap items-center"
                >
                    <MainButton
                        class-map="mr-2"
                        title="Add User"
                        @click="addUser"
                    />
                </div>
                <div v-if="managers?.length > 0" class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 mt-4">
                    <MainBlock
                        v-for="(manager, index) in managers"
                        :key="index"
                    >
                        <template #body>
                            <div class="grid grid-cols-3 items-center">
                                <div class="col-span-2 lg:mx-2 lg:mr-auto text-left lg:text-left mt-3 lg:mt-0">
                                    <a href="javascript:void(0);" class="font-medium">
                                        {{ manager.full_name }}
                                    </a>
                                    <div class="text-gray-600 text-xs mt-0.5">
                                        {{ manager.email }}
                                    </div>
                                    <ul>
                                        <li
                                            v-for="(role, rIndex) in manager.roles"
                                            :key="rIndex"
                                            v-text="role.name"
                                        />
                                    </ul>
                                </div>
                                <div class="flex justify-end">
                                    <MainButton
                                        class-map="py-1 px-2"
                                        title="Profile"
                                        size-button="medium"
                                        @click="goToProfile(manager.id)"
                                    />
                                </div>
                            </div>
                        </template>
                    </MainBlock>
                </div>
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import axios from 'axios';
import Layout from "@layouts/Layout.vue";
import MainBlock from "@components/MainBlock.vue";
import MainButton from "@components/Button/MainButton.vue";


const router = useRouter();
const store = useStore();
const managers = ref([]);
const showAddButton = ref(false);

const getData = async () => {
    const { data: { data } } = await axios.get('/api/v1/user/get-managers');

    managers.value = data;

    showAddButton.value = store.getters['auth/subTypeBasic'] ? managers.value?.length < store.getters['auth/getAppSettings'].users : true;
}

const goToProfile = (id) => {
    router.push({ name: 'manager-details', params: { id: id } })
}

const addUser = () => {
    router.push({ name: 'manager-details', params: { id: 0 } })
}


onMounted(() => {
    getData();
});
</script>

<style scoped>

</style>
