<template>
  <div>
    <div
      :id="'update-' + currentItem.id"
      class="modal attr"
      style="z-index: 9998 !important;"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-4">
            <i class="gg-close mr-0 ml-auto cursor-pointer" data-dismiss="modal"></i>
            <form @submit="saveAttribute">
              <div class="form-group product-modal-image-wrapper">
                <ImageUploader
                  :url="itemImageUrl"
                  :remove-file="removeFile"
                  @fileUploaded="fileUploaded"
                />
              </div>
              <div class="form-group mt-5">
                <FormInput
                  v-model="validate.value.$model"
                  :errors="validate.value.$errors"
                  type="text"
                  label="Name:"
                  placeholder="Enter name"
                />
              </div>

              <ChooseMetricType
                v-if="type == 1"
                v-model="validate.metric_id.$model"
                :errors="validate.metric_id.$errors"
              />

              <div class="form-group mt-5">
                <FormTextarea
                  v-model="validate.description.$model"
                  :errors="validate.description.$errors"
                  type="text"
                  label="Description:"
                  placeholder="Enter description"
                />
              </div>
              <div v-if="itemLayerInclude.includes(type)" class="form-group mt-5 flex items-center gap-2">
                <InputColor
                  v-model="validate.color.$model"
                  :errors="validate.color.$errors"
                />
                <div>
                  Service Color <TipColor />
                </div>
              </div>
              <div v-if="itemTypeInclude.includes(type)" class="form-group mt-5">
                <!-- ***** PRICE BLOCK ***** -->
                <div class="price-block">
                  <h3 class="underline font-semibold text-lg">Price:</h3>
                  <div class="flex items-center gap-4">
                    <div>
                      <div>
                        <input type="checkbox"
                               :checked="priceType == 'simple_price'"
                               @click="onPriceTypeSelect('simple_price')"
                               class="form-checkbox hover:cursor-pointer">
                        {{ labelPrice }}
                        <TipAttribute :type-attribute="type" :label="labelPrice" />
                      </div>
                    </div>
                    <div v-if="itemTypeSqft.includes(type)">
                      <MainButton
                        title="How to calculate"
                        size-button="small"
                        @click="calculate.show=!calculate.show"
                      />
                    </div>
                  </div>
                  <div class="mt-2" v-if="!isTiersActive">
                    <FormInput
                      v-model.lazy="validate.price.$model"
                      :errors="validate.price.$errors"
                      :step="0.0001"
                      type="number"
                      placeholder="0.00"
                    >
                      <template #prepend>
                        <div
                          v-text="$store?.getters['website/website']?.currency?.sign ?? '$'"
                          class="input-group-text"
                        />
                      </template>
                    </FormInput>
                  </div>
                  <div v-if="calculate.show && !isTiersActive" class="mt-4">
                    <Select
                      v-model="calculate.type"
                      :options="[{name: 'Plants formula', value: 'PS'}, {name: 'Mulch/Gravel Formula', value: 'MGF'}]"
                      label="Formula"
                      placeholder="Select Formula"
                      class-map="w-full min-w-[10rem]"
                    />
                    <template v-if="calculate.type">
                      <div class="grid grid-cols-2 gap-4">
                        <div class="mt-2">
                          <FormInput
                            v-model="calculate.cost"
                            :step="0.01"
                            :label="calculate.type === 'PS' ? 'Plan Cost' : 'Cost/yard'"
                            type="number"
                          />
                        </div>
                        <div class="mt-2">
                          <FormInput
                            v-model="calculate.square"
                            :step="0.01"
                            :label="calculate.type === 'PS' ? 'Space between plants (ft)' : 'Mulch/Gravel Depth (Inches)'"
                            type="number"
                          />
                        </div>
                      </div>
                      <div class="mt-2">
                        <template v-if="calculate.cost > 0 && calculate.square > 0">
                                                <span
                                                  @click="copy(calculate.result)"
                                                  class="item-copy"
                                                >
                                                    {{ labelPrice }} = ${{ calculate.result }}
                                                </span>
                        </template>
                      </div>
                    </template>
                  </div>
                  <div class="flex items-center gap-4 mt-4" v-if="!isTiersActive">
                    <div>
                      <label>
                        Minimum
                      </label>
                    </div>
                  </div>
                  <div class="mt-2" v-if="!isTiersActive">
                    <FormInput
                      v-model.lazy="validate.min_price.$model"
                      :errors="validate.min_price.$errors"
                      :step="0.01"
                      :min="0.01"
                      type="number"
                      placeholder="0.00"
                    >
                      <template #prepend>
                        <div
                          v-text="$store?.getters['website/website']?.currency?.sign ?? '$'"
                          class="input-group-text"
                        />
                      </template>
                    </FormInput>
                  </div>
                </div>
                <!-- ********** END PRICE BLOCK ************ -->
                <div v-if="props.type != 5" class="space-y-4 mt-4 mb-8">
                  <div class="block" v-for="commonType in itemCommonTypes.filter(el => el.title === 'Tiers')" :key="'common-type-' + commonType.id + commonType.is_active">
                    <input type="checkbox" @click="onPriceTypeSelect('lot_size_price')" :checked="priceType === 'lot_size_price'" class="form-checkbox hover:cursor-pointer">
                    {{ commonType.title }}
                    <!-- ********** tiers block -->
                    <TiersBlock
                      v-if="priceType === 'lot_size_price'"
                      :label="labelPrice.replace('Price ', '')"
                      :optionTiers="optionTiers"
                      :price-type="commonType.config.price_type"
                      @updateTiers="updateTiers"
                      @updateTiersPriceType="updateTiersPriceType"
                    />
                    <!-- ************* -->
                  </div>
                </div>

                <div v-if="props.type != 5" class="space-y-4 mt-4 mb-4">
                  <label class="underline font-semibold text-lg">Size Options:</label>
                  <div class="block" v-for="commonType in itemCommonTypes.filter(el => el.title !== 'Tiers')" :key="'common-type-' + commonType.id + commonType.is_active">
                    <!--   divider for tiers and rest  -->
                    <div class="title medium divider" v-if="commonType.title == 'Lot Size'">
                      <span>or</span>
                      <div class="line"></div>
                    </div>
                    <!--   end divider for tiers and rest  -->

                    <input type="checkbox" @click="onPresetsClick(commonType, $event)" :checked="commonType.is_active" class="form-checkbox hover:cursor-pointer">
                    {{ commonType.title }}
                    <!-- ********** presetsize block -->
                    <PresetBlock
                      v-if="commonType.id == 3 && commonType.is_active"
                      :presetSizes="presetSizes || []"
                      :label="labelPrice.replace('Price ', '')"
                      @updatePresetSizes="updatePresetSizes"
                    />
                    <!-- ************* -->
                  </div>
                </div>

              </div>
              <div class="flex justify-center my-2">
                <MainButton
                  title="Update"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch, ref, reactive, computed, inject, onMounted } from 'vue'
import { useStore } from 'vuex';
import { helpers, maxLength, minLength, minValue, numeric, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { copy } from "@scripts/plugins/functions";
import cash from 'cash-dom/dist/cash';
import ImageUploader from '@components/UI/ImageUploader';
import TipAttribute from "@components/Tooltips/TipAttribute.vue";
import TipColor from "@components/Tooltips/TipColor.vue";
import InputColor from "@components/UI/InputColor.vue";
import FormInput from "@components/UI/Input.vue";
import MainButton from "@components/Button/MainButton.vue";
import Select from "@components/UI/Select.vue";
import ChooseMetricType from "@components/Page/Attribute/ChooseMetricType.vue";
import FormTextarea from "@components/UI/FormTextarea.vue";
import TiersBlock from './TiersBlock.vue'
import PresetBlock from './PresetBlock.vue'
const toast = inject('toast');

const props = defineProps({
  currentItem: {
    type: Object,
    default: () => {
      return {
        value: '',
        description: '',
        price: null,
        min_price: null,
        color: null,
        metric_id: ''
      }
    }
  },
  type: {
    type: Number,
    default: null
  },
  labelPrice: {
    type: String,
    default: 'SQFT'
  }
});
const emit = defineEmits([ 'saveAttribute' ]);
const store = useStore();
const formData = new FormData();
const removeFile = ref(false);
const itemImageUrl = ref('');
const itemTypeInclude = [2, 3, 5, '2', '3', '5'];
const itemLayerInclude = [1, '1'];
const itemTypeSqft = [ 2, 3, '2', '3' ];
const priceType = ref('simple_price');

const presetSizes = ref([])
const optionTiers = ref([])
const tierPriceType = ref('flat fee')

const calculate = ref({
  show: false,
  cost: 0,
  square: 0,
  type: null,
  result: 0
});
const requestData = reactive({
  value: '',
  description: '',
  price: null,
  min_price: null,
  color: null,
  metric_id: '',
});
const generalCommonTypes = store.getters['general/getAttributeCommonTypes']
const itemCommonTypes = ref(generalCommonTypes.map(type => ({
  ...type,
  is_active: true,
  config: type.id == 3 ? { sizes: [] } : (type.id == 4 ? { tiers: [] } : {}),
  attribute_common_type_id: type.id
})))

const isTiersActive = computed(() => priceType.value === 'lot_size_price')
const rules = {
  value: { required, maxLength: maxLength(40) },
  description: { maxLength: maxLength(80) },
  price: !itemLayerInclude.includes(props.type) && !isTiersActive ? {
    required, numeric, minValue: minValue(0.0001),
    customMaxLength: helpers.withMessage('The maximum length allowed is 9', value => {
      let priceWithoutDecimal = value;
      let priceSlice = value.slice(-5);

      if (priceSlice.includes(",") || priceSlice.includes(".")) {
        priceWithoutDecimal = value.slice(0, -5);
      }

      return priceWithoutDecimal.length <= 9;
    }),
  } : {},
  min_price: { minValue: minValue(0.01) },
  color: itemLayerInclude.includes(props.type) ? { required, minLength: minLength(4) } : {},
  metric_id: props.type == 1 ? { required } : {},
}
const validate = useVuelidate(rules, requestData);

const checkSiteOptionSelectedValidation = () => {
  if (itemCommonTypes.value[0].is_active || itemCommonTypes.value[1].is_active || itemCommonTypes.value[2].is_active || itemCommonTypes.value[4].is_active) {
    return true;
  }

  return false;
}

const fileUploaded = (file) => {
  formData.append('file', file);
  removeFile.value = false;
}

const onPriceTypeSelect = (price_type) => {
  priceType.value = price_type
}

const clearCurrentPreSetSizes = () => {
  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.attribute_common_type_id == 3) {
      itemCommonTypes.value[index].config.sizes = []
    }
  }
}

const clearCurrentTiers = () => {
  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.attribute_common_type_id == 4) {
      itemCommonTypes.value[index].config.tiers = []
    }
  }
}

const calculateFormula = () => {
  if (calculate.value.type === 'PS') {
    calculate.value.result = calculate.value.cost / calculate.value.square;
  } else {
    calculate.value.result = calculate.value.cost / (324 / calculate.value.square);
  }

  calculate.value.result = Number(calculate.value.result.toFixed(2));
}

const updatePresetSizes = (sizes) => {
  presetSizes.value = sizes
  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == 3) {
      itemCommonTypes.value[index] = {
        ...itemCommonTypes.value[index],
        config: {
          ...itemCommonTypes.value[index].config,
          sizes: presetSizes.value
        }
      }
    }
  }
}

const updateTiers = (tiers) => {
  optionTiers.value = tiers

  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == 4) {
      itemCommonTypes.value[index] = {
        ...itemCommonTypes.value[index],
        config: {
          ...itemCommonTypes.value[index].config,
          tiers: optionTiers.value
        }
      }
    }
  }
}

const updateTiersPriceType = (price_type) => {
  tierPriceType.value = price_type

  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == 4) {
      itemCommonTypes.value[index] = {
        ...itemCommonTypes.value[index],
        config: {
          ...itemCommonTypes.value[index].config,
          price_type: price_type
        }
      }
    }
  }
}

const onPresetsClick = (common, e) => {
  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    if (itemCommonType.id == common.id) {
      itemCommonTypes.value[index].is_active = !itemCommonTypes.value[index].is_active
    }
  }
}

const saveAttribute = (e) => {
  e.preventDefault();
  validate.value.$touch();

  if (!checkSiteOptionSelectedValidation()) {
    toast({
      text: 'Please select at least one size option',
      type: 'danger'
    });
    return;
  }

  if (!validate.value.$invalid) {
    // some boolshit with itemCommonTypes reactivity
    itemCommonTypes.value.forEach((type, index) => {
      formData.append('attribute_common_types[]', JSON.stringify(type))
    })

    formData.append('value', requestData.value)
    formData.append('type', props.type)
    formData.append('price', requestData.price)
    formData.append('price_type', priceType.value ?? 'simple_price')

    if (requestData.min_price) {
      formData.append('min_price', requestData.min_price)
    }

    formData.append('description', requestData.description)
    formData.append('color', requestData.color)
    formData.append('metric_id', requestData.metric_id)

    if (!formData.has('file') && props.currentItem.media_id !== null) {
      formData.append('media_id', requestData.description)
    }
    cash('#update-' + props.currentItem.id).modal('hide');
    removeFile.value = true;
    emit('saveAttribute', formData);
    formData.delete('value');
    formData.delete('type');
    formData.delete('price');
    formData.delete('min_price');
    formData.delete('description');
    formData.delete('color');
    formData.delete('metric_id');
    formData.delete('price_type');
    itemCommonTypes.value.forEach((type, index) => {
      formData.delete('attribute_common_types[]', JSON.stringify(type))
    })
    validate.value.$reset();

    setTimeout(() => {
      clearCurrentPreSetSizes();
      clearCurrentTiers();
    }, 300)

    toast({
      text: 'Attribute updated successfully',
      type: 'success'
    });
  }
}

watch(
  () => calculate.value,
  (calculate) => {
    if (calculate.cost > 0 && calculate.square > 0) {
      calculateFormula();
    }
  },
  {
    deep: true
  }
);

// pre-sizes, map or measurements if true -> lot size false
watch(() => itemCommonTypes.value[0]?.is_active, (value) => {
  if (itemCommonTypes.value[0]?.is_active) {
    itemCommonTypes.value[4].is_active = false
  }
});
watch(() => itemCommonTypes.value[1]?.is_active, (value) => {
  if (itemCommonTypes.value[1]?.is_active) {
    itemCommonTypes.value[4].is_active = false
  }
});
watch(() => itemCommonTypes.value[2]?.is_active, (value) => {
  if (itemCommonTypes.value[2]?.is_active) {
    itemCommonTypes.value[4].is_active = false
  }
});

// lot size if true -> pre-sizes, map and measurements false
watch(() => itemCommonTypes.value[4]?.is_active, (value) => {
  if (itemCommonTypes.value[4]?.is_active) {
    itemCommonTypes.value[0].is_active = false
    itemCommonTypes.value[1].is_active = false
    itemCommonTypes.value[2].is_active = false
  }
});

watch(() => props.currentItem, () => {
  requestData.value = props.currentItem.value;
  requestData.price = props.currentItem.price;
  requestData.min_price = props.currentItem.min_price;
  requestData.description = props.currentItem.description;
  requestData.color = props.currentItem.color;
  requestData.metric_id = props.currentItem.attribute_metric;
  if (props.currentItem?.media) {
    if (props.currentItem.media?.thumbnail_url) {
      itemImageUrl.value = props.currentItem.media.thumbnail_url;
    }
    if (props.currentItem.media?.url) {
      itemImageUrl.value = props.currentItem.media.url;
    }
  }
  priceType.value = props.currentItem.price_type ?? 'simple_price';

  if (props.currentItem.commons?.length) {
    itemCommonTypes.value = props.currentItem.commons.map(common => {
      return {
        id: common.common_type.id,
        title: common.common_type.title,
        is_active: common.is_active,
        config: common.config,
        attribute_common_type_id: common.common_type.id
      }
    })

    // Extend existing common types which have presets
    if (itemCommonTypes.value && !itemCommonTypes.value.find(el => el.id === 3)) {
      const allCommonTypes = store.getters['general/getAttributeCommonTypes']
      const preSizesCommonType = allCommonTypes.find(el => el.id === 3)

      itemCommonTypes.value.push({
        id: preSizesCommonType.id,
        title: preSizesCommonType.title,
        is_active: false,
        config: {
          sizes: []
        },
        attribute_common_type_id: preSizesCommonType.id
      })
    }

    // Extend existing common types which have tiers
    if (itemCommonTypes.value && !itemCommonTypes.value.find(el => el.title === 'Tiers')) {
      const allCommonTypes = store.getters['general/getAttributeCommonTypes']
      const tiersCommonType = allCommonTypes.find(el => el.title === 'Tiers')

      itemCommonTypes.value.push({
        id: tiersCommonType.id,
        title: tiersCommonType.title,
        is_active: false,
        config: {
          tiers: [],
          price_type: 'flat fee'
        },
        attribute_common_type_id: tiersCommonType.id
      })
    }

    // Extend existing common types with lot-sizes
    if (itemCommonTypes.value && !itemCommonTypes.value.find(el => el.title === 'Lot Size')) {
      const allCommonTypes = store.getters['general/getAttributeCommonTypes']
      const lotSizeCommonType = allCommonTypes.find(el => el.title === 'Lot Size')

      itemCommonTypes.value.push({
        id: lotSizeCommonType.id,
        title: lotSizeCommonType.title,
        is_active: false,
        config: {},
        attribute_common_type_id: lotSizeCommonType.id
      })
    }
  }

  // Check if lot size should be disabled
  let lotSizeIsDisabled = false
  for (const [index, itemCommonType] of Object.entries(itemCommonTypes.value)) {
    //disable lot size if at least one from pre-sizes, map or measurements is active
    if ([1,2,3].includes(itemCommonType.id) && itemCommonType.is_active) {
      lotSizeIsDisabled = true
    }

    if (itemCommonType.id == 3) {
      presetSizes.value = itemCommonTypes.value[index].config.sizes
    }

    if (itemCommonType.id == 4) {
      optionTiers.value = itemCommonTypes.value[index].config.tiers
      tierPriceType.value = itemCommonTypes.value[index].config.price_type
    }
  }

  if (lotSizeIsDisabled && itemCommonTypes.value.find(el => el.title === 'Lot Size')) {
    itemCommonTypes.value.find(el => el.title === 'Lot Size').is_active = false
  }

  validate.value.$reset();

  calculate.value.show = false;
  calculate.value.result = 0;
  calculate.value.cost = 0;
  calculate.value.square = 0;
  calculate.value.type = null;

  removeFile.value = true;
});

</script>
<style lang="scss" scoped>
:deep(.height-img-wrapper) {
  height: 10rem;
  width: 10rem;
}

.attr {
  z-index: 9998 !important;
}

.divider {
  position: relative;
  height: 20px;
  width: 100%;

  span {
    text-transform: uppercase;
    position: absolute;
    left: calc(50% - 17px);
    top: 0;
    z-index: 3;
    padding: 0 8px;
    background-color: white;
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    font-weight: 600;
  }

  .line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(211, 212, 213, 1);
  }
}
</style>
