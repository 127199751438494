import tUIImageEditor from 'grapesjs-tui-image-editor'
// import basicBlocks from 'grapesjs-blocks-basic'
const token = document.querySelector('meta[name="csrf-token"]').content
export default () => (
    {
        container: '#contentManager',
        fromElement: true,
        plugins: [tUIImageEditor],
        height: '700px',
        weight: '100%',
        colorPicker: { appendTo: 'parent', offset: { top: 26, left: -166 } },
        storageManager: {
            type: 'remote',
            contentTypeJson:true,
            storeComponents: true,
            storeStyles: true,
            storeHtml: true,
            storeCss: true,
            headers: {
                'X-CSRF-TOKEN': token,
                'Content-Type': 'application/json'
            }
        },
        blockManager: {
            prependTo: '#contentBlocks',
            blocks: [
                {
                    id: 'empty-section',
                    label: 'Empty section',
                    content: `<section class="section flex min-h-min justify-center items-center my-4"></section>`
                },
                {
                    id: 'section',
                    label: 'Title + Text',
                    content: `<section class="landing-section my-4">
                                  <h2 class="text-center text-2xl">Title</h2>
                                  <h3 class="text-center text-base">Sub Title</h3>
                                </section>`
                },
                {
                    id: 'services',
                    label: 'Services',
                    content: `<section class="landing-section my-4">
                                  <h2 class="text-center text-2xl">Title</h2>
                                  <div class="flex items-stretch gap-4 w-full rounded-lg bg-stripes-cyan text-center">
                                  <div class="py-4 flex-1 flex items-center justify-center shadow-lg rounded-lg bg-cyan-500">01</div>
                                  <div class="py-12 flex-1 flex items-center justify-center shadow-lg rounded-lg bg-cyan-500">02</div>
                                  <div class="py-8 flex-1 flex items-center justify-center shadow-lg rounded-lg bg-cyan-500">03</div>
                                </div>
                                </section>`
                },
                {
                    id: 'text',
                    label: 'Text',
                    content: '<p class="dm-sans">Text</p>'
                },
                {
                    id: 'link',
                    label: 'Link',
                    category: 'Basic',
                    activate: true,
                    content: {
                        type: 'link',
                        content: 'Insert your link here',
                        style: { color: '#d983a6' }
                    }
                },
                {
                    id: 'image',
                    label: 'Image',
                    select: true,
                    content: { type: 'image' },
                    activate: true
                }
            ]
        }
    })
