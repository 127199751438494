<template>
    <GeneralLayout>
        <DarkModeSwitcher />
        <div>
            <div class="min-h-screen">
                <div class="flex justify-center text-center items-center flex-col my-4 mx-4">
                    <div class="pb-[2rem]">
                        <img :src="logoTransparent" alt="" class="w-[205px]">
                    </div>
                    <MainBlock class-map="w-full max-w-[550px]" title="Sign Up">
                        <template #body>
                            <form class="w-full" @submit.prevent="register">
                                <fieldset>
                                    <div class="mt-8">
                                        <FormInput
                                            v-model.trim="validate.first_name.$model"
                                            :errors="validate.first_name.$errors"
                                            placeholder="Enter Name"
                                            label="Name"
                                        />
                                        <div class="mt-4">
                                            <FormInput
                                                v-model.trim="validate.company_name.$model"
                                                :errors="validate.company_name.$errors"
                                                placeholder="Enter company name"
                                                label="Company"
                                            />
                                        </div>
                                        <div class="mt-4">
                                            <FormInput
                                                v-model.trim="validate.email.$model"
                                                :errors="validate.email.$errors"
                                                placeholder="example@company.com"
                                                label="Email"
                                                type="email"
                                            />
                                        </div>
                                        <div class="mt-4">
                                            <InputPhone
                                                v-model="validate.contact_number.$model"
                                                :errors="validate.contact_number.$errors"
                                                :class-map="['login__input']"
                                            />
                                        </div>
                                        <div class="mt-4">
                                            <FormInput
                                                v-model.trim="validate.password.$model"
                                                :errors="validate.password.$errors"
                                                placeholder=""
                                                label="Password"
                                                type="password"
                                            />
                                        </div>
                                    </div>
                                    <div class="flex items-center text-gray-700 dark:text-gray-600 mt-4 text-xs sm:text-sm">
                                        <input
                                            id="remember-me"
                                            v-model="validate.agree.$model"
                                            type="checkbox"
                                            class="form-check-input border mr-2"
                                        >
                                        <label
                                            class="cursor-pointer select-none"
                                            for="remember-me"
                                        >
                                            I agree to the
                                        </label>
                                        <a
                                            class="text-theme-32 text-decoration-underline ml-1"
                                            href="/privacy-policy"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </a>
                                    </div>
                                    <div class="flex justify-center gap-4 mt-6">
                                        <MainButton
                                            :type-button="processing ? 'disabled' : ''"
                                            title="Create Account"
                                            type="submit"
                                        />
                                    </div>
                                </fieldset>
                                <div class="mt-6 text-center">
                                    Already have an account?
                                    <router-link to="/login" class="text-theme-32 dark:text-theme-10 text-decoration-underline">
                                        Log In
                                    </router-link>
                                </div>
                            </form>
                        </template>
                    </MainBlock>
                </div>
            </div>
        </div>
    </GeneralLayout>
</template>

<script setup>
import { getCurrentInstance, inject, onMounted, reactive, ref, toRefs } from "vue";
import { email, minLength, required, not, helpers, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core';
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { telInputValid } from "@mixins/composition/phoneMask";
import DarkModeSwitcher from '@components/DarkModeSwitcher.vue';
import FormInput from "@components/UI/Input.vue";
import InputPhone from "@components/UI/InputPhone.vue";
import MainButton from "@components/Button/MainButton.vue";
import MainBlock from "@components/MainBlock.vue";
import logoTransparent from '@images/svg/logo.svg';
import GeneralLayout from "../../Layouts/GeneralLayout.vue";

const store = useStore();
const router = useRouter();
const processing = ref(false);
const toast = inject('toast');
const { proxy } = getCurrentInstance();
const formData = reactive({
    first_name: '',
    company_name: '',
    email: '',
    contact_number: '',
    password: '',
    agree: false
});

const formData_v = {
    first_name: { required, minLength: minLength(2), maxLength: maxLength(50) },
    company_name: { required, minLength: minLength(2), maxLength: maxLength(200) },
    email: { required, email },
    contact_number: { required, phone: helpers.withMessage('Contact Number is invalid', telInputValid) },
    agree: { isValid: not(model => !model) },
    password: { required, minLength: minLength(8) },
}
const validate = useVuelidate(formData_v, toRefs(formData));

const register = async () => {
    validate.value.$touch();

    if (validate.value.agree.$error) {
        toast({
            type: 'warning',
            text: 'You must be agree with Privacy Policy'
        });
    } else if (!validate.value.$invalid) {
        processing.value = true;
        try {
            await store.dispatch('auth/register', formData);
            store.commit('messages/pushSuccessMessage', 'Registration success!');

            const { data: { data } } = await store.dispatch('auth/user');
            store.commit('auth/setUser', data);
            store.commit('auth/setLoggedIn', true);

            document.location.replace('/admin');
        } catch (e) {
            proxy.$errorHandler(e);
            processing.value = false;
        }
    }
}

onMounted(() => {
    cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login');
});
</script>
