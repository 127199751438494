export const columns = [
    {
        title: 'Date',
        responsive: 0,
        field: 'created_at',
        hozAlign: 'center',
        titleFormatter: function (cell, formatterParams, onRendered) {
            return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
        }
    },
    {
        title: 'Message',
        field: 'body',
        hozAlign: 'center',
        titleFormatter: function (cell, formatterParams, onRendered) {
            return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
        },
        headerSort: false
    },
    {
        title: 'Seen',
        field: 'seen',
        hozAlign: 'center',
        titleFormatter: function (cell, formatterParams, onRendered) {
            return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
        },
        formatter: function (cell, formatterParams, onRendered) {
            const value = cell.getValue();
            if (value === 'Yes')
                return "<div style='text-align:center;font-size: 20px'>&#10004;</div>";
            else
                return "<div style='text-align:center;font-size: 20px'>&#10008;</div>";
        },
        headerSort: false
    }
]
