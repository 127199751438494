export default {
    setOnboardingSteps(state, data) {
        state.onboardingSteps = data;
        
        for (let i = 0; i < data.length; i++) {
            if (data[i].pivot.is_completed || data[i].pivot.is_skipped) {
                continue
            } else {
                state.currentStepId = data[i].id
                break
            }
        }
    },
    
    setCurrentStepId(state, id) {
        state.currentStepId = id;
    },
    
    setStepCompleted(state, id) {
        state.onboardingSteps.forEach((step) => {
            if (step.id === id) {
                step.pivot.is_completed = true
            }
        })
    },
    
    setStepSkipped(state, id) {
        state.onboardingSteps.forEach((step) => {
            if (step.id === id) {
                step.pivot.is_skipped = true
            }
        })
    },
    
    setAttributes(state, data) {
        state.attributes = data
    },
    
    setDeleteElementUid(state, uid) {
        state.deleteElementUid = uid
    }
}
