<template>
    <Layout title="Manager Details">
        <template #body>
            <ButtonBack title="Websites list" :to="{name: 'websites-management'}" />
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-6 xxl:col-span-6">
                    <div>
                        <div class="form-group">
                            <label>Domain Name: </label>
                            <input
                                v-model="websiteData.code"
                                class="form-control"
                                type="text"
                            >
                        </div>
                        <div class="form-group">
                            <label>Company Name: </label>
                            <input
                                v-model="websiteData.company_name"
                                class="form-control"
                                type="text"
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-if="owner"
                    class="col-span-6 xxl:col-span-6"
                >
                    <h3 class="text-lg mb-4">
                        Owner site
                    </h3>
                    <div class="flex items-center mb-3">
                        <label class="mr-3">Name:</label>
                        <a
                            class="alert alert-warning show cursor-pointer py-1"
                            role="alert"
                            @click.prevent="router.push('/admin/users-management/' + owner.id + '/' + websiteId)"
                        >
                            {{ owner.full_name }}
                        </a>
                    </div>
                    <div class="flex mb-3">
                        <label class="mr-3">Email:</label>
                        <span>{{ owner.email }}</span>
                    </div>
                    <div class="flex">
                        <label class="mr-3">Contact number:</label>
                        <span>{{ owner.contact_number }}</span>
                    </div>
                </div>
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import ButtonBack from "@components/Button/ButtonBack.vue";
import Layout from "@layouts/Layout.vue";


const websiteData = ref({});
const router = useRouter();
const owner = ref({});
// eslint-disable-next-line no-underscore-dangle
const websiteId = ref(router.currentRoute._value.params.id);


const getData = () => {
    axios.get(`/api/super-admin/admin-websites/` + websiteId.value).then((response) => {
        const { data } = response
        websiteData.value = data ?? {}
        owner.value = data.owner ?? null
    }).catch((e) => {
        router.push({ name: 'websites-management' })
    });
}


onMounted(() => {
    getData();
});
</script>

<style lang="css" scoped>
    .form-group{
        margin: .75rem 0;
    }
</style>
