<template>
    <Layout title="Pricing rules">
        <template #body>
            <Accordion title="How it works">
                <template #body>
                    <div class="my-3">
                        <div class="my-2">
                            <span class="font-medium">Basics</span> <span class="font-medium">your customer will be shown:</span>
                            <div class="ml-4">
                                <div class="flex items-center gap-2">
                                    <div class="circle" />
                                    <div>The base cost of the Layer</div>
                                </div>
                                <div class="flex items-center gap-2">
                                    <div class="circle" />
                                    <div>The cost of Layer Upgrades</div>
                                </div>
                                <div class="flex items-center gap-2">
                                    <div class="circle" />
                                    <div>The cost of any Single Unit Item</div>
                                </div>
                                <div class="flex items-center gap-2">
                                    <div class="circle" />
                                    <div>The combined Total Cost including pricing rules and price range</div>
                                </div>
                            </div>
                        </div>
                        <p class="">
                            <span class="font-medium">Quote Price Range:</span>
                            Here you can determine how big of a cost range you give your customer. For example, instead
                            of
                            showing the price being $1000 the quote will show $950-$1200 (or whatever % you want to
                            use).
                        </p>
                        <div class="my-2">
                            <span class="font-medium">Rules:</span> The tool allows you to increase or decrease the
                            price based on how long the project is. For example, you can add a short charge of 10% to
                            all projects
                            shorter than 50 feet (or meters). You can have one “Less than” rule, one “Between”, and one
                            “Greater
                            than” rule.
                        </div>
                        <div class="my-2">
                            <span class="font-medium mr-2">Formulas</span> <span class="font-medium">that are used for the calculation of user quotes:</span>
                            <div class="ml-4">
                                <div class="flex items-center gap-2">
                                    <div class="circle" />
                                    <div>Layer "1" $/{{ metricValue }} * Layer "1" {{ metricValue }} range = The base
                                        cost of the Layer
                                    </div>
                                </div>
                                <div class="flex items-center gap-2">
                                    <div class="circle" />
                                    <div>Layer "1" Upgrades $/{{ metricValue }} * Layer "1" {{ metricValue }} range =
                                        The cost of Layer Upgrades
                                    </div>
                                </div>
                                <div class="flex items-center gap-2">
                                    <div class="circle" />
                                    <div>Single Unit Item fix $ = The cost of any Single Unit Item</div>
                                </div>
                                <div class="flex items-center gap-2">
                                    <div class="circle" />
                                    <div>((Layer "1" $/{{ metricValue }} + Layer "1" Upgrades $/{{ metricValue }}) *
                                        Layer "1" {{ metricValue }} range + Single Unit Item(s) fix $ + Pricing rules) *
                                        min-max% = Total Cost
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Accordion>

            <Title title="Quote price range" type-title="medium" class="mb-2 text-lg">
                <template #tip>
                    <TipRange />
                </template>
            </Title>

            <div class="rounded-md w-80">
                <div class="form-group mb-4">
                    <FormInput
                        v-model="validate.low.$model"
                        :errors="validate.low.$errors"
                        label="Low range(%):"
                        type="number"
                    >
                        <template #prepend>
                            <div
                                class="input-group-text"
                                v-text="'%'"
                            />
                        </template>
                    </FormInput>
                </div>
                <div class="form-group">
                    <FormInput
                        v-model="validate.high.$model"
                        :errors="validate.high.$errors"
                        label="High range(%):"
                        type="number"
                    >
                        <template #prepend>
                            <div
                                class="input-group-text"
                                v-text="'%'"
                            />
                        </template>
                    </FormInput>
                </div>
            </div>
            <Line />
            <div class="row rounded-md">
                <div v-if="rules.length > 0" id="inline-form">
                    <MainBlock>
                        <template #body>
                            <div v-for="(rule, index) in rules">
                                <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-3">
                                    <div class="flex gap-3 items-center">
                                        <div class="mt-[1.5rem] inline-block">If service</div>
                                        <div class="flex-grow">
                                            <CustomSelect
                                                v-model="rule.operator"
                                                :errors="errorsRules[`rules.${index}.operator`]"
                                                :options="operatorsOptions"
                                                label="Rule"
                                                class-map="w-full"
                                                placeholder="Select Rule"
                                                @update:modelValue="(value) => handleSelectChange(value, index)"
                                            >
                                                <template #label>
                                                    <div class="flex gap-3">
                                                        <div>Rule</div>
                                                        <ChooseMetricType
                                                            v-model="rule.type"
                                                            :show-label="false"
                                                            :show-text="false"
                                                            wrap-class-map="form-group"
                                                        />
                                                    </div>
                                                </template>
                                            </CustomSelect>
                                        </div>
                                    </div>
                                    <div>
                                        <FormInput
                                            v-if="rule.operator != 5"
                                            v-model="rule.values"
                                            :class-map="[{'!border-theme-40': errorsRules[`rules.${index}.values`]}]"
                                            type="number"
                                            label="Length"
                                            placeholder="Enter length"
                                        />
                                        <div v-else class="flex gap-4">
                                            <FormInput
                                                v-model="rule.values[0]"
                                                :class-map="[{'!border-theme-40': errorsRules[`rules.${index}.values`]}]"
                                                label="Min Length"
                                                placeholder="Enter min length"
                                                type="number"
                                                @input="betweenRuleChanges($event.target.value, 'low', index)"
                                            />
                                            <FormInput
                                                v-model="rule.values[1]"
                                                :class-map="[{'!border-theme-40': errorsRules[`rules.${index}.values`]}]"
                                                label="Max Length"
                                                placeholder="Enter max length"
                                                type="number"
                                                @input="betweenRuleChanges($event.target.value, 'high', index)"
                                            />
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-between">
                                        <div class="flex gap-3 items-center">
                                            <div class="mt-[1.5rem] inline-block">then</div>
                                            <div class="flex-grow mt-1">
                                                <label>
                                                    Add to Quote Total
                                                    <TipQuoteTotal />
                                                </label>
                                                <div
                                                    class="flex"
                                                    :style="{'border': errorsRules[`rules.${index}.sum_add_minus`] && errorsRules[`rules.${index}.sum_add_minus`].length > 0 ? '1px solid #C9000E' : '1px solid #D3D3D3','border-radius': errorsRules[`rules.${index}.sum_add_minus`] ? '0.375rem' : '0'}"
                                                >
                                                    <FormInput
                                                        v-model="rule.sum_add_minus"
                                                        placeholder="Enter amount"
                                                        type="number"
                                                        class-map="!border-0 !rounded-r-none"
                                                    />
                                                    <div class="w-[30%]">
                                                        <CustomSelect
                                                            v-model="rule.unit"
                                                            :options="unitsOptions"
                                                            class-map="replace-border !border-0 !rounded-r-none"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <MainButton
                                                class-map="danger !border-0 !bg-transparent mt-[1.5rem]"
                                                size-button="small"
                                                @click="removeRule(rule, index)"
                                            >
                                                <template #body>
                                                    <div class="text-center" style="height: 20px; width: 20px;">
                                                        <img :src="imageDeleteItem" width="20" height="20" alt="">
                                                    </div>
                                                </template>
                                            </MainButton>
                                        </div>
                                    </div>
                                </div>

                                <Line v-if="index+1 !== rules.length" />
                            </div>
                        </template>
                    </MainBlock>
                </div>
                <div class="flex my-4 gap-4 justify-center">
                    <MainButton
                        v-if="rules.length !== 6"
                        title="Add rule"
                        @click="addRule"
                    />
                    <MainButton
                        :type-button="processing ? 'disabled' : ''"
                        title="Save"
                        @click="saveRules"
                    />
                </div>
            </div>
        </template>
    </Layout>
</template>

<script setup>
import { computed, getCurrentInstance, inject, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import { useStore } from 'vuex';
import axios from 'axios';
import TipRange from "@components/Tooltips/TipRange.vue";
import TipQuoteTotal from "@components/Tooltips/TipQuoteTotal.vue";
import Layout from "@layouts/Layout.vue";
import Accordion from "@components/UI/Accordion.vue";
import Line from "@components/Line.vue";
import Title from "@components/Title.vue";
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import MainBlock from "@components/MainBlock.vue";
import imageDeleteItem from "@assets/images/svg/delete.svg";
import ChooseMetricType from "@components/Page/Attribute/ChooseMetricType.vue";
import CustomSelect from "@components/UI/CustomSelect.vue";


const store = useStore();
const { proxy } = getCurrentInstance();
const errorsRules = ref([]);
const toast = inject('toast');
const processing = ref(false);
const rules = ref([]);
const operatorsOptions = ref([]);
const unitsOptions = ref([]);
const betweenRanges = [];
const currentWebsite = computed(() => store.getters['website/website']);
const ranges = reactive({
    low: 0,
    high: 0
});
const rules_v = {
    low: { required, maxLength: maxLength(8) },
    high: { required, maxLength: maxLength(8) },
}
const validate = useVuelidate(rules_v, toRefs(ranges));
const metricValue = ref('(sqft/lf)');

const addRangesValues = (item) => {
    ranges.high = item?.high ?? 0;
    ranges.low = item?.low ?? 0;
}

const getData = async () => {
    const { data } = await axios.get('/api/pricing-rules', {
        params: {
            website_id: currentWebsite.value.id
        }
    });

    rules.value = data.rules;
    operatorsOptions.value = data.operatorsList;
    unitsOptions.value = data.units;
    addRangesValues(data.lowHighRanges);
}

const addRule = () => {
    rules.value.push({
        unit: 1,
        type: 1,
        values: '',
        operator: '',
        sum_add_minus: '',
    });
}

const saveRules = () => {
    validate.value.$touch();

    if (!validate.value.$invalid) {
        processing.value = true;
        let object = {
            range: {
                low: ranges.low,
                high: ranges.high
            },
            website_id: currentWebsite.value.id
        }

        if (rules.value.length > 0) {
            object.rules = rules.value;
        }

        axios.post('/api/pricing-rules', object).then(() => {
            toast({
                type: 'success',
                text: 'Rules saved!'
            });

            setTimeout(() => {
                processing.value = false;
            }, 800);

            errorsRules.value = [];

            getData();
        }).catch((e) => {
            proxy.$errorHandler(e);

            if (e.response) {
                errorsRules.value = e.response.data;
            }

            processing.value = false;
        })
    }
}

const removeRule = (rule, index) => {
    if (!rule.website_id) {
        rules.value.splice(index, 1);
        return true;
    }

    axios
        .delete(`/api/pricing-rules/${rule.id}`)
        .then(() => {
            toast({
                type: 'success',
                text: 'Rules delete!'
            });

            getData();
        })
        .catch((e) => {
            toast({
                type: 'danger',
                text: e.response.data.message
            });
        });
}

const handleSelectChange = (value, index) => {
    if (value === 5) {
        rules.value[index].values = [];
    }
}

const betweenRuleChanges = (value, position, index) => {
    let currentValues = Array.isArray(rules.value[index].values) ? [...rules.value[index].values] : [];

    if (position === 'low') currentValues[0] = value;
    if (position === 'high') currentValues[1] = value;

    rules.value[index].values = currentValues;
}


watch(
    () => currentWebsite.value,
    () => {
        getData();
    }
);

onMounted(() => getData());
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.pricing-tip {
    height: 0;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
}

.pricing-tip_bg {
    background-color: #f1f1f1;
}

.pricing-tip_quotes {
    color: rgba(28, 63, 170, var(--tw-bg-opacity));
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.rule-unit {
    max-width: 70px;
    right: 0;
}
</style>
