<script setup>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const text = ref('')
const showButton = ref(false)

function getDiffDaysMessage(date1, date2) {
  // Get the time difference in milliseconds
  const time1 = date1.getTime()
  const time2 = date2.getTime()

  if (time2 >= time1) {
    return 'Trial period: ' + Math.ceil(Math.abs(time2 - time1) / (1000 * 3600 * 24)) + ' days left'
  }

  if (time2 < time1) {
    store.commit('auth/setTrialPlanIsExpired', true)
    return 'Trial period is expired'
  }
}

onMounted(() => {
  const user = store.getters['auth/user']
  store.commit('auth/setTrialPlanIsExpired', false)

  if (user?.subData && user.subData.name === 'Default trial Plan' && !user.is_influencer) {
    const endsAtDate = new Date(user.subData.trial_ends_at)
    const nowDate = new Date()
    text.value = getDiffDaysMessage(nowDate, endsAtDate)
    showButton.value = true
  }
})

watch(() => store.getters['auth/user'], (user) => {
  if (user?.subData && user.subData.name === 'Default trial Plan' && !user.is_influencer) {
    const endsAtDate = new Date(user.subData.trial_ends_at)
    const nowDate = new Date()
    text.value = getDiffDaysMessage(nowDate, endsAtDate)
    showButton.value = true
  }
}, { immediate: true, deep: true })
</script>

<template>
  <a class="upgrate-button" v-if="showButton" href="/admin/plans">
    <span>Keep Access + Upgrade Now</span>
  </a>
</template>

<style scoped lang="scss">
.upgrate-button {
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3EAF69;
  border-radius: 8px;
  height: 40px;
  box-sizing: border-box;
  
  &:hover {
    background: #1c7430;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    white-space: nowrap;
  }
}
</style>
