const state = () => ({
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'TopMenu-dashboard',
        title: 'Dashboard',
        subMenu: [
          {
            icon: '',
            pageName: 'TopMenu-dashboard-overview-1',
            title: 'Overview 1'
          },
          {
            icon: '',
            pageName: 'TopMenu-dashboard-overview-2',
            title: 'Overview 2'
          },
          {
            icon: '',
            pageName: 'TopMenu-dashboard-overview-3',
            title: 'Overview 3'
          }
        ]
      },
      {
        icon: 'BoxIcon',
        pageName: 'TopMenu-menu-layout',
        title: 'Menu Layout',
        subMenu: [
          {
            icon: '',
            pageName: 'SideMenu-dashboard-overview-1',
            title: 'Side Menu',
            ignore: true
          },
          {
            icon: '',
            pageName: 'SimpleMenu-dashboard-overview-1',
            title: 'Simple Menu',
            ignore: true
          },
          {
            icon: '',
            pageName: 'TopMenu-dashboard-overview-1',
            title: 'Top Menu',
            ignore: true
          }
        ]
      },
      {
        icon: 'ActivityIcon',
        pageName: 'TopMenu-apps',
        title: 'Apps',
        subMenu: [
          {
            icon: 'UsersIcon',
            pageName: 'TopMenu-users',
            title: 'Users',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-users-layout-1',
                title: 'Layout 1'
              },
              {
                icon: '',
                pageName: 'TopMenu-users-layout-2',
                title: 'Layout 2'
              },
              {
                icon: '',
                pageName: 'TopMenu-users-layout-3',
                title: 'Layout 3'
              }
            ]
          },
          {
            icon: 'TrelloIcon',
            pageName: 'TopMenu-profile',
            title: 'Profile',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-profile-overview-1',
                title: 'Overview 1'
              },
              {
                icon: '',
                pageName: 'TopMenu-profile-overview-2',
                title: 'Overview 2'
              },
              {
                icon: '',
                pageName: 'TopMenu-profile-overview-3',
                title: 'Overview 3'
              }
            ]
          },
          {
            icon: 'InboxIcon',
            pageName: 'TopMenu-inbox',
            title: 'Inbox'
          },
          {
            icon: 'FolderIcon',
            pageName: 'TopMenu-file-manager',
            title: 'File Manager'
          },
          {
            icon: 'MessageSquareIcon',
            pageName: 'TopMenu-chat',
            title: 'Chat'
          },
          {
            icon: 'FileTextIcon',
            pageName: 'TopMenu-post',
            title: 'Post'
          },
          {
            icon: 'CalendarIcon',
            pageName: 'TopMenu-calendar',
            title: 'Calendar'
          },
          {
            icon: 'EditIcon',
            pageName: 'TopMenu-crud',
            title: 'Crud',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-crud-data-list',
                title: 'Data List'
              },
              {
                icon: '',
                pageName: 'TopMenu-crud-form',
                title: 'Form'
              }
            ]
          }
        ]
      },
      {
        icon: 'LayoutIcon',
        pageName: 'TopMenu-layout',
        title: 'Pages',
        subMenu: [
          {
            icon: '',
            pageName: 'TopMenu-wizards',
            title: 'Wizards',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-wizard-layout-1',
                title: 'Layout 1'
              },
              {
                icon: '',
                pageName: 'TopMenu-wizard-layout-2',
                title: 'Layout 2'
              },
              {
                icon: '',
                pageName: 'TopMenu-wizard-layout-3',
                title: 'Layout 3'
              }
            ]
          },
          {
            icon: '',
            pageName: 'TopMenu-blog',
            title: 'Blog',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-blog-layout-1',
                title: 'Layout 1'
              },
              {
                icon: '',
                pageName: 'TopMenu-blog-layout-2',
                title: 'Layout 2'
              },
              {
                icon: '',
                pageName: 'TopMenu-blog-layout-3',
                title: 'Layout 3'
              }
            ]
          },
          {
            icon: '',
            pageName: 'TopMenu-pricing',
            title: 'Pricing',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-pricing-layout-1',
                title: 'Layout 1'
              },
              {
                icon: '',
                pageName: 'TopMenu-pricing-layout-2',
                title: 'Layout 2'
              }
            ]
          },
          {
            icon: '',
            pageName: 'TopMenu-invoice',
            title: 'Invoice',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-invoice-layout-1',
                title: 'Layout 1'
              },
              {
                icon: '',
                pageName: 'TopMenu-invoice-layout-2',
                title: 'Layout 2'
              }
            ]
          },
          {
            icon: '',
            pageName: 'TopMenu-faq',
            title: 'FAQ',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-faq-layout-1',
                title: 'Layout 1'
              },
              {
                icon: '',
                pageName: 'TopMenu-faq-layout-2',
                title: 'Layout 2'
              },
              {
                icon: '',
                pageName: 'TopMenu-faq-layout-3',
                title: 'Layout 3'
              }
            ]
          },
          {
            icon: '',
            pageName: 'login',
            title: 'Login'
          },
          {
            icon: '',
            pageName: 'register',
            title: 'Register'
          },
          {
            icon: '',
            pageName: 'error-page',
            title: 'Error Page'
          },
          {
            icon: '',
            pageName: 'TopMenu-update-profile',
            title: 'Update profile'
          },
          {
            icon: '',
            pageName: 'TopMenu-change-password',
            title: 'Change Password'
          }
        ]
      },
      {
        icon: 'InboxIcon',
        pageName: 'TopMenu-components',
        title: 'Components',
        subMenu: [
          {
            icon: '',
            pageName: 'TopMenu-table',
            title: 'Table',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-regular-table',
                title: 'Regular Table'
              },
              {
                icon: '',
                pageName: 'TopMenu-Tabulator',
                title: 'Tabulator'
              }
            ]
          },
          {
            icon: '',
            pageName: 'TopMenu-overlay',
            title: 'Overlay',
            subMenu: [
              {
                icon: '',
                pageName: 'TopMenu-modal',
                title: 'Modal'
              },
              {
                icon: '',
                pageName: 'TopMenu-slide-over',
                title: 'Slide Over'
              },
              {
                icon: '',
                pageName: 'TopMenu-notification',
                title: 'Notification'
              }
            ]
          },
          {
            icon: '',
            pageName: 'TopMenu-accordion',
            title: 'Accordion'
          },
          {
            icon: '',
            pageName: 'TopMenu-button',
            title: 'Button'
          },
          {
            icon: '',
            pageName: 'TopMenu-alert',
            title: 'Alert'
          },
          {
            icon: '',
            pageName: 'TopMenu-progress-bar',
            title: 'Progress Bar'
          },
          {
            icon: '',
            pageName: 'TopMenu-tooltip',
            title: 'Tooltip'
          },
          {
            icon: '',
            pageName: 'TopMenu-dropdown',
            title: 'Dropdown'
          },
          {
            icon: '',
            pageName: 'TopMenu-typography',
            title: 'Typography'
          },
          {
            icon: '',
            pageName: 'TopMenu-icon',
            title: 'Icon'
          },
          {
            icon: '',
            pageName: 'TopMenu-loading-icon',
            title: 'Loading Icon'
          }
        ]
      },
      {
        icon: 'SidebarIcon',
        pageName: 'TopMenu-forms',
        title: 'Forms',
        subMenu: [
          {
            icon: '',
            pageName: 'TopMenu-regular-form',
            title: 'Regular Form'
          },
          {
            icon: '',
            pageName: 'TopMenu-datepicker',
            title: 'Datepicker'
          },
          {
            icon: '',
            pageName: 'TopMenu-tom-select',
            title: 'Tom Select'
          },
          {
            icon: '',
            pageName: 'TopMenu-file-upload',
            title: 'File Upload'
          },
          {
            icon: '',
            pageName: 'TopMenu-wysiwyg-editor',
            title: 'Wysiwyg Editor'
          },
          {
            icon: '',
            pageName: 'TopMenu-validation',
            title: 'Validation'
          }
        ]
      },
      {
        icon: 'HardDriveIcon',
        pageName: 'TopMenu-widgets',
        title: 'Widgets',
        subMenu: [
          {
            icon: '',
            pageName: 'TopMenu-chart',
            title: 'Chart'
          },
          {
            icon: '',
            pageName: 'TopMenu-slider',
            title: 'Slider'
          },
          {
            icon: '',
            pageName: 'TopMenu-image-zoom',
            title: 'Image Zoom'
          }
        ]
      }
    ]
  })

// Getters
const getters = { menu: (state) => state.menu }

// Actions
const actions = {}

// Mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
