<script setup>
import DeleteModal from '~/Components/Modal/DeleteModal.vue'
import MainButton from '../../Components/Button/MainButton.vue'
import { computed, defineProps, inject, defineEmits } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
import cash from 'cash-dom/dist/cash'
import axios from 'axios'
const toast = inject('toast');
const emits = defineEmits(['onLoading'])
const props = defineProps({
  category: {
    type: Object
  },
  allAttributes: {
    type: Array
  },
})

const children = computed(() => {
  return props.allAttributes.filter((attribute) => attribute.parents[0]?.id === props.category.id)
})

const deleteElement = (uuid) => {
  store.commit('onboarding/setDeleteElementUid', uuid)
  
  cash('#' + props.category.uuid).modal('show')
}

const closeModal = () => {
  const modal = cash('#' + props.category.uuid)
  modal.modal('hide')
}

const updateAttributesOnDelete = (uuid) => {
  const newAttributes = props.allAttributes.filter((attribute) => attribute.uuid !== uuid)
  store.commit('onboarding/setAttributes', newAttributes)
}

const deleteAttribute = () => {
  const ElementUid = store.getters['onboarding/getDeleteElementUid']
  if (!ElementUid) {
    toast({
      text: 'Error deleting attribute',
      type: 'danger'
    })
    closeModal()
    return
  }
  axios.delete('/api/onboarding-steps/delete-attribute', {
    params: {
      uuid: ElementUid
    }
  }).then(() => {
    store.commit('onboarding/setDeleteElementUid', null)
    toast({
      text: 'Service is deleted successfully',
      type: 'success'
    })
    updateAttributesOnDelete(ElementUid)
    closeModal()
  }).catch(() => {
    store.commit('onboarding/setDeleteElementUid', null)
    toast({ 
      text: 'Error deleting attribute',
      type: 'danger'
    })
    closeModal()
  })
}

const save = () => {
  emits('onLoading', true)
  const data = children.value.map((child) => {
    return {
      uuid: child.uuid,
      price: child.price
    }
  })
  
  axios.post('/api/onboarding-steps/update-attribute', {
    data
  }).then(() => {
    toast({
      text: 'Service is saved successfully',
      type: 'success'
    })
    emits('onLoading', false)
  }).catch(() => {
    toast({
      text: 'Error saving service',
      type: 'danger'
    })
    emits('onLoading', false)
  })
}
</script>

<template>
  <div class="category-price">
    <div class="delete-category">
      <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" @click.stop.prevent="deleteElement(category.uuid)">
        <path d="M0 18.5C0 23.4065 1.9491 28.1121 5.41852 31.5815C8.88795 35.0509 13.5935 37 18.5 37C23.4065 37 28.1121 35.0509 31.5815 31.5815C35.0509 28.1121 37 23.4065 37 18.5C37 13.5935 35.0509 8.88795 31.5815 5.41852C28.1121 1.9491 23.4065 0 18.5 0C13.5935 0 8.88795 1.9491 5.41852 5.41852C1.9491 8.88795 0 13.5935 0 18.5Z" fill="#F44336"/>
        <path d="M28 23.5987L24.2018 27L9 13.4013L12.8 10L28 23.5987Z" fill="white"/>
        <path d="M12.8014 27L9 23.6016L24.1986 10L28 13.4L12.8014 27Z" fill="white"/>
      </svg>
    </div>
    <h3>{{ category.value }}</h3>
    <div class="category-price__content">
      <div class="category-price__content__item" v-for="child in children" :key="`category-price-child-${child.id}`">
        <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg" @click.stop.prevent="deleteElement(child.uuid)">
          <path d="M5 27.5C4.175 27.5 3.4685 27.206 2.8805 26.618C2.2925 26.03 1.999 25.324 2 24.5V5H0.5V2H8V0.5H17V2H24.5V5H23V24.5C23 25.325 22.706 26.0315 22.118 26.6195C21.53 27.2075 20.824 27.501 20 27.5H5ZM20 5H5V24.5H20V5ZM8 21.5H11V8H8V21.5ZM14 21.5H17V8H14V21.5Z" fill="#C9000E" />
        </svg>
        <h4>{{ child.value }}</h4>
        <div class="input-group">
          <div class="input-group-text">$</div>
          <input type="number" class="form-control py-2 px-4 block" placeholder="0.00" step="0.0001" v-model="child.price">
          <span v-if="category?.metric" class="metrics">/{{ category?.metric?.currency ?? '' }}</span>
        </div>
      </div>
    </div>
    <div class="save-button">
      <MainButton
        title="Save"
        class="flex justify-end mobile-button green-btn"
        @click="save"
      />
    </div>
    <DeleteModal :id="category.uuid" @delete="deleteAttribute" />
  </div>
</template>

<style scoped lang="scss">
.save-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.category-price {
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 0 0 18px rgba(176, 176, 176, 0.2);
  padding: 20px 40px;
  background: #fff;
  max-width: 600px;
  border-radius: 20px;
  
  svg {
    &:hover {
      cursor: pointer;
    }
  }
  
  @media (max-width: 768px) {
    padding: 12px;
  }
  
  .delete-category {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(20px, -20px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    
    &:hover {
      cursor: pointer;
    }
  }
  
  h3 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    margin: 0 0 16px;
  }
  
  .category-price__content__item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 16px;
    
    h4 {
      font-family: Poppins;
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
    
    .input-group {
      align-items: center;
      flex-wrap: wrap;
      
      input {
        max-width: 160px;
      }
      
      div, input, span {
        font-family: Poppins;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
</style>
