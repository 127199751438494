import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

const helpers = {
    cutText(text, length) {
        if (text.split(' ').length > 1) {
            const string = text.substring(0, length)
            const splitText = string.split(' ')
            splitText.pop()

            return `${splitText.join(' ')}...`
        }

        return text
    },
    formatDate(date, format) {
        return dayjs(date).format(format)
    },
    capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        }

        return ''
    },
    onlyNumber(string) {
        if (string) {
            return string.replace(/\D/g, '')
        }

        return ''
    },
    formatCurrency(number) {
        if (number) {
            const formattedNumber = number.toString().replace(/\D/g, '')
            const rest = formattedNumber.length % 3
            let currency = formattedNumber.substr(0, rest)
            const thousand = formattedNumber.substr(rest).match(/\d{3}/g)
            let separator

            if (thousand) {
                separator = rest ?
                    '.' :
                    ''
                currency += separator + thousand.join('.')
            }

            return currency
        }

        return ''
    },
    timeAgo(time) {
        const date = new Date(
            (time || '').replace(/-/g, '/').replace(/[TZ]/g, ' ')
        )
        const diff = (new Date().getTime() - date.getTime()) / 1000
        const dayDiff = Math.floor(diff / 86400)

        if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
            return dayjs(time).format('MMMM DD, YYYY')
        }

        return (
            dayDiff === 0 &&
            (diff < 60 && 'just now' ||
                diff < 120 && '1 minute ago' ||
                diff < 3600 && `${Math.floor(diff / 60)} minutes ago` ||
                diff < 7200 && '1 hour ago' ||
                diff < 86400 && `${Math.floor(diff / 3600)} hours ago`) ||
            dayDiff === 1 && 'Yesterday' ||
            dayDiff < 7 && `${dayDiff} days ago` ||
            dayDiff < 31 && `${Math.ceil(dayDiff / 7)} weeks ago`
        )
    },
    diffTimeByNow(time) {
        const startDate = dayjs(
            dayjs().format('YYYY-MM-DD HH:mm:ss').toString()
        )
        const endDate = dayjs(
            dayjs(time).format('YYYY-MM-DD HH:mm:ss').toString()
        )

        const duration = dayjs.duration(endDate.diff(startDate))
        const milliseconds = Math.floor(duration.asMilliseconds())

        const days = Math.round(milliseconds / 86400000)
        const hours = Math.round(milliseconds % 86400000 / 3600000)
        let minutes = Math.round(milliseconds % 86400000 % 3600000 / 60000)
        const seconds = Math.round(
            milliseconds % 86400000 % 3600000 % 60000 / 1000
        )

        if (seconds < 30 && seconds >= 0) {
            minutes += 1
        }

        return {
            days: days.toString().length < 2 ?
                `0${days}` :
                days,
            hours: hours.toString().length < 2 ?
                `0${hours}` :
                hours,
            minutes: minutes.toString().length < 2 ?
                `0${minutes}` :
                minutes,
            seconds: seconds.toString().length < 2 ?
                `0${seconds}` :
                seconds
        }
    },
    isset(obj) {
        if (obj !== null && obj !== undefined) {
            if (typeof obj === 'object' || Array.isArray(obj)) {
                return Object.keys(obj).length
            }

            return obj.toString().length
        }

        return false
    },
    toRaw(obj) {
        return JSON.parse(JSON.stringify(obj))
    },
    randomNumbers(from, to, length) {
        const numbers = [ 0 ]
        for (let i = 1; i < length; i++) {
            numbers.push(Math.ceil(Math.random() * (from - to) + to))
        }

        return numbers
    }
}

const install = (app) => {
    app.config.globalProperties.$h = helpers
}

export { install as default, helpers as helper }
