<template>
    <GeneralLayout>
        <DarkModeSwitcher />
        <div>
            <div class="min-h-screen">
                <div class="flex justify-center text-center items-center flex-col h-screen mx-4">
                    <div class="pb-[4rem]">
                        <img :src="logoTransparent" alt="" class="w-[205px]">
                    </div>
                    <MainBlock class-map="w-full max-w-[550px]" title="Forgot Password">
                        <template #body>
                            <form class="w-full" @submit.prevent="save">
                                <fieldset>
                                    <div class="intro-x text-left mt-5">
                                        <p>Don't worry, it happens. Please enter your email address. We will send you an email to reset your password.</p>
                                    </div>
                                    <div class="mt-4 mb-2">
                                        <FormInput
                                            v-model="validate.email.$model"
                                            :errors="validate.email.$errors"
                                            label="Email"
                                            type="email"
                                            placeholder="Enter email"
                                        />
                                    </div>
                                    <div class="flex justify-center gap-4 mt-4">
                                        <MainButton title="Send" type="submit" :type-button="processing ? 'disabled' : ''" />
                                        <MainButton>
                                            <template #body>
                                                <router-link to="/login">
                                                    Sign in
                                                </router-link>
                                            </template>
                                        </MainButton>
                                    </div>
                                </fieldset>
                            </form>
                        </template>
                    </MainBlock>
                </div>
            </div>
        </div>
    </GeneralLayout>
</template>

<script setup>
import { onMounted, inject, reactive, toRefs, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import DarkModeSwitcher from '@components/DarkModeSwitcher.vue';
import FormInput from "@components/UI/Input.vue";
import logoTransparent from '@images/svg/logo.svg';
import MainButton from "@components/Button/MainButton.vue";
import MainBlock from "../../Components/MainBlock.vue";
import GeneralLayout from "../../Layouts/GeneralLayout.vue";


const store = useStore();
const router = useRouter();
const userData = reactive({
    email: ''
});
const userData_v = {
    email: { required, email }
}
const toast = inject('toast');
const validate = useVuelidate(userData_v, toRefs(userData));
const processing = ref(false);

const save = async () => {
    validate.value.$touch();

    if (!validate.value.$invalid) {
        processing.value = true;

        await store.dispatch('auth/forgotPassword', userData).then((response) => {
            toast({
                text: response.data.message,
                type: 'success'
            });
        }).catch((e) => {
            toast({
                text: e.response.data.message,
                type: 'danger'
            });
        }).finally(() => {
            processing.value = false;

            router.push({ name: 'login' });
        });
    }
}


onMounted(() => {
    document.getElementsByTagName('body')[0].classList.add('login');
});
</script>
