<template>
    <Layout title="Websites">
        <template #body>
            <tabulator
                title="Users"
                :ajax-url="options.ajaxUrl"
                :ajax-params="options.ajaxParams"
                :columns="options.columns"
                :default-filter-field="options.defaultFilterField"
                :filtering-fields="options.filteringFields"
                :ditailing-filter="false"
            />
        </template>
    </Layout>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { websitesManagementColumns } from '@scripts/component/WebsitesManagementColumns';
import { websitesManagementFilteringFields } from '@scripts/component/WebsitesManagementFilteringFields';
import Tabulator from '@components/Tabulator/Tabulator';
import Layout from "@layouts/Layout.vue";


const router = useRouter();
const options = {
    ajaxUrl: '/api/super-admin/admin-websites',
    columns: websitesManagementColumns,
    filteringFields: websitesManagementFilteringFields,
    defaultFilterField: 'code'
}
</script>

<style scoped>

</style>
