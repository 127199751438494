<template>
    <router-view />
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
const router = useRouter();

const store = useStore();

async function goToLink(e) {
  e.preventDefault()
  e.stopPropagation()
  await router.push({ path: '/new-guide' })
}

onMounted(() => {
    if (window.auth) {
        store.commit('auth/setUser', window.auth)
        store.commit('auth/setLoggedIn', true)
        store.commit('auth/setSubscribed', window.auth.subscribed)
    }
});
</script>
