import Button from './Button.vue'
import Dot from './Dot.vue'
import Input from './Input.vue'
import Modal from './Modal.vue'
import TextEditor from './TextEditor.vue'
import ContentEditor from './ContentEditor.vue'

const components = [
    Button,
    Dot,
    Input,
    Modal,
    TextEditor,
    ContentEditor
]

export default (app) => {
    components.forEach(component => app.component(component.name, component))
}
