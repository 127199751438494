<template>
    <Layout title="Subscription plans">
        <template #body>
            <SubSelector
                :subscriptions="subscriptions"
                @setActive="setActive"
                @deleted="getSubData"
                @save="getSubData"
                @updated="getSubData"
            />
        </template>
    </Layout>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue';
import axios from 'axios';
import SubSelector from './SubSelector.vue';
import SubSetting from './SubSetting.vue';
import Layout from "@layouts/Layout.vue";


const subscriptions = ref([]);
const activeSub = ref(false);
const toast = inject('toast');


const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const setActive = (val) => {
    activeSub.value = subscriptions.value[val]
}

const getSubData = () => {
    axios
        .get('/api/get-subscription-plans')
        .then((res) => {
            subscriptions.value = res.data[0] ?? [];
        });
}


onMounted(() => {
    getSubData();
})
</script>
