import * as types from './mutation-types'

const state = () => ({
    darkMode: false,
    loading: false,
    impersonated: false,
    customersPage: 1,
    customersSize: 10,
    notificationsPage: 1,
    notificationsSize: 10,
    transactionsPage: 1,
    transactionSize: 10,
    subscriberPage: 1,
    subscriberSize: 10
})

// Getters
const getters = {
    darkMode: (state) => state.darkMode,
    loading: (state) => state.loading,
    customersPage: (state) => state.customersPage,
    customersSize: (state) => state.customersSize,
    notificationsPage: (state) => state.notificationsPage,
    notificationsSize: (state) => state.notificationsSize,
    transactionPage: (state) => state.transactionPage,
    transactionSize: (state) => state.transactionSize,
    subscriberPage: (state) => state.subscriberPage,
    subscriberSize: (state) => state.subscriberSize,
    impersonated: (state) => state.impersonated
}

// Actions
const actions = {
    media(context, file) {
        return this.axios.post('/api/v1/media', file)
    },
    setImpersonated({ commit }, value) {
        commit('setImpersonated', value)
    },
    setDarkMode({ commit }, darkMode) {
        commit(types.SET_DARK_MODE, { darkMode })
    },
    setCustomersPage({ commit }, value) {
        commit('setCustomersPage', value)
    },
    setCustomersSize({ commit }, value) {
        commit('setCustomersSize', value)
    },
    setTransactionSize({ commit }, value) {
        commit('setTransactionSize', value)
    },
    setTransactionPage({ commit }, value) {
        commit('setTransactionPage', value)
    },
    setSubscriberSize({ commit }, value) {
        commit('setSubscriberSize', value)
    },
    setSubscriberPage({ commit }, value) {
        commit('setSubscriberPage', value)
    }
}

// Mutations
const mutations = {
    [types.SET_DARK_MODE](state, { darkMode }) {
        state.darkMode = darkMode
    },
    setImpersonated(state, value) {
        state.impersonated = value
    },
    setLoading(state, bool) {
        state.loading = bool
    },
    setCustomersPage(state, value) {
        state.customersPage = value
    },
    setCustomersSize(state, value) {
        state.customersSize = value
    },
    setNotificationsPage(state, value) {
        state.notificationsPage = value
    },
    setNotificationsSize(state, value) {
        state.notificationsSize = value
    },
    setTransactionSize(state, value) {
        state.transactionSize = value
    },
    setTransactionPage(state, value) {
        state.transactionPage = value
    },
    setSubscriberSize(state, value) {
        state.subscriberSize = value
    },
    setSubscriberPage(state, value) {
        state.subscriberPage = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
