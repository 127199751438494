export default {
    setUser(state, data) {
        state.user = data;

    },

    setLoggedIn(state, data) {
        state.loggedIn = data
    },

    setSubscribed(state, data) {
        state.subscribed = data
    },

    setIsWebSites(state, data) {
        state.isWebSites = data
    },

    setAppSettings(state, data) {
        state.appSettings = data;
    },
    
    setTrialPlanIsExpired(state, data) {
        state.trialPlanIsExpired = data;
    }
}
