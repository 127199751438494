import axios from "axios";

export default {
    store (context, payload) {
        return this.axios.post('/api/website', payload)
    },
    index (context, userId) {
        return this.axios(`/api/website?owner_id=${userId}`)
    },
    pages (context, websiteId) {
        return this.axios(`/api/page/${websiteId}`)
    },
    page (context, payload) {
        return this.axios.put(`/api/page/${payload.id}`, payload)
    },
    settings (context, payload) {
        return this.axios.post('/api/settings', payload)
    },
    website ({ getters }, payload) {
        return this.axios.post('/api/website', payload)
    },
    updateWebsite ({ getters }, payload) {
        return this.axios.put(`/api/website/${getters.websiteId}`, payload)
    },
    emailSettings (context, payload) {
        return this.axios.get('/api/settings', { params: { settings: [ 'emailSettings' ] } })
    },
    websiteSettings ({ getters }, payload) {
        return this.axios.post(`/api/settings/${getters.websiteId}`, payload)
    },
    setWebsiteSettings (context, websiteId) {
        return this.axios.get(`/api/websiteSettings/`)
    }
}
