<template>
    <Layout title="Manager Details">
        <template #body>
            <ButtonBack title="Managers list" :to="{name: 'managers-management'}" />
            <div>
                <div class="grid grid-cols-2 gap-4">
                    <div class="col-span-2 sm:col-span-1 form-group">
                        <FormInput
                            v-model="validate.first_name.$model"
                            :errors="validate.first_name.$errors"
                            label="First Name:"
                            placeholder="Enter First Name"
                        />
                    </div>
                    <div class="col-span-2 sm:col-span-1 form-group">
                        <FormInput
                            v-model="validate.last_name.$model"
                            :errors="validate.last_name.$errors"
                            label="Last Name:"
                            placeholder="Enter Last Name"
                        />
                    </div>
                    <div class="col-span-2 sm:col-span-1 form-group">
                        <FormInput
                            v-model="validate.email.$model"
                            :errors="validate.email.$errors"
                            type="email"
                            label="Email:"
                            placeholder="Enter Email"
                        />
                    </div>
                    <div class="col-span-2 sm:col-span-1 form-group">
                        <InputPhone
                            v-model="validate.contact_number.$model"
                            :default-val="validate.contact_number.$model"
                            :errors="validate.contact_number.$errors"
                            label="Phone:"
                            placeholder="Enter Phone"
                        />
                    </div>
                    <div class="col-span-2 sm:col-span-1">
                        <CustomSelect
                            v-model="validate.managerRolesList.$model"
                            :options="roles"
                            label="Roles:"
                        />
                    </div>
                </div>
                <div class="flex my-4 gap-4 justify-center">
                    <MainButton
                        :title="isUpdate ? 'Update' : 'Create'"
                        :type-button="processing ? 'disabled' : ''"
                        @click="addUpdateManager"
                    />
                    <a
                        v-if="isUpdate && isOwner"
                        type="button"
                        class="button danger"
                        data-toggle="modal"
                        :data-target="`#delete-modal-${managerId}`"
                    >
                        Delete
                    </a>
                </div>
            </div>
        </template>
    </Layout>

    <DeleteModal :id="`delete-modal-${managerId}`" @delete="deleteManager" />
</template>

<script setup>
import { computed, getCurrentInstance, inject, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { maxLength, required, email, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import cash from 'cash-dom/dist/cash';
import axios from 'axios';
import Layout from "@layouts/Layout.vue";
import ButtonBack from "@components/Button/ButtonBack.vue";
import InputPhone from "@components/UI/InputPhone.vue";
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import DeleteModal from "@components/Modal/DeleteModal.vue";
import Select from "@components/UI/Select.vue";
import CustomSelect from "../../Components/UI/CustomSelect.vue";


const store = useStore();
const toast = inject('toast');
const owner = computed(() => store.getters['auth/user']);
const isOwner = !owner.value.owner_id;
const router = useRouter();
const roles = ref([]);
const processing = ref(true);
const { proxy } = getCurrentInstance();
// eslint-disable-next-line no-underscore-dangle
let managerId = router.currentRoute._value.params.id;
const isUpdate = computed(() => managerId > 0 ?? false);
const managerData = ref({
    last_name: '',
    first_name: '',
    email: '',
    contact_number: '',
    managerRolesList: 0
});
const rules = {
    last_name: { required, maxLength: maxLength(40) },
    first_name: { required, maxLength: maxLength(40) },
    email: { required, email },
    contact_number: { required },
    managerRolesList: { required, minLength: minLength(1) },
}
const ownerData = {
    company_name: owner.value.company_name,
    owner_id: owner.value.id
}
const validate = useVuelidate(rules, managerData);

const getData = async () => {
    axios.get(`/api/v1/user/manager/${ managerId }`).then(({ data }) => {
        managerData.value = data.user;
        managerData.value.managerRolesList = data.user.role.id;
        roles.value = data.roles;
        processing.value = false;
    }).catch((e) => {
        toast({
            type: 'danger',
            text: e.response.data
        });
        router.push({ name: 'managers-management' });
    });
}

const getRoles = async () => {
    axios.get('/api/v1/user/roles-list').then((response) => {
        const { data } = response;
        roles.value = data.roles;
        processing.value = false;
        managerData.value.managerRolesList = managerData.value.managerRolesList == 0 ? roles.value[0].value : managerData.value.managerRolesList;
    }).catch((e) => {
        toast({
            type: 'danger',
            text: e.response.data
        });
        router.push({ name: 'managers-management' });
    });
}

const addUpdateManager = () => {
    validate.value.$touch();

    if (!validate.value.$invalid) {
        processing.value = true;

        Object.assign(managerData.value, ownerData, { userRoles: managerData.value.managerRolesList });

        if (Number(managerId) === 0) {
            Object.assign(managerData.value, { password: Math.random().toString(36).slice(-8) });

            axios.post('/api/v1/user/manager', managerData.value).then((response) => {
                toast({
                    type: 'success',
                    text: 'Manager updated'
                });
                router.push({ name: 'managers-management' });
                processing.value = false;
            }).catch((e) => {
                proxy.$errorHandler(e);
                processing.value = false;
            })
        } else {
            axios.put(`/api/v1/user/manager/${managerId}`, managerData.value).then((response) => {
                toast({
                    type: 'success',
                    text: 'Manager updated'
                });
                router.push({ name: 'managers-management' });
                processing.value = false;
            }).catch((e) => {
                proxy.$errorHandler(e);
                processing.value = false;
            })
        }
    }
}

const deleteManager = () => {
    axios.post('/api/v1/user/manager', {
        id: managerId,
        _method: 'delete'
    }).then(() => {
        cash(`#delete-modal-${managerId}`).modal('hide');
        router.push({ name: 'managers-management' });
    })
}

onMounted(() => {
    if (managerId > 0) {
        getData();
    } else {
        getRoles();
    }
});
</script>

<style lang="css" scoped>
.form-group {
    margin: .75rem 0;
}
</style>