<script setup>
import OnboardingStep from './OnboardingStep.vue'
import Layout from '@layouts/Layout.vue'
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const onboardingSteps = computed({
  get() {
    return store.getters['onboarding/getOnboardingSteps'];
  },
  set(value) {
    store.commit('onboarding/setOnboardingSteps', value);
  }
});

onMounted(async () => {
  await store.dispatch('onboarding/getOnboardingStepsAction')
});
</script>

<template>
  <Layout title="Onboarding Checklist">
    <template #body> 
      <div class="onboarding-wrapper">
        <OnboardingStep
          v-for="step in onboardingSteps"
          :step="step"
          :key="`onboarding-step-${step.id}`"
        />
      </div>
    </template>
  </Layout>
</template>

<style scoped lang="scss">

</style>
