<script setup>
import MainButton from '../../../Components/Button/MainButton.vue'
import FormInput from '../../../Components/UI/Input.vue'
import { onMounted, ref, watch } from 'vue'
const props = defineProps({
  id: {
    type: String
  },
  label: {
    type: String
  },
  optionTiers: {
    type: Array,
    default: []
  },
  priceType: {
    type: String,
  }
})
const emits = defineEmits(['updateTiers', 'updateTiersPriceType'])

const tiers = ref([])
const selectedPriceType = ref('flat fee')

onMounted(() => {
  tiers.value = props.optionTiers
  selectedPriceType.value = props.priceType ? props.priceType : props.label
})

watch(() => props.priceType, (value) => {
  selectedPriceType.value = value
})

watch(() => tiers.value, (value) => {
  emits('updateTiers', tiers.value)
}, {
  deep: true
})

watch(() => selectedPriceType.value, (value) => {
  emits('updateTiersPriceType', value)
})

watch(() => props.optionTiers, (value) => {
  tiers.value = value
}, {
  deep: true
})

const generateId = () => {
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
}

const getLatestHigh = () => {
  if (tiers.value.length === 0) {
    return 0
  }
  return tiers.value[tiers.value.length - 1].higth
}

const addTier = () => {
  tiers.value.push({
    id: generateId(),
    low: getLatestHigh() ? getLatestHigh() : 0,
    higth: 0,
    price: 0
  })
}

const selectTierPriceType = (type) => {
  selectedPriceType.value = type
}

const deleteTier = (tier) => {
  tiers.value = tiers.value.filter((t) => t.id !== tier.id)
}
</script>

<template>
  <div class="mt-2">
    <div class="relative mt-2 mb-2 table">
      <div class="table-head">
        <div class="table-row">
          <div class="left text-xl font-medium text-gray-600">{{ label }}</div>
          <div class="right text-xl font-medium text-gray-600">Price</div>
        </div>
      </div>
      <div class="table-body">
        <div class="table-row">
          <div class="left">
            <div>Low</div>
            <div>High</div>
          </div>
          <div class="right">
            <div @click.prevent="selectTierPriceType(props.label)" class="p-1 hover:cursor-pointer price-type" :class="{ 'selected': selectedPriceType == props.label}">Per {{ label }}</div>
            <div @click.prevent="selectTierPriceType('flat fee')" class="p-1 hover:cursor-pointer price-type" :class="{ 'selected': selectedPriceType == 'flat fee'}">Flat Fee</div>
          </div>
        </div>
        <div v-for="(tier) in tiers" :key="'tier-' + tier.id" class="table-row  pt-3">
          <div class="left">
            <input min="0" step="any" v-model="tier.low" type="number" class="w-20 border border-gray-300 rounded-md p-1" />
            <input min="0" step="any" v-model="tier.higth" type="number" class="w-20 border border-gray-300 rounded-md p-1" />
          </div>
          <div class="right">
            <FormInput
              v-model.lazy="tier.price"
              :step="0.01"
              :min="0.01"
              type="number"
              placeholder="0.00"
            >
              <template #prepend>
                <div
                  v-text="$store?.getters['website/website']?.currency?.sign ?? '$'"
                  class="input-group-text"
                />
              </template>
            </FormInput>
            <a @click.prevent.stop="deleteTier(tier)" class="block col-span-12 sm:col-span-5 2xl:col-span-1 z-50" data-toggle="modal">
              <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 27.5C4.175 27.5 3.4685 27.206 2.8805 26.618C2.2925 26.03 1.999 25.324 2 24.5V5H0.5V2H8V0.5H17V2H24.5V5H23V24.5C23 25.325 22.706 26.0315 22.118 26.6195C21.53 27.2075 20.824 27.501 20 27.5H5ZM20 5H5V24.5H20V5ZM8 21.5H11V8H8V21.5ZM14 21.5H17V8H14V21.5Z" fill="#C9000E"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="flex w-full items-center h-full justify-evenly mb-4">
      <MainButton
        class-map="w-32"
        title=""
        size-button="small"
        @click="addTier"
      >
        <template #icon>
          <div class="text-4xl h-full flex items-center justify-center">+</div>
        </template>
      </MainButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    width: 100%;
  }
  
  .left, .right {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  
  .selected {
    background: #3EAF69;
    border-radius: 4px;
  }
  
  .price-type {
    border: 1px solid #6E6E6E;
    border-radius: 4px;
  }
</style>
