<script setup>
import MainButton from '../../Components/Button/MainButton.vue'
import { defineProps, computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const toast = inject('toast')
import circleSvg from '@images/svg/onboarding/circle.svg';
import completedSvg from '@images/svg/onboarding/completed.svg';
import skippedSvg from '@images/svg/onboarding/skipped.svg';
const store = useStore()
const router = useRouter()
const props = defineProps({
  step: Object,
  default: () => ({})
})

const currentStepId = computed({
  get() {
    return store.getters['onboarding/getCurrentStepId']
  },
  set() {
    store.commit('onboarding/setCurrentStepId', value)
  }
})

const isCurrentStep = computed(() => {
  return currentStepId.value == props.step.id
})

const isStepRedirectAction = computed(() => {
  const redirects = {
    link: '/dashboard',
    blank: false,
    is_quote: false
  }
  
  if (props.step.id == 1) {
    redirects.link = openLending()
    redirects.is_quote = true,
    redirects.blank = true
  }
  if (props.step.id == 2) {
    redirects.link = '/admin/quick-price-change'
  }
  if (props.step.id == 3) {
    redirects.link = '/admin/products'
  }
  if (props.step.id == 4) {
    redirects.link = '/admin/website?scrollTo=logo'
  }
  if (props.step.id == 5) {
    redirects.link = '/admin/quote-install-tool'
  }
  
  return redirects
})

const openLending = () => {
  const website = computed(() => store.getters['website/website']);
  const { code } = website.value;
  return code ? `${ window.location.protocol }//${ code }.${ window.location.hostname }` + '/quote?preview=true' : '';
}

const onStepStart = async () => {
  try {
    const response = await store.dispatch('onboarding/completeOnboardingStepAction', {
      onboarding_step_id: props.step.id,
      is_completed: true,
      is_skipped: false
    })
  } catch (error) {
    toast({
      text: 'Something went wrong! Please try again later.',
      type: 'danger'
    })
  }
  
  if (isStepRedirectAction.value.blank && isStepRedirectAction.value.is_quote) {
    window.open(isStepRedirectAction.value.link, '_blank')
  } else {
    await router.push(isStepRedirectAction.value.link)
  }
}

const onStepSkip = async () => {
  try {
    const response = await store.dispatch('onboarding/completeOnboardingStepAction', {
      onboarding_step_id: props.step.id,
      is_completed: false,
      is_skipped: true
    })
  } catch (error) {
    toast({
      text: 'Something went wrong! Please try again later.',
      type: 'danger'
    })
  }
}

const setIsCurrent = () => {
  store.commit('onboarding/setCurrentStepId', props.step.id)
}
</script>

<template>
  <div class="onboarding-step" :key="isCurrentStep">
    <div class="onboarding-title" @click="setIsCurrent">
      <img :src="completedSvg" alt="completed" v-if="step.pivot.is_completed">
      <img :src="skippedSvg" alt="skipped" v-else-if="step.pivot.is_skipped">
      <img :src="circleSvg" alt="circle" v-else>
      <h3 class="block font-medium">{{ step.title }}</h3>
    </div>
    <div class="onboarding-buttons" v-if="isCurrentStep">
      <MainButton
        title="Start"
        class="mobile-button green-btn"
        @click="onStepStart"
      />
      <MainButton
        title="Skip"
        class="mobile-button"
        @click="onStepSkip"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .mobile-button {
    padding: 2px 16px !important;
  }
  
  .onboarding-step {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
  }
  
  .onboarding-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    flex-wrap: nowrap;
    
    &:hover {
      cursor: pointer;
    }
    
    img {
      width: 24px;
      height: 24px;
    }
    
    h3 {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 1);
    }
  }
  
  .onboarding-buttons {
    display: flex;
    gap: 12px;
    align-items: center;
  }
</style>
