<template>
    <div class="mobile-menu md:hidden">
        <div class="mobile-menu-bar">
            <a href="javascript:void(0);" class="flex mr-auto">
                <img
                    alt=""
                    class="w-[215px]"
                    :src="Logo"
                >
            </a>
            <BarChart2Icon
                class="w-8 h-8 text-black transform -rotate-90 cursor-pointer"
                @click="toggleMobileMenu"
            />
        </div>
        <transition @enter="enter" @leave="leave">
            <ul
                v-if="activeMobileMenu"
                class="border-t border-theme-32 py-5"
            >
                <template v-for="(menu, menuKey) in formattedMenu">
                    <li
                        v-if="menu == 'divider'"
                        :key="menu + menuKey"
                        class="menu__devider my-6 text-black"
                    />
                    <li
                        v-else
                        :key="menu + menuKey"
                    >
                        <a
                            href="javascript:void(0);"
                            class="menu"
                            :class="{
                                'menu--active': menu.active,
                                'menu--open': menu.activeDropdown
                            }"
                            @click="linkTo(menu, router)"
                        >
                            <div class="menu__icon">
                                <component :is="menu.icon" />
                            </div>
                            <div class="menu__title">
                                {{ menu.title }}
                                <div
                                    v-if="menu.subMenu"
                                    class="menu__sub-icon"
                                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                                >
                                    <ChevronDownIcon />
                                </div>
                            </div>
                        </a>
                        <transition
                            @enter="enter"
                            @leave="leave"
                        >
                            <ul v-if="menu.subMenu && menu.activeDropdown">
                                <li
                                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                                    :key="subMenuKey"
                                >
                                    <a
                                        href="javascript:void(0);"
                                        class="menu"
                                        :class="{ 'menu--active': subMenu.active }"
                                        @click="linkTo(subMenu, router)"
                                    >
                                        <div class="menu__icon">
                                            <component
                                                v-if="subMenu.icon"
                                                :is="subMenu.icon"
                                            />

                                            <ActivityIcon v-else />
                                        </div>
                                        <div class="menu__title">
                                            {{ subMenu.title }}
                                            <div
                                                v-if="subMenu.subMenu"
                                                class="menu__sub-icon"
                                                :class="{
                                                    'transform rotate-180': subMenu.activeDropdown
                                                }"
                                            >
                                                <ChevronDownIcon />
                                            </div>
                                        </div>
                                    </a>
                                    <transition
                                        @enter="enter"
                                        @leave="leave"
                                    >
                                        <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                                            <li
                                                v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                                                :key="lastSubMenuKey"
                                            >
                                                <a
                                                    href="javascript:void(0);"
                                                    class="menu"
                                                    :class="{ 'menu--active': lastSubMenu.active }"
                                                    @click="linkTo(lastSubMenu, router)"
                                                >
                                                    <div class="menu__icon">
                                                        <ZapIcon />
                                                    </div>
                                                    <div class="menu__title">
                                                        {{ lastSubMenu.title }}
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </transition>
                                </li>
                            </ul>
                        </transition>
                    </li>
                </template>
                <li>
                  <div class="share-button p-2 flex justify-center">
                    <MainButton
                      :type-button="shareButtonDisabled ? 'disabled' : ''"
                      title="Share Quote Link"
                      class-map="mt-10 mb-10 flex gap-1 align-center green-btn"
                      @click="share"
                    >
                      <template #icon>
                        <svg width="20px" height="20px" viewBox="0 0 32 32" id="Outlined" xmlns="http://www.w3.org/2000/svg">
                          <title/>
                          <g id="Fill">
                            <path d="M25,22a4,4,0,0,0-3.26,1.69l-11-6.4A4,4,0,0,0,11,16a4.14,4.14,0,0,0-.1-.87L22,8.65A4,4,0,1,0,21,6a4.14,4.14,0,0,0,.1.87L10,13.35A4,4,0,1,0,7,20a4,4,0,0,0,2.66-1L21,25.6c0,.13,0,.26,0,.4a4,4,0,1,0,4-4ZM25,4a2,2,0,1,1-2,2A2,2,0,0,1,25,4ZM7,18a2,2,0,1,1,2-2A2,2,0,0,1,7,18ZM25,28a2,2,0,1,1,2-2A2,2,0,0,1,25,28Z"/>
                          </g>
                        </svg>
                      </template>
                    </MainButton>
                  </div>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@store';
import { helper as $h } from '@scripts/utils/helper';
import {
    activeMobileMenu,
    toggleMobileMenu,
    linkTo,
    enter,
    leave
} from './index';
import { nestedMenu } from '@layouts/SideMenu';
import Logo from "../../../assets/images/svg/logo.svg";
import MainButton from '../Button/MainButton.vue'

const route = useRoute();
const router = useRouter();
const store = useStore();
const formattedMenu = ref([]);
const sideMenu = computed(() => nestedMenu(route));

const website = computed(() =>store.getters['website/website']);
const shareButtonDisabled = computed(() => store.getters['auth/getTrialPlanIsExpired']);

function share() {
  const specificRoute = `${ window.location.protocol }//${ website.value.code }.${ window.location.hostname }/quote`;
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(specificRoute).then(() => {
      alert('Quote link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  } else {
    alert('Quote link can\'t be copied to clipboard!');
  }
}


const getFormattedMenu = () => {
    setTimeout(() => {
        formattedMenu.value = $h.toRaw(nestedMenu(route))
    }, 250);
}


watch(
    computed(() => route.path),
    () => {
        getFormattedMenu();
    }
);


onMounted(() => {
    getFormattedMenu();
});
</script>
