<template>
    <div>
        <TipHidePrice :tip-style="tipStyle" />

        <Layout title="Pricing Catalog">
            <template #body>
                <AttributesItems
                    :attributeType="ATTRIBUTE_TYPE"
                    @attributeChosen="attributeChosen"
                />

                <PriceCatalogTabulator
                    :ajax-url="options.ajaxUrl"
                    :ajax-params="options.ajaxParams"
                    :columns="options.columns"
                    :filtering-fields="options.filteringFields"
                />
            </template>
        </Layout>

    </div>
</template>
<script setup>
import { reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import tippy from 'tippy.js';
import AttributesItems from './Partials/AttributesItems.vue';
import PriceCatalogTabulator from './Partials/PriceCatalogTabulator.vue';
import TipHidePrice from "@components/Tooltips/TipHidePrice.vue";
import Layout from "@layouts/Layout.vue";


const store = useStore();
const router = useRouter();
const ATTRIBUTE_TYPE  = 1;

const columns = [
    {
        title: 'Option',
        responsive: 0,
        field: 'option',
        hozAlign: 'center',
        titleFormatter: function(cell, formatterParams, onRendered){
            return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
        }
    },
    {
        title: 'Upgrade',
        responsive: 0,
        field: 'upgrade',
        hozAlign: 'center',
        titleFormatter: function(cell, formatterParams, onRendered){
            return "<div style='text-align:center;'>" + cell.getValue() + "</div>";
        }
    },
    {
        title: 'Option Price',
        field: 'priceOption',
        hozAlign: 'center',
        editor:'input',
        validator: "numeric",
    },
    {
        title: 'Upgrade Price',
        field: 'priceUpgrade',
        hozAlign: 'center',
        validator: "numeric",
        editor: function (cell, onRendered, success, cancel) {
            let cellValue = cell.getValue();

            if(cellValue === '-') {
                return false;
            }

            let editor = document.createElement("input");
            editor.setAttribute("type", "numeric");
            editor.value = cell.getValue();

            onRendered(function(){
                editor.focus();
                editor.style.css = "100%";
            });

            function successFunc(){
                success(editor.value);
            }

            editor.addEventListener("change", successFunc);
            editor.addEventListener("blur", successFunc);

            return editor;
        },
    },
    {
        title: 'Hide Price',
        field: 'hidePrice',
        hozAlign: 'center',
        formatter: function (cell, formatterParams, onRendered) {
            const hidePrice = cell.getData().hidePrice;

            if (hidePrice !== undefined && hidePrice !== '-') {
                if(document.getElementsByClassName('price-tip').length === 1) {
                    let priceTipClone = document.querySelector('.price-tip').cloneNode(true);
                    priceTipClone.style.display = 'initial';

                    if(navigator.userAgent.match(/chrome|chromium|crios|edg|opr\//i)) {
                        priceTipClone.style.top = '-1px';
                    }

                    tippy(priceTipClone, {
                        content: "If you want to hide the cost of a certain product, select the 'Hide Price' button and your customer will get 'Price TBD' message on their Quote"
                    });

                    cell.getColumn().getElement().getElementsByClassName('tabulator-col-title')[0].appendChild(priceTipClone);
                }

                return `<input type="checkbox" class="form-check-input" ${Boolean(Number(cell.getData().hidePrice)) ? 'checked' : ''}/>`;
            } else {
                return '-';
            }
        },
        cellClick: function (e, cell) {
            let element = cell.getElement();
            let chkbox = element.getElementsByClassName('form-check-input')[0];
            if(e.target.classList.contains('form-check-input')) {
                if (e.target.checked) {
                    chkbox.setAttribute('checked', false);
                    cell.getRow().deselect();
                    cell.getData().IsSelected = false;

                    cell.getData().hidePrice = "1";
                } else {
                    chkbox.setAttribute('checked', true);
                    cell.getRow().select();
                    cell.getData().IsSelected = true;

                    cell.getData().hidePrice = "0";
                }
            }
        },
        headerSort: false
    },
];

const options = {
    ajaxUrl: '/api/price-catalog-data',
    columns: columns,
    ajaxParams: reactive({}),
}


const tipStyle = computed(() => {
    let style = {
        display: 'none'
    }
    if(navigator.userAgent.match(/chrome|chromium|crios|edg|opr\//i)) {
        style.top = '-0.4em'
    }
    return style
})

const attributeChosen = (item) => {
    options.ajaxParams.attribute_id = item.id
}


onMounted(() => {
    let priceTipClone = document.getElementsByClassName('price-tip')[0].cloneNode(true);
    priceTipClone.style.display = 'initial';

    document.querySelectorAll('.tabulator-col-title')[document.querySelectorAll('.tabulator-col-title').length - 1].appendChild(priceTipClone);

    if(navigator.userAgent.match(/chrome|chromium|crios|edg|opr\//i)) {
        priceTipClone.style.top = '-1px'
    }

    tippy(priceTipClone, {
        content: "If you want to hide the cost of a certain product, select the 'Hide Price' button and your customer will get 'Price TBD' message on their Quote"
    });
});
</script>
