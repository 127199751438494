<template>
    <pre v-highlight>
    <slot/>
    <textarea
        v-model="copySource"
        class="fixed w-1 h-1"
    />
  </pre>
</template>

<script>
import { defineComponent } from 'vue'
import { copySource } from './index'
import jsBeautify from 'js-beautify'
import hljs from 'highlight.js'
import _ from 'lodash'

export default defineComponent({
    directives: {
        highlight: {
            beforeMount(el) {
                let source = cash(el).find('code').find('textarea').html()

                source = _.replace(source, /&lt;/g, '<')
                source = _.replace(source, /&gt;/g, '>')

                source = jsBeautify.html(source)

                copySource.value = source

                source = _.replace(source, /</g, '&lt;')
                source = _.replace(source, />/g, '&gt;')

                cash(el).find('code').html(source)

                hljs.highlightElement(cash(el).find('code')[0])
            }
        }
    },
    setup() {
        return {copySource}
    }
})
</script>

<style scoped>
textarea {
    margin-left: 1000000px;
}
</style>
