<script setup>
import { onMounted, ref} from 'vue'
import MainButton from '../../Components/Button/MainButton.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const router = useRouter()
const store = useStore()
const calendlyLink = ref('https://calendly.com/ezestimate/success-call')

onMounted(() => {
  const user = store.getters['auth/user']
  if (user?.company_name && user?.email) {
    calendlyLink.value = `https://calendly.com/ezestimate/success-call?name=${user.company_name}&email=${user.email}`
  }

  const script = document.createElement('script');
  script.src = 'https://assets.calendly.com/assets/external/widget.js';
  script.async = true;
  document.body.appendChild(script);
});

const goToGuideRedirect = () => {
  router.push({ path: '/guide-redirect' })
}
</script>

<template>
  <!-- Calendly inline widget begin -->

  <div class="dark:text-white pb-4 flex flex-col items-center max-w-xl pt-4 mt-4" style="margin: 30px auto 0;">
    <h2 class="text-black font-semibold text-center text-xl pb-4">Schedule a Call!</h2>
    <p style="text-align: center">Wants us to do the work and fully customize your instant quote tool for you? Choose a time below to jump on a quick call so we can better understand your business and unique pricing.
      Totally free!</p>
  </div>
  
<!--  <div class="buttons-block" style="padding-top: 0;">
    <MainButton
      title="Skip & Set up the tool yourself!"
      class-map="mt-4 mb-2 grey-green-btn"
      @click="goToGuideRedirect"
    />
    <div class="title medium divider">
      <span>OR</span>
      <div class="line"></div>
    </div>
  </div>-->
  <div class="calendly-inline-widget" :data-url="calendlyLink" style="min-width:320px;height:650px;"></div>
  <!-- Calendly inline widget end -->
</template>

<style scoped lang="scss">
.calendly-inline-widget{
  @media screen and (max-width: 768px) {
    height: 500px;
  }
}

.buttons-block {
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  max-width: 475px;
  width: 100%;
  align-items: center;
  padding: 40px 40px 0;
  box-sizing: border-box;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    box-shadow: none;
    padding: 16px;
    margin: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: rgba(38, 38, 38, 1);
  }

  p {
    text-align: center;
  }

  .divider {
    position: relative;
    height: 20px;
    width: 100%;

    span {
      text-transform: uppercase;
      position: absolute;
      left: calc(50% - 17px);
      top: 0;
      z-index: 3;
      padding: 0 8px;
      background-color: white;
      color: rgba(102, 102, 102, 1);
      font-size: 12px;
      font-weight: 600;
    }

    .line {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid rgba(211, 212, 213, 1);
    }
  }

  button {
    width: 100%;
    border-radius: 8px;

  }

  .notes {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
  }
}
</style>
