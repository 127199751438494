<template>
    <GeneralLayout>
        <DarkModeSwitcher />
        <div>
            <div class="min-h-screen">
                <div class="flex justify-center text-center items-center flex-col h-screen mx-4">
                    <div class="pb-[4rem]">
                        <img :src="logoTransparent" alt="" class="w-[205px]">
                    </div>
                    <MainBlock class-map="w-full max-w-[550px]" title="Reset Password">
                        <template #body>
                            <form class="w-full" @submit.prevent="save">
                                <fieldset>
                                    <div class="intro-x mt-8">
                                        <FormInput
                                            v-model.trim="validate.password.$model"
                                            :errors="validate.password.$errors"
                                            placeholder="Enter password"
                                            label="Password"
                                            type="password"
                                        />
                                    </div>
                                    <PasswordProgress :secure="passwordSecure" />
                                    <div class="intro-x mt-4">
                                        <FormInput
                                            v-model.trim="validate.password_confirmation.$model"
                                            :errors="validate.password_confirmation.$errors"
                                            placeholder="Enter password confirmation"
                                            label="Password Confirmation"
                                            type="password"
                                        />
                                    </div>
                                    <div class="flex justify-center gap-4 mt-4">
                                        <MainButton title="Save" type="submit" :type-button="processing ? 'disabled' : ''" />
                                    </div>
                                </fieldset>
                            </form>
                        </template>
                    </MainBlock>
                </div>
            </div>
        </div>
    </GeneralLayout>
</template>

<script setup>
import { ref, onMounted, inject, reactive, toRefs, computed, watch } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, sameAs } from '@vuelidate/validators';
import DarkModeSwitcher from '@components/DarkModeSwitcher.vue';
import logoTransparent from '@images/svg/logo.svg';
import MainButton from "@components/Button/MainButton.vue";
import FormInput from "@components/UI/Input.vue";
import MainBlock from "@components/MainBlock.vue";
import PasswordProgress from "@components/Progress/PasswordProgress.vue";
import GeneralLayout from "../../Layouts/GeneralLayout.vue";


const router = useRouter();
const store = useStore();
const toast = inject('toast');
const passwordSecure = ref(0);
const userData = reactive({
    email: '',
    password: '',
    password_confirmation: '',
    token: ''
});
const userData_v = {
    password: { required, minLength: minLength(8) },
    password_confirmation: { required, sameAs: sameAs(computed(() => userData.password)) },
}
const validate = useVuelidate(userData_v, toRefs(userData));
const processing = ref(false);


const save = async () => {
    validate.value.$touch();
    processing.value = true;

    if (!validate.value.$invalid) {
        if (userData.password === userData.password_confirmation) {
            userData.token = router.currentRoute._value.params.token;
            userData.email = router.currentRoute._value.query.email;

            await store.dispatch('auth/resetPassword', userData).then((response) => {
                toast({
                    text: response.data.message,
                    type: 'success'
                });
                router.push({ name: 'login' })
            }).catch((e) => {
                toast({
                    text: e.response.data.message,
                    type: 'danger'
                });
            }).finally(() => {
                processing.value = false;
            })
        }
    }
}

const checkPassword = (password) => {
    const s_letters = 'qwertyuiopasdfghjklzxcvbnm';
    const b_letters = 'QWERTYUIOPLKJHGFDSAZXCVBNM';
    const digits = '0123456789';
    const specials = '!@#$%^&*()_-+=\|/.,:;[]{}';

    let isEasyPassword, isMiddlePassword, isHardPassword, isImpossiblePassword = false;
    passwordSecure.value = 0;

    if (password.length >= 8) {
        for (let i = 0; i < password.length; i++) {
            if (s_letters.indexOf(password[i]) !== -1) isEasyPassword = true;
            else if (b_letters.indexOf(password[i]) !== -1) isMiddlePassword = true;
            else if (digits.indexOf(password[i]) !== -1) isHardPassword = true;
            else if (specials.indexOf(password[i]) !== -1) isImpossiblePassword = true;
        }

        [ isEasyPassword, isMiddlePassword, isHardPassword, isImpossiblePassword ].forEach(secure => {
            if (secure) passwordSecure.value += 1;
        })
    }
}


watch(
    () => validate.value.password.$model,
    () => {
        checkPassword(validate.value.password.$model);
    }
);

onMounted(() => {
    document.getElementsByTagName('body')[0].classList.add('login')
});
</script>
