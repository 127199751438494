export const websitesManagementFilteringFields = [
    {
        name: 'Company name',
        value: 'company_name'
    },
    {
        name: 'Domain name',
        value: 'code'
    }
]
