<template>
  <div class="border-slate-200/60 dark:border-darkmode-400 rounded-lg cursor">
    <div class="w-100 relative image-fit cursor-pointer zoom-in mx-auto height-img-wrapper">
      <img
        :src="imageUrl"
        :style="imageUrl === '/images/placeholder.jpg' ? 'box-shadow: 0 0 21px rgba(0, 0, 0, 0.08)' : ''"
        class="rounded-lg"
        alt=""
      >
    </div>
    <div class="mx-auto cursor-pointer relative mt-5 flex justify-center">
      <MainButton
        v-if="showButton"
        title="Change Photo"
        type="button"
        class-map="z-50"
        @click="openFileDialog"
      />
      <input
        ref="imageFile"
        type="file"
        id="refImage"
        class="w-full h-full top-0 left-0 absolute opacity-0 cursor-pointer"
        accept="image/*"
        @change="onEventFilePicked"
      >
    </div>
  </div>
</template>

<script setup>
import { inject, ref, watch } from 'vue'
import MainButton from "../Button/MainButton.vue";

const props = defineProps({
  removeFile: {
    type: Boolean,
    default: false
  },
  url: {
    type: String,
    default: ''
  },
  showButton: {
    type: Boolean,
    default: true
  }
});
const emit = defineEmits(['fileUploaded']);
const imageFile = ref(null);
const defaultFileUrl = '/images/placeholder.jpg';
const imageUrl = ref(props.url ? props.url : defaultFileUrl);
const maxFileSize = 3 * 1024 * 1024;
const toast = inject('toast');

const openFileDialog = () => {
  imageFile.value.value = '';
  imageFile.value.click();
}

const onEventFilePicked = (event) => {
  const files = event.target.files
  const image = files[0];
  const checkSize = image.size > maxFileSize;

  if (!checkSize) {
    emit('fileUploaded', image);

    const fileReader = new FileReader();

    fileReader.addEventListener('load', () => {
      imageUrl.value = fileReader.result
    });

    fileReader.readAsDataURL(files[0]);
  } else {
    toast({
      type: 'danger',
      text: 'Uploaded file must not be larger than 3Mb.'
    });
  }
}


watch(
  () => props.url,
  () => {
    imageUrl.value = props.url ? props.url : defaultFileUrl
  }
);

watch(
  () => props.removeFile,
  () => {
    imageUrl.value = props.url ? props.url : defaultFileUrl
  }
);
</script>

<style scoped>

</style>
