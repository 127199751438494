<template>
    <div :class="['grid grid-cols-1 items-center gap-4', {'md:grid-cols-3': !websiteSettings.onlyQuote}, {'md:grid-cols-2': websiteSettings.onlyQuote}]">
        <div class="text-start">
            <router-link
                :to="{ name: 'website' }"
                :class="['flex items-center text-theme-36', {'!text-theme-32 text-decoration-underline': routeName === 'website'}]"
            >
                <SettingsIcon class="w-4 h-4 mr-2" />
                General
            </router-link>
        </div>
        <template v-if="store.getters['auth/unwantedSubTypes']">
            <div v-show="!websiteSettings.onlyQuote">
                <router-link
                    :to="{ name: 'website-main' }"
                    :class="['flex items-center justify-start md:justify-center text-theme-36', {'!text-theme-32 text-decoration-underline': routeName !== 'website'}]"
                >
                    <HomeIcon class="w-4 h-4 mr-2" />
                    Main Page
                </router-link>
            </div>
            <div class="flex gap-4 items-center justify-start md:justify-end">
                <div>
                    Skip to Estimator
                </div>
                <div>
                    <Switch :checked="onlyQuote" @switch-action="switchMode" />
                </div>
            </div>
        </template>
    </div>
    <Line class="!py-4" />
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Switch from "@components/UI/Switch.vue";
import Line from "@components/Line.vue";


const store = useStore();
const router = useRouter();
const routeName = ref(router.currentRoute._value.name);
const websiteId = computed(() => store.getters['website/websiteId']);
const websiteSettings = computed({
    get() {
        return store.getters['website/websiteSettings'];
    },
    set(value) {
        store.commit('website/setWebsiteSettings', value);
    }
});
const onlyQuote = ref(websiteSettings.value.onlyQuote);

const switchMode = () => {
    if (routeName.value === 'website-main') {
        router.push({ name: 'website'});
    }

    websiteSettings.value.onlyQuote = !websiteSettings.value.onlyQuote;

    store.dispatch('website/settings', {
        settings: { websiteSettings: websiteSettings.value },
        website_id: websiteId.value
    });
}


watch(
    () => websiteSettings.value,
    (settings) => {
        onlyQuote.value = settings.onlyQuote;
    },
    {
        deep: true
    }
)

watch(
    () => router.currentRoute,
    (route) => {
        routeName.value = route._value.name;
    },
    {
        deep: true
    }
)
</script>