export const filteringFields = [{
        name: 'Customer',
        value: 'customer'
    },
    {
        name: 'Status',
        value: 'status'
    },
    {
        name: 'Product',
        value: 'product'
    },
]