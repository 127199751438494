<template>
    <GeneralLayout>
        <div>
            <div class="flex items-center my-6">
                <div :class="['mr-auto w-full title leading-10', {'medium': typeTitle === 'medium'}, {'small': typeTitle === 'small'}]">
                    {{ title }}
                    <slot name="tip" />
                </div>
            </div>
            <div style="box-shadow: 0 0 21px rgba(0, 0, 0, 0.08); border-radius: 16px; padding: 1.2rem 3rem">
                <slot name="body" />
            </div>
        </div>
    </GeneralLayout>
</template>

<script setup>
import { defineProps, onMounted, computed } from 'vue';
import { useStore } from "vuex";
import GeneralLayout from "./GeneralLayout.vue";


const props = defineProps({
    title: {
        type: String,
        required: true
    },
    typeTitle: {
        type: String,
        default: ''
    }
});
const store = useStore();
const user = computed(() => store.getters['auth/user']);


onMounted(() => {
    const element = document.getElementsByClassName('intercom-lightweight-app');

    if(element.length > 0 && user.value?.isAdmin) {
        element[0].remove();
    }
});
</script>

<style lang="scss" scoped>
.title {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;

    &.medium {
        font-size: 24px !important;
    }

    &.small {
        font-weight: 400 !important;
        font-size: 18px !important;
    }
}
</style>
